import React, { useState } from "react";
import { connect } from "react-redux";

import { KladrField } from "components/forms/fields";
import { setProp } from "domain/actions/equipment/bid";

function Address(props) {
  const [value, setValue] = useState(props.item.addressActual || "");
  const isActive = props.selected ? props.selected.status !== 11 : false;

  return (
    <div className={props.className}>
      <KladrField
        label="Адрес расположения"
        className="p-col-12 p-p-0"
        value={value}
        onChange={(e) => {
          setValue(e);
          props.setProp("addressActual", e.value, props.item.id);
        }}
        disabled={isActive}
        defaultQuery={value}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { setProp })(Address);
