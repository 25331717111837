import React from "react";
import { InputNumber } from "primereact/inputnumber";
import styles from "../../style.module.css";

function RangeComponent(props) {
  return (
    <div className={props.className}>
      <span className={styles.rangeComponent__label}>{props.label}</span>

      <div className={styles.rangeComponent}>
        <div className={styles.rangeComponent__input}>
          <label>От</label>
          <InputNumber
            value={props.value[0]}
            onValueChange={(e) => props.onChange([e.value, props.value[1]])}
            placeholder="0"
            style={{ marginRight: "20px" }}
          />
        </div>

        <div className={styles.rangeComponent__input}>
          <label>До</label>
          <InputNumber
            value={props.value[1]}
            onValueChange={(e) => props.onChange([props.value[0], e.value])}
            placeholder="0"
          />
        </div>
      </div>
    </div>
  );
}

export default RangeComponent;
