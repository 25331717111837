import React, { useEffect } from "react";
import { connect } from "react-redux";
import { closeWinReceipt } from "domain/actions/receipt";
import { Header } from "./components";
import Content from "./layouts";
import Window from "components/windows";

import styles from "../../style.module.css";

function ReceiptWindow(props) {
  const close = props.closeWinReceipt;

  useEffect(() => {
    return () => close(false);
  }, [close]);

  return (
    <Window
      className={`c-window-page ${styles.closeWinReceipt} ${
        props.activeSidebar
          ? "window-sidebar-active"
          : "window-sidebar-inActive"
      } `}
      header={<Header />}
      visible={props.show}
      onHide={close}
      position="right"
      modal={false}
      draggable={false}
    >
      <Content />
    </Window>
  );
}

const mapStateToProps = (state) => ({
  show: state.receipts.show,
  activeSidebar: state.sidebar.show,
});
export default connect(mapStateToProps, { closeWinReceipt })(ReceiptWindow);
