import React from "react";
import { connect } from "react-redux";
import {
  FirstComponent,
  SecondComponent,
  Terminal,
  Merchant,
  MerchantName,
  SBPId,
  SbpAccountNumber,
  SBPMerchant
} from "../../../../../components/fields/psb";
import DeleteBtn from "../../../components/acquiring/deleteBtn";
import {
  TypePaymentSystem,
  TokenPayment,
  PaymentCallback,
  ShopIdOrLogin,
  SecretOrPassword,
  PaymentComment,
} from "../../../../../components/fields";

function PSBParams(props) {
console.log(props)
  return (
    <div className={props.className}>
      <TypePaymentSystem
        className="p-col-12 p-md-4 p-xl-3"
        value={props.item.acquiringId}
        index={props.index}
      />
      <TokenPayment
        className="p-col-12 p-md-7 p-xl-8"
        value={props.item.paymentToken}
        index={props.index}
      />

      <DeleteBtn
        className="p-col-4 p-md-1 p-d-flex p-jc-center p-ai-end"
        index={props.index}
      />

      <PaymentCallback
        className="p-col-12 p-md-4"
        value={props.item.paymentCallback}
        index={props.index}
      />
      <ShopIdOrLogin
        className="p-col-12 p-md-4"
        value={props.item.paymentLogin}
        index={props.index}
      />
      <SecretOrPassword
        className="p-col-12 p-md-4"
        value={props.item.paymentPassword}
        index={props.index}
      />

      <SBPId
        className="p-col-12 p-md-8"
        value={props.item.sbp_id}
        index={props.index}
      />

      <Terminal
        className="p-col-12 p-md-4"
        value={props.item.terminal}
        index={props.index}
      />
      <Merchant
        className="p-col-12 p-md-4"
        value={props.item.merchant}
        index={props.index}
      />
      <MerchantName
        className="p-col-12 p-md-4"
        value={props.item.merch_name}
        index={props.index}
      />
      
      
      <SBPMerchant
        className="p-col-12 p-md-4"
        value={props.item.sbp_merchant}
        index={props.index}
      />
      <SbpAccountNumber
        className="p-col-12 p-md-4"
        value={props.item.sbp_account_number}
        index={props.index}
      />
      <FirstComponent
        className="p-col-12 p-md-6 "
        value={props.item.firstComponent}
        index={props.index}
      />
      <SecondComponent
        className="p-col-12 p-md-6 "
        value={props.item.secondComponent}
        index={props.index}
      />

      <PaymentComment
        className="p-col-12"
        value={props.item.comment}
        index={props.index}
      />
    </div>
  );
}

export default connect(null)(PSBParams);
