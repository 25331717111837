import React, { useState, useEffect, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import {
  setIsLocal,
  clearCashbox,
  addCashbox,
} from "domain/actions/equipment/bid";
import { WarningConfirm } from "../../windows";
import Label from "components/based/label";
import { Select } from "components/forms/fields";
import styles from "../../style.module.css";

function InstallPlace(props) {
  const [value, setValue] = useState(null);
  const [options] = useState([
    { id: 1, name: "У клиента" },
    { id: 2, name: "У поставщика услуг" },
  ]);
  const [show, setShow] = useState(false);
  const [warning, showWarning] = useState(false);
  const { setIsLocal, clearCashbox, addCashbox } = props;

  const memoizedOptions = useMemo(() => options, [options]);

  const newArrayCashboxes = useCallback(
    (val) => {
      setIsLocal(val);
      clearCashbox();
      addCashbox();
    },
    [setIsLocal, clearCashbox, addCashbox]
  );

  const setLocal = useCallback(
    (e) => {
      setValue(e);
      if (props.editEvent) return showWarning(true);

      newArrayCashboxes(e);
    },
    [props.editEvent, newArrayCashboxes]
  );

  const accept = (val) => {
    if (val) newArrayCashboxes(value);
  };

  useEffect(() => {
    if (props.org) return setShow("p-d-flex");
    setShow("p-d-none");
  }, [props.org]);

  useEffect(() => {
    if (memoizedOptions?.length && props.isLocal === null) {
      setLocal(memoizedOptions[1]);
    }
  }, [props.isLocal, setLocal, memoizedOptions]);

  const isActive = props.selected ? props.selected.status !== 11 : false;

  return (
    <div className={`${show} ${props.className} `}>
      <Label
        value="Выберите, где будут находиться кассы"
        className={`${styles.hLabel} p-col-12 p-pr-2 p-pl-0`}
        required
      />

      <Select
        options={options}
        value={props.isLocal}
        onChange={setLocal}
        className={`${styles.local} p-col-12 p-p-0 `}
        disabled={isActive}
      />

      <WarningConfirm show={warning} onHide={showWarning} accept={accept} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLocal: state.bidEquipments.isLocal,
  editEvent: state.bidEquipmentsCashbox.editEvent,
  org: state.bidEquipments.organizationId,
  cashbox: state.bidEquipmentsCashbox.list,
  selected: state.bidList.select,
});
export default connect(mapStateToProps, {
  setIsLocal,
  clearCashbox,
  addCashbox,
})(InstallPlace);
