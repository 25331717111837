import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";

export function CardComponent(props) {
  const { item, onSelect } = props;
  const [product, setProduct] = useState("");

  useEffect(() => {
    if (item && item.receiptBody && item.receiptBody?.items.length) {
      setProduct(item.receiptBody.items[0]);
    }
  }, [item]);

  const header = (
    <div
      style={{
        width: "100%",
        height: "10px",
        backgroundColor: "rgb(25 112 47)",
      }}
    ></div>
  );
  const footer = (
    <span>
      <Button label="Использовать" icon="pi pi-check" onClick={onSelect} />
    </span>
  );

  return (
    <div className="p-col-12 p-md-6 p-xl-3">
      <Card title={product.name} footer={footer} header={header}></Card>
    </div>
  );
}

export default CardComponent;
