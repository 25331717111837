import React from "react";
import { connect } from "react-redux";
import { MobileFieldControl } from "components/forms/fields";
import { clearResponseError } from "domain/actions/users";

function MobileField(props) {
  const { errors, disabled } = props;

  return (
    <MobileFieldControl
      className={props.className}
      name="mobile"
      control={props.formHook.control}
      errors={props.formHook.errors}
      label="Номер телефона:"
      required={true}
      responseError={errors?.mobile?.messages[0]}
      onClearError={props.clearResponseError}
      disabled={disabled}
    />
  );
}

const mapStateToProps = (state) => ({
  errors: state.users.responseError,
});

export default connect(mapStateToProps, { clearResponseError })(MobileField);
