import { SET_PAGINATION_USERS } from "domain/types/users";

const initialState = {
  limit: 1,
  page: 1,
  pages: 1,
  total: 120,
};

export default function pagination(state = initialState, action) {
  switch (action.type) {
    case SET_PAGINATION_USERS:
      const { limit, page, pages, total } = action.payload;

      return {
        limit: limit,
        page: page ? page : 1,
        pages: pages,
        total: total,
      };
    default:
      return state;
  }
}
