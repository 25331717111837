import { combineReducers } from "redux";

import formsReducer from "./reducers/forms.reduce";

import account from "./reducers/account.reduce";

import authReducer from "./reducers/auth.reduce";

import analytics from "./reducers/analytics";

import billing from "./reducers/billing";

import registerReducer from "./reducers/register.reduce";

import usersReducer from "./reducers/users";
import userPagination from "./reducers/users/pagination";
import userFilter from "./reducers/users/filter";

import organizationReducer from "./reducers/organization.reducer";
import { orgFilters, orgPagination, orgCreate } from "./reducers/organizations";
import orgAcquiring from "./reducers/organizations/acquiring";

import paymentsReducer from "./reducers/payments";
import paymentsFilters from "./reducers/payments/filter";

import requisitesReducer from "./reducers/requisites.reduce";
import errorsReducer from "./reducers/errors.reduce";
import receiptReducer from "./reducers/receipt.reduce";
import { receiptFilters, receiptPagination } from "./reducers/receipts";
import receiptPaginationReducer from "./reducers/pagination.reduce";

import translatesReduce from "./reducers/translate.reduce";

import equipmentReducer from "./reducers/equipment.reduce";
import equipmentPagination from "./reducers/equipment/equipmentPagination";
import equipmentFilters from "./reducers/equipment/equipment/filter";

import createReceiptReducer from "./reducers/createReceipt.reduce";
import routeReducer from "./reducers/route.reduce";

import bidListReduce from "./reducers/equipment/bid";
import bidFilterReducer from "./reducers/equipment/bid/filter";
import bidPagination from "./reducers/equipment/bid/pagination";
import bidEquipmentsReducer from "./reducers/equipment/bid/bidEquipment.reduce";
import bidListCashboxReducer from "./reducers/equipment/bid/bidListCashbox.reduce";

import { createReceiptItems } from "./reducers/createReceipt/index";
import EditItemReceiptReducer from "./reducers/createReceipt/editItem";
import ReceiptTemplatesReducer from "./reducers/receiptTemplates.reduce";
import NotificationsReduce from "./reducers/notifications.reduce";

import Settings from "./reducers/settings";

import Sidebar from "./reducers/sidebar";

const rootReducer = combineReducers({
  account: account,
  auth: authReducer,
  analytics: analytics,
  billing: billing,
  bidList: bidListReduce,
  bidEquipments: bidEquipmentsReducer,
  bidEquipmentsCashbox: bidListCashboxReducer,
  bidFilter: bidFilterReducer,
  bidPagination: bidPagination,
  createReceipt: createReceiptReducer,
  createReceiptItems: createReceiptItems,
  createReceiptEdit: EditItemReceiptReducer,
  receiptTemplates: ReceiptTemplatesReducer,
  equipments: equipmentReducer,
  equipmentPagination: equipmentPagination,
  equipmentFilters: equipmentFilters,
  errors: errorsReducer,
  forms: formsReducer,
  organizations: organizationReducer,
  orgCreate: orgCreate,
  orgAcquiring: orgAcquiring,
  orgFilters: orgFilters,
  orgPagination: orgPagination,
  payments: paymentsReducer,
  paymentsFilter: paymentsFilters,
  pagination: receiptPaginationReducer,
  register: registerReducer,
  requisites: requisitesReducer,
  receipts: receiptReducer,
  receiptsFilter: receiptFilters,
  receiptPagination: receiptPagination,
  route: routeReducer,
  settings: Settings,
  sidebar: Sidebar,
  translate: translatesReduce,
  users: usersReducer,
  userPagination: userPagination,
  userFilter: userFilter,
  notifications: NotificationsReduce,
});

export default rootReducer;
