import React from "react";
import { connect } from "react-redux";

import { Chip } from "primereact/chip";

function ListSno(props) {
  const list = props.item.snoCashboxes.map((i) => i.sno);

  const renderItems = list.map((i) => {
    const active = i.id === props.item.defaultSnoId;
    return (
      <Chip
        key={i.id}
        label={i.name}
        className={`p-m-2 c-chip ${active && "c-chip-active"}`}
      />
    );
  });
  return (
    <div className={props.className}>
      <span className="block-label-title p-pl-2">Список СНО</span>
      <div className="p-d-flex p-flex-wrap">{renderItems}</div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  item: state.equipments.select,
});
export default connect(mapStateToProps)(ListSno);
