import React from "react";
import { connect } from "react-redux";

import { SelectControl } from "components/forms/fields";

function TypeItem(props) {
  const { control, errors } = props.formHook;
  const requisite = props.requisite ? props.requisite.markingCodeType : [];

  return (
    <SelectControl
      label="Тип товара"
      className="p-col-12  p-d-none p-flex-wrap"
      name="markingCode.type"
      control={control}
      errors={errors}
      optionLabel="name"
      options={requisite}
      defaultValue={props.defaultValue}
      showClear={true}
    />
  );
}

const mapStateToProps = (state) => ({
  requisite: state.requisites,
});
export default connect(mapStateToProps)(TypeItem);
