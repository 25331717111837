import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { ProgressSpinner } from "primereact/progressspinner";

function SpinnerWrapper(props) {
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    if (props.progress !== undefined) return setProgress(props.progress);

    return setProgress(props.progressDef);
  }, [props.progress, props.progressDef]);

  // useEffect(() => {
  //   const body = document.getElementsByTagName('body')[0];
  //   if(progress) body.style.overflow = 'hidden';
  //   else body.style.overflow = 'auto';
  // }, [progress])

  return (
    <div
      className={`${props.className} ${progress ? "c-filter-blur" : ""}`}
      style={{ pointerEvents: progress ? "none" : "auto" }}
    >
      {progress && (
        <div
          className={`
            ${
              props.position === "center"
                ? "c-absolute-center "
                : "c-absolute-top "
            }
            ${props.classNameSpinner}
          `}
        >
          <ProgressSpinner
            className="c-progressSpiner"
            strokeWidth="8"
            fill="#ffffff00"
            animationDuration=".5s"
          />
        </div>
      )}

      {props.children}
    </div>
  );
}

const mapStateToProps = (state) => ({
  progressDef: state.pagination.progressBar,
});

export default connect(mapStateToProps)(SpinnerWrapper);
