import React from "react";
import {
  Phone,
  Email,
  AddressActual,
  AddressLegal,
} from "../../../../components/fields";

function Contacts(props) {
  return (
    <div className={props.className}>
      <span className="block-label-title p-pb-4 title-silver p-col-12">
        Контактные данные
      </span>

      <Phone className="p-col-12 p-md-6" />
      <Email className="p-col-12 p-md-6" />
      <AddressLegal className="p-col-12 " />
      <AddressActual className="p-col-12 " />
    </div>
  );
}

export default Contacts;
