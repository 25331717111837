import orderBy from "lodash/orderBy";

export default function createData(data) {
  if (data === null) return [];

  let sortData = orderBy(data, ["moneyIn"], ["desc"]);
  let result = [];

  if (sortData.length) {
    sortData.forEach((i) => {
      if (i.moneyIn === 0 && i.moneyOut === 0) return;
      else {
        result.push(i);
      }
    });
  }

  if (result.length > 3) {
    let top = sortData.slice(0, 3);
    let other = {
      id: "otherOrganizations",
      moneyIn: Number(
        sortData
          .slice(3)
          .reduce((c, s) => c + s.moneyIn, 0)
          .toFixed(2)
      ),
      moneyOut: Number(
        sortData
          .slice(3)
          .reduce((c, s) => c + s.moneyOut, 0)
          .toFixed(2)
      ),
      name: "Прочие",
      receiptsIn: Number(
        sortData
          .slice(3)
          .reduce((c, s) => c + s.receiptsIn, 0)
          .toFixed(2)
      ),
      receiptsOut: Number(
        sortData
          .slice(3)
          .reduce((c, s) => c + s.receiptsOut, 0)
          .toFixed(2)
      ),
    };

    top.push(other);

    result = top;
  }

  return result;
}
