import React from "react";

const style = {
  fontSize: "34px",
  margin: "64px 0",
};

function EmptyComponent(props) {
  return (
    <div className={props.className}>
      <p style={style}>За выбранный период данные по графику отсутствуют</p>
    </div>
  );
}

export default EmptyComponent;
