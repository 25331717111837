import React, { useEffect } from "react";
import { LikeFilter } from "components/static";
import { connect } from "react-redux";
import { setLike } from "domain/actions/organizations/filters";

function Like(props) {
  useEffect(() => {
    return () => props.setLike("");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <LikeFilter
      className={props.className}
      value={props.value}
      setValue={props.setLike}
    />
  );
}

const mapStateToProps = (state) => ({
  value: state.orgFilters.like,
});
export default connect(mapStateToProps, { setLike })(Like);
