import React, { useState } from "react";
import { connect } from "react-redux";
import {
  handleShowAcquiringLocal,
  getAcquiringLocal,
} from "domain/actions/organizations/acquiring";
import { AddAcquiringLocal } from "../../../components/addAcquiringLocal";
import { useEffect } from "react";
import AcquiringLocalEdit from "../../../components/acquiringLocalEdit";
import WindowAcquiringLocal from "../../../../../windows/acquiringLocal";

function AcquiringLocalData(props) {
  const { org, getAcquiringLocal, handleShowAcquiringLocal } = props;
  const [acquiring, setAcquiring] = useState([]);
  const [selected, setSelected] = useState(null);

  const getAcquiring = () => {
    if (!org) return;
    getAcquiringLocal(org.id).then((r) => setAcquiring(r.data));
  };

  useEffect(() => {
    getAcquiring();
  }, [org]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreate = () => {
    setSelected(null);
    handleShowAcquiringLocal(true);
  };
  const handleUpdate = (i) => {
    setSelected(i);
    handleShowAcquiringLocal(true);
  };

  let renderItems = acquiring.map((i, index) => {
    let className = "p-col-12 p-p-3 p-mb-3 p-d-flex p-flex-wrap c-box-shadow";
    return (
      <AcquiringLocalEdit
        key={index}
        index={index}
        className={className}
        item={i}
        handleSelected={() => handleUpdate(i)}
      />
    );
  });

  return (
    <div className={props.className}>
      {acquiring.length ? (
        <div className="p-col-12 p-p-0">{renderItems}</div>
      ) : (
        <div className="p-col-12 p-text-center empty-list">Список пуст ...</div>
      )}

      <div className="p-col-12 p-d-flex p-jc-center">
        <AddAcquiringLocal className="p-col-12 p-md-3" onShow={handleCreate} />
      </div>

      <WindowAcquiringLocal acquiring={selected} refresh={getAcquiring} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  org: state.orgCreate.select,
});
export default connect(mapStateToProps, {
  handleShowAcquiringLocal,
  getAcquiringLocal,
})(AcquiringLocalData);
