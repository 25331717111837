import React from "react";
import Window from "components/windows";
import Content from "./layouts";

import { connect } from "react-redux";
import {
  postFreezeCash,
  getEquipmentsByFilter,
} from "domain/actions/equipment";
import { showError } from "domain/actions/error.actions";

function FreezeWindow(props) {
  const isFreeze = +props.item.status === 2;
  const text = isFreeze
    ? "Возобновление работы кассы"
    : "Приостановка работы кассы";

  const onConfirm = (comment) => {
    if (comment.length < 5)
      return props.showError(
        "Не заполнено поле комментарий. Минимально 5 символов"
      );

    const data = {
      cashboxId: props.item.id,
      info: comment,
      isFreeze: !isFreeze,
    };

    props.postFreezeCash(data).then((r) => {
      props.getEquipmentsByFilter();
      props.close(false);
    });
  };

  return (
    <Window
      className={props.className}
      header={text}
      visible={props.show}
      onHide={props.close}
      style={{ minWidth: "340px" }}
      draggable={false}
    >
      <Content
        item={props.item}
        isFreeze={isFreeze}
        onShow={props.close}
        onConfirm={onConfirm}
      />
    </Window>
  );
}

const mapStateToProps = (state) => ({
  item: state.equipments.select,
});

export default connect(mapStateToProps, {
  postFreezeCash,
  showError,
  getEquipmentsByFilter,
})(FreezeWindow);
