import React from "react";
import { Label } from "components/based";

function HeadTable(props) {
  return (
    <div className={props.className}>
      <Label className="p-col-3 p-p-1" value="Товар" />
      <Label className="p-col-2 p-p-1" value="Предмет расчета" />
      <Label className="p-col-1 p-p-1" value="Кол-во" />
      <Label className="p-col-1 p-p-1" value="Цена" />
      <Label className="p-col-1 p-p-1" value="Налог. ставка" />
      <Label className="p-col-1 p-p-1" value="Ед. измерения" />
      <Label className="p-col-1 p-p-1" value="Сумма" />
      <Label className="p-col-1 p-p-1" />
    </div>
  );
}

export default HeadTable;
