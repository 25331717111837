import React from "react";
import { CheckboxField } from "components/forms/fields";

function Agreement(props) {
  return (
    <div className="p-d-flex p-p-2">
      <CheckboxField
        name="accept"
        checked={props.checked}
        onChange={(e) => props.onChange(e.checked)}
      />
      <span className="p-as-center">
        Я соглашаюсь на обработку персональных данных
        <a
          href="https://yandex.ru/turbo/zakonrf.info/s/zakon-o-personalnyh-dannyh/"
          rel="noreferrer"
          target="_blank"
        >
          {" "}
          <u> Закон №152-ФЗ</u>
        </a>
      </span>
    </div>
  );
}

export default Agreement;
