import { SET_REQUISITES, SET_REQUISITES_TARIFFS } from "../actionTypes";
import { numberWithSpaces } from "utils/numberWithSpaces";

const statusPaid = [
  { id: 0, name: "Успешная оплата" },
  { id: 1, name: "Оплата не удалась" },
  { id: 2, name: "Время оплаты истекло" },
  { id: 11, name: "Ожидание оплаты" },
];
const statusReceipts = [
  { id: 0, name: "Распечатан" },
  { id: 1, name: "Отказано" },
  { id: 11, name: "В ожидании" },
  { id: 100, name: "Произошла ошибка на сервере" },
  { id: 101, name: "Нет ответа от Worker" },
  { id: 111, name: "Произошла ошибка на сервере. Чек распечатан." },
  { id: 211, name: "Ожидание оплаты" },
  { id: 201, name: "Отказано в оплате квитанции" },
  { id: 202, name: "Время оплаты истекло" },
];

const legalEntity = [
  { name: "Юридическое лицо", isLegalEntity: true },
  { name: "Индивидуальный предприниматель", isLegalEntity: false },
];

const initialState = null;

export default function requisitesReduce(state = initialState, action) {
  switch (action.type) {
    case SET_REQUISITES:
      return Object.assign(
        {
          statusPaid: statusPaid,
          statusReceipts: statusReceipts,
          legalEntity: legalEntity,
          tariffs: [],
        },
        action.payload
      );

    case SET_REQUISITES_TARIFFS:
      const tariffs = (array) =>
        array.map((item) => {
          item.name =
            item.description + " (" + numberWithSpaces(item.cost) + " руб.)";
          return item;
        });
      return {
        ...state,
        tariffs: action.payload ? tariffs(action.payload) : [],
      };

    default:
      return state;
  }
}
