import React from "react";
import { TypePaymentSystem, TokenPayment } from "../../../../components/fields";
import EditBtn from "./editBtn";

function AcquiringEdit(props) {
  return (
    <div className={props.className}>
      <TypePaymentSystem
        className="p-col-12 p-md-4 p-xl-3"
        value={props.item.acquiringId}
        index={props.index}
        disabled={true}
      />
      <TokenPayment
        className="p-col-12 p-md-7 p-xl-8"
        value={props.item.paymentToken}
        index={props.index}
        disabled={true}
      />

      <EditBtn
        className="p-col-4 p-md-1 p-d-flex p-jc-center p-ai-end"
        item={props.item}
      />
    </div>
  );
}

export default AcquiringEdit;
