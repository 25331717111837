// import MenuIcon from '@material-ui/icons/Menu';
import ApartmentIcon from "@material-ui/icons/Apartment";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import AirplayIcon from "@material-ui/icons/Airplay";
import PostAddIcon from "@material-ui/icons/PostAdd";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import SettingsIcon from "@material-ui/icons/Settings";
import Receipt from "@material-ui/icons/Receipt";
import RoomService from "@material-ui/icons/RoomService";
import Payment from "@material-ui/icons/Payment";

const listNavigation = [
  {
    id: "analytics",
    link: "/main/analytics",
    name: "Аналитика",
    icon: <TrendingUpIcon />,
  },
  {
    id: "organizations",
    link: "/main/organizations",
    name: "Организации",
    icon: <ApartmentIcon />,
  },
  {
    id: "users",
    link: "/main/users",
    name: "Пользователи",
    icon: <EmojiPeopleIcon />,
  },
  {
    id: "equipments",
    link: "/main/equipments",
    name: "Оборудование",
    icon: <AirplayIcon />,
  },
  {
    id: "templates",
    link: "/main/templates",
    name: "Шаблоны чеков",
    icon: <Receipt />,
  },
  {
    id: "create/receipt",
    link: "/main/create/receipt",
    name: "Новый чек",
    icon: <PostAddIcon />,
  },
  {
    id: "receipts",
    link: "/main/receipts",
    name: "Чеки",
    icon: <LibraryBooksIcon />,
  },
  {
    id: "payments",
    link: "/main/payments",
    name: "Платежи",
    icon: <Payment />,
  },
  {
    id: "services",
    link: "/main/services",
    name: "Сервисы",
    icon: <RoomService />,
  },
  {
    id: "settings",
    link: "/main/settings",
    name: "Настройки",
    icon: <SettingsIcon />,
  },
];

export default listNavigation;
