import React, { useState, useRef, useEffect } from "react";
import { Badge } from "primereact/badge";
import { Dialog } from "primereact/dialog";
import { LargeList } from "components/static";

import Message from "./message";
import MessageDialog from "./message.dialog";
import { connect } from "react-redux";
import {
  getNotification,
  getNotificationsNotRead,
} from "domain/actions/notification";
import { getServicesAll } from "domain/actions/route.action";
import { socket } from "domain/controllers/socket";
import { onReadAll } from "domain/actions/notification";
import { Button } from "primereact/button";

function useOutsideDialog(ref, cb) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (!ref.current) return;
      if (ref.current.mask?.className === event.target.className) {
        cb();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]); // eslint-disable-line react-hooks/exhaustive-deps
}

export function Notification(props) {
  const {
    className = "",
    getNotification,
    getNotificationsNotRead,
    getServicesAll,
    count,
    warn,
    onReadAll,
  } = props;
  const dialogRef = useRef();
  const [services, setServices] = useState([]);
  const [display, setDisplay] = useState(false);
  const [message, setMessage] = useState(null);
  const [visible, setVisible] = useState(false);
  const [key, setKey] = useState(1);
  const [data, setData] = useState({});

  const restart = () => setKey((prev) => prev + 1);
  const onShowMessage = (message) => {
    setMessage(message);
    setVisible(true);
  };
  const showBilling = () => {
    const billing = services.filter((i) => i.name === "Биллинг");
    if (billing.length) {
      window.open(`${billing[0].link}/api/auth/login`, "_blank");
    }
  };

  const _onReadAll = async () => {
    await onReadAll(Object.keys(data).map((key) => data[key]));
    restart();
  };

  useEffect(() => {
    socket.on("getNotification", (notification) => {
      console.log(notification);
      restart();
      getNotificationsNotRead();
    });

    // return () => {
    //   socket.off("getNotification", () => {});
    // };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getNotificationsNotRead();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getServicesAll().then((r) => setServices(r));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!display) setData({});
  }, [display]);

  useOutsideDialog(dialogRef, () => setDisplay(false));

  return (
    <div className={className}>
      <i
        className="pi pi-bell p-mr-4 p-text-secondary p-overlay-badge"
        style={{ fontSize: "1.5rem", cursor: "pointer" }}
        onClick={(e) => setDisplay(true)}
        aria-haspopup
        aria-controls="overlay_notification"
      >
        {count > 0 ? (
          <Badge
            value={count}
            severity={warn <= 0 ? "success" : "danger"}
          ></Badge>
        ) : (
          <></>
        )}
      </i>

      <Dialog
        header="Уведомления"
        className="notification"
        ref={dialogRef}
        visible={display}
        position="top-right"
        contentClassName="p-pr-3 p-pl-3"
        modal
        closeOnEscape={false}
        style={{ width: "50vw", minWidth: "355px" }}
        onHide={() => setDisplay(false)}
        draggable={false}
        resizable={false}
      >
        <Button
          label="Прочитать всё"
          onClick={() => _onReadAll()}
          className="p-button-text p-col-12"
        />

        <LargeList
          key={key}
          renderRow={(item) => (
            <Message message={item} restart={restart} onShow={onShowMessage} />
          )}
          getDataFromServer={getNotification}
          setDataFromServer={(data) => setData(data)}
          limit={9}
          style={{ height: "375px" }}
          itemSize={118}
          emptyText="Нет уведомлений"
        />
      </Dialog>

      <MessageDialog
        message={message}
        visible={visible}
        setVisible={setVisible}
        onBilling={showBilling}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  count: state.notifications.count,
  warn: state.notifications.warn,
});

export default connect(mapStateToProps, {
  getNotification,
  getNotificationsNotRead,
  getServicesAll,
  onReadAll,
})(Notification);
