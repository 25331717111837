import React, { useState } from "react";
import { ReportBtn, PayoutBtn, FreezeBtn } from "../index";
import {
  PayoutWindow,
  ReportWindow,
  FreezeWindow,
  WorkerWindow,
} from "../../../../../../windows";
import { Button } from "primereact/button";

function PanelButtons(props) {
  const [showFreeze, setFreeze] = useState(false);
  const [showPayout, setPayout] = useState(false);
  const [showReport, setReport] = useState(false);
  const [showWorker, setWorker] = useState(false);

  return (
    <React.Fragment>
      <div className={props.className}>
        <FreezeBtn
          onClick={() => setFreeze(true)}
          className="p-col-12 p-md-4"
        />

        {props.active && (
          <React.Fragment>
            <ReportBtn
              onClick={() => setReport(true)}
              className="p-col-12 p-md-4"
            />
            <PayoutBtn
              onClick={() => setPayout(true)}
              className="p-col-12 p-md-4 p-d-none"
            />
          </React.Fragment>
        )}

        <div className="p-col-12 p-md-4">
          <Button
            label='Сменить "Рабочее место"'
            onClick={() => setWorker(true)}
            className="c-btns--gradient c-btns"
          />
        </div>
      </div>

      <FreezeWindow show={showFreeze} close={() => setFreeze(false)} />
      <ReportWindow show={showReport} close={() => setReport(false)} />
      <PayoutWindow show={showPayout} close={() => setPayout(false)} />
      <WorkerWindow show={showWorker} close={() => setWorker(false)} />
    </React.Fragment>
  );
}

export default PanelButtons;
