import React from "react";

function Logotype(props) {
  return (
    <div className={props.className} onClick={props.onClick}>
      <img src={process.env.PUBLIC_URL + "/img/logo512.png"} alt="VD.Cloud" />
      <span className={`p-d-flex p-jc-center p-flex-column`}>VD.Cloud</span>
    </div>
  );
}

export default Logotype;
