import React from "react";
import styles from "../index.module.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { InputTextarea } from "primereact/inputtextarea";

function TextAreaField({
  className = "",
  name = "",
  label = "",
  value = null,
  onChange = () => console.log("onChange"),
  onBlur = () => console.log("onBlur"),
  required = false,
  params,
  placeholder = "",
  autoResize,
  disabled = false,
}) {
  return (
    <div className={className}>
      <label className={`${styles.label} p-pb-1`}>{label}</label>
      <InputTextarea
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`${styles.borderFull}`}
        onBlur={onBlur}
        {...params}
        autoResize={autoResize}
        placeholder={placeholder}
        disabled={disabled}
      />
      {required && !value.length && (
        <small className="p-error p-d-block">Обязательно для заполнения!</small>
      )}
    </div>
  );
}

function TextAreaFieldControl({
  control,
  errors,
  className = "",
  name = "",
  label = "",
  defaultValue = "",
  required = false,
  validate = null,
  tooltip = null,
  params,
  disabled = false,
  handleValue = (v) => v,
}) {
  let rules = {};

  if (required) {
    rules.required = "Обязательно для заполнения!";
  }
  if (validate) {
    rules.validate = validate;
  }

  return (
    <div className={className}>
      <label className={`${styles.label} p-pb-1`}>{label}</label>
      <Controller
        name={name}
        control={control}
        render={(props) => (
          <InputTextarea
            value={props.value}
            onChange={(e) => {
              let value = handleValue(e.target.value);
              props.onChange(value);
            }}
            className={`${styles.borderFull}`}
            tooltipOptions={{ position: "bottom" }}
            tooltip={tooltip}
            {...params}
            disabled={disabled}
          />
        )}
        rules={rules}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        as={<p className="errorText">message</p>}
      />
    </div>
  );
}

export { TextAreaField, TextAreaFieldControl };
