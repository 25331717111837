import React, { useEffect } from "react";
import { connect } from "react-redux";
import { closeWinPayment } from "domain/actions/payments";
import PaymentContent from "./payment.content";
import Window from "components/windows";

import styles from "../styles.module.css";

function PaymentWindow(props) {
  const close = props.closeWinPayment;

  useEffect(() => {
    return () => close(false);
  }, [close]);

  return (
    <Window
      className={`c-window-page ${styles.closeWin} ${
        props.activeSidebar
          ? "window-sidebar-active"
          : "window-sidebar-inActive"
      } `}
      header={
        <>
          <div className="p-d-flex">
            <p className="p-m-0">Информация о платеже </p>
            {/* {numbers} */}
          </div>
        </>
      }
      visible={props.show}
      onHide={close}
      position="right"
      modal={false}
      draggable={false}
    >
      <PaymentContent />
    </Window>
  );
}

const mapStateToProps = (state) => ({
  show: state.payments.show,
  activeSidebar: state.sidebar.show,
});
export default connect(mapStateToProps, { closeWinPayment })(PaymentWindow);
