import React from "react";
import { connect } from "react-redux";
import { deleteCashbox } from "domain/actions/equipment/bid";
import { DeleteBtn } from "components/btns/iconBtn";

function BtnDelete(props) {
  const onDeleteItem = () => props.deleteCashbox(props.id);
  const isVisible = props.selected
    ? props.selected.status === 11
      ? ""
      : "p-d-none"
    : "";
  return (
    <div className={props.className + " " + isVisible}>
      <DeleteBtn onClick={onDeleteItem} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { deleteCashbox })(BtnDelete);
