import React from "react";
import styles from "../../../../../../../../styles.module.css";

import { Button } from "primereact/button";

function Footer(props) {
  return (
    <div className={props.className}>
      <div className={`p-col-12 p-md-4 ${styles.buttons} c-green-btn`}>
        <Button
          label="Применить"
          className="p-button-success"
          onClick={() => {
            let btn = document.getElementById("formAddingItems");
            btn.click();
          }}
        />
      </div>
    </div>
  );
}

export default Footer;
