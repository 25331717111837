import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  selectedItem,
  updateField,
  deleteItem,
  toggleShow,
} from "domain/actions/createReceipt";

import TextInput from "./textField";
import NumberField from "./numberField";
import DropdownField from "./dropdownField";
import { EditBtn, DeleteBtn } from "components/btns/iconBtn";

function ItemComponent(props) {
  const [item, setItem] = useState(props.item);

  useEffect(() => {
    setItem(props.item);
  }, [props.item]);

  const onEditItem = () => {
    props.selectedItem(item.id);
    props.toggleShow(true);
  };
  const onDeleteItem = () => props.deleteItem(item.id);
  const onUpdateField = (key, value) => props.updateField(key, value, item.id);

  return (
    <div className="p-col-12 p-p-3 p-p-xl-0 p-d-flex p-jc-between p-flex-wrap">
      <TextInput
        className="p-col-12 p-p-1 p-sm-6 p-xl-3 p-md-order-0 p-fluid"
        value={item.name}
        name="name"
        label="Товар"
        onChange={onUpdateField}
      />

      <DropdownField
        className="p-col-12 p-p-1 p-sm-6 p-xl-2 p-md-order-1 p-fluid"
        value={item.paymentObject}
        name="paymentObject"
        label="Предмет расчета"
        options={props.requisite?.paymentObject}
        onChange={onUpdateField}
      />

      <DropdownField
        className="p-col-12 p-p-1 p-sm-6 p-xl-1 p-md-order-5 p-fluid"
        value={item.tax}
        name="tax"
        label="Налог.ставка"
        options={props.requisite?.tax}
        onChange={onUpdateField}
      />

      <DropdownField
        className="p-col-12 p-p-1 p-sm-6 p-xl-1 p-md-order-5 p-fluid"
        value={item.measurementUnit}
        name="measurementUnit"
        label="Ед. измерения"
        options={props.requisite?.unit ? props.requisite.unit : []}
        onChange={onUpdateField}
      />

      <NumberField
        className="p-col-6 p-p-1 p-sm-3 p-xl-1 p-md-order-3 p-fluid"
        value={item.quantity}
        name="quantity"
        label="Кол-во"
        maxFractionDigits={3}
        max={99999999.99}
        onChange={onUpdateField}
      />

      <NumberField
        className="p-col-6 p-p-1 p-sm-3 p-xl-1 p-md-order-4 p-fluid"
        value={item.price}
        name="price"
        label="Цена"
        max={99999999.99}
        onChange={onUpdateField}
      />

      <NumberField
        className="p-col-6 p-p-1 p-sm-3 p-xl-1 p-md-order-6 p-fluid"
        value={item.amount}
        name="amount"
        label="Сумма"
        disabled
      />

      <div
        className={`p-col-6 p-p-1 p-sm-3 p-xl-1 p-md-order-7 p-mt-4 p-mt-xl-0`}
      >
        <EditBtn onClick={onEditItem} />
        <DeleteBtn onClick={onDeleteItem} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  requisite: state.requisites,
  items: state.createReceiptItems.items,
});
export default connect(mapStateToProps, {
  deleteItem,
  selectedItem,
  updateField,
  toggleShow,
})(ItemComponent);
