import React from "react";
import { connect } from "react-redux";
import { TextAreaFieldControl } from "components/forms/fields";

import { autoLayoutKeyboardRUS } from "utils/autoLayoutKeyboard";

function CodeField(props) {
  const mark = props.codes.markingCode ? props.codes.markingCode.mark : "";
  const paramTextArea = {
    rows: 3,
    cols: 10,
    autoResize: true,
    placeholder: "Отсканируйте маркировку с помощью 2d сканера",
    autoFocus: true,
    // readOnly: true
  };

  const handleValue = (value) => {
    return autoLayoutKeyboardRUS(value ? value : "");
  };

  return (
    <TextAreaFieldControl
      className="p-p-2"
      name="markingCode.mark"
      control={props.formHook.control}
      errors={props.formHook.errors}
      label="Маркировка"
      params={paramTextArea}
      handleValue={handleValue}
      defaultValue={mark}
    />
  );
}

const mapStateToProps = (state) => ({
  codes: state.createReceiptEdit.codes,
});
export default connect(mapStateToProps)(CodeField);
