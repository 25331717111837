export default function formatItemsEng(data) {
  let result = JSON.stringify(data);
  let items = JSON.parse(result).map((i) => {
    i.type = i.type.eng;
    i.paymentObject = i.paymentObject.eng;
    i.paymentMethod = i.paymentMethod.eng;
    i.tax = { type: i.tax.eng };

    if (i.nomenclatureCode) i.nomenclatureCode = nomenclatureCode(i);
    if (i.markingCode) i.markingCode = markingCode(i);
    if (i.agentInfo) i.agentInfo = agents(i);
    if (i.measurementUnit) i.measurementUnit = measurementUnit(i);

    for (let key in i) {
      if (i[key] === null) delete i[key];
    }
    return i;
  });
  return items;
}

function nomenclatureCode(item) {
  if (item.nomenclatureCode && item.nomenclatureCode.type) {
    item.nomenclatureCode.type = item.nomenclatureCode.type.eng;
    return item.nomenclatureCode;
  }

  return null;
}

function markingCode(item) {
  if (item.markingCode && item.markingCode.type) {
    item.markingCode.type = item.markingCode.type.eng;
    return item.markingCode;
  }

  return null;
}

function agents(item) {
  if (item.agentInfo && item.agentInfo.agents) {
    item.agentInfo.agents = item.agentInfo.agents.map((i) => i.eng);
    return item.agentInfo;
  }

  return null;
}

function measurementUnit(item) {
  if (item.measurementUnit) {
    item.measurementUnit = item.measurementUnit.eng;
    return item.measurementUnit;
  }

  return null;
}
