import { io } from "socket.io-client";

export function getBusUrl() {
  const env = process.env;
  // const prefix = "/api";
  // https://bus.stage.vdpaybox.ru
  if (env.REACT_APP_ENVIRONMENT === "production") {
    return env.REACT_APP_BUS_PROD;
  }

  if (env.REACT_APP_ENVIRONMENT === "development") {
    return env.REACT_APP_BUS_DEV;
  }

  return undefined;
}

const URL = getBusUrl();

export const socket = io(URL, {
  // transports: ["websocket"],
  auth: {
    token: localStorage.getItem("AccessToken"),
    service: "PAYBOX",
    scope: "WEB",
  },
  autoConnect: true,
  reconnection: true,
  transports: ["polling"],
});
