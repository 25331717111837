import { ApiController } from "domain/controllers";
import {
  COUNT_MESSAGE_NOT_READ,
  COUNT_MESSAGE_WARNING,
} from "domain/actionTypes";

const api = new ApiController();

const thunkNotRead = async (dispatch, getState) => {
  const filter = {
    limit: 10,
    page: 1,
    isRead: false,
  };

  // return { data: message};
  const response = await api.get(`/notifications`, filter);

  dispatch({
    type: COUNT_MESSAGE_NOT_READ,
    payload: response?.data?.total ? response.data?.total : 0,
  });
  dispatch({
    type: COUNT_MESSAGE_WARNING,
    payload: response?.data
      ? response.data.data.filter((i) => i.type === "ALERT").length
      : 0,
  });
};

export function getNotificationsNotRead() {
  return async function thunk(dispatch, getState) {
    return await thunkNotRead(dispatch, getState);
  };
}

export function getNotification(page, limit = 10) {
  return async function thunk(dispatch, getState) {
    const response = await api.get(
      `/notifications?limit=${limit}&page=${page}`
    );
    return response.data;
  };
}

export function onRead(messageId) {
  return async function thunk(dispatch, getState) {
    const response = await api.post(`/notifications/readByExtId`, {
      notificationIds: [messageId],
    });
    await thunkNotRead(dispatch, getState);

    return response;
  };
}

export function onReadAll(arrMessage) {
  return async function thunk(dispatch, getState) {
    const response = await api.post(`/notifications/readByExtId`, {
      notificationIds: arrMessage
        .filter((i) => i.isRead === false)
        .map((message) => message.externId),
    });
    await thunkNotRead(dispatch, getState);

    return response;
  };
}

export function onReadBilling(arrMessage) {
  return async function thunk(dispatch, getState) {
    const response = await api.post(`/notifications/readByExtId`, {
      notificationIds: arrMessage.filter(
        (message) => message.text.toUpperCase().indexOf("БАЛАНС") !== -1
      ),
    });
    await thunkNotRead(dispatch, getState);

    return response;
  };
}
