import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setProp, setPropEdit } from "domain/actions/organizations/created";
import { TextAreaField } from "components/forms/fields";

function Comment(props) {
  const [value, setValue] = useState(props.value);
  const [updateKey, forceUpdate] = useState(1);

  const setProp = !props.select ? props.setProp : props.setPropEdit;

  useEffect(() => {
    if (props.select) return setValue(props.select.comment);
    setValue(props.value);
  }, [props.value, props.select]);

  return (
    <TextAreaField
      key={updateKey}
      label="Комментарий"
      className={props.className}
      value={value ? value : ""}
      onChange={(e) => {
        if (e.length < 512) setValue(e);
      }}
      onBlur={(e) => {
        setProp("comment", e.target.value);
        forceUpdate(updateKey + 1);
      }}
      autoResize
      params={{ rows: 5 }}
      placeholder="Напишите комментарий..."
      disabled={props.disabled}
    />
  );
}

const mapStateToProps = (state) => ({
  value: state.orgCreate.item.comment,
  select: state.orgCreate.select,
});
export default connect(mapStateToProps, { setProp, setPropEdit })(Comment);
