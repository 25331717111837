import React from "react";

import { Column } from "components/dataView";

function Body(props) {
  const renderedListItems = props.list.map((i, index) => {
    return (
      <div
        key={index}
        className="p-d-flex p-col-12 p-pl-0 p-pr-0 p-pt-1 p-pb-1 p-flex-wrap"
      >
        <Column
          className="p-col-6 p-pl-0 p-md-3 p-as-center p-ai-start"
          classTitle="p-d-block p-d-md-none"
          classBody="c-color-green p-text-left"
          title="Серийный номер"
          value={`№${i.factoryNumber} `}
        />

        <Column
          className="p-col-6 p-md-5 p-as-center p-ai-start"
          classTitle="p-d-block p-d-md-none"
          classBody="p-text-left"
          title="Торговая площадка"
          value={i.tradePlace ? i.tradePlace : "-"}
        />

        <Column
          className="p-col-12 p-md-4 p-as-center p-ai-start p-text-start"
          classTitle="p-d-block p-d-md-none"
          classBody="p-text-left"
          title="Комментарий"
          value={i.comment === "" ? i.comment : "-"}
        />
      </div>
    );
  });

  return <div className={props.className}>{renderedListItems}</div>;
}

export default Body;
