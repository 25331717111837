import React from "react";
import { Heading } from "components/static";
import Menu from "./menu";

function Header(props) {
  return (
    <div className={props.className}>
      <div className="p-col-12 p-md-4 p-pr-0">
        <Heading className="p-col-12 p-p-0" title="Шаблоны чеков" />

        <Menu history={props.history} className="p-col-12 p-p-0" />
      </div>
    </div>
  );
}

export default Header;
