import React from "react";
import { Checkbox } from "primereact/checkbox";

import { Column } from "components/dataView";

function CardOrg(props) {
  const { item, isSelect, setSelect, setUpdate, update } = props;

  const actionBodyTemplate = (value) => {
    return (
      <Checkbox
        checked={isSelect}
        onChange={(e) => {
          setSelect(value);
          setUpdate(update + 1);
        }}
      />
    );
  };

  return (
    <div className="p-d-flex p-flex-wrap p-pt-4 p-pb-4 c-card-bb">
      <Column
        body={actionBodyTemplate}
        title=""
        value={item}
        className="p-col-3 p-md-2 p-lg-2 p-p-1 p-ai-sm-center"
      />

      <div className="p-col-9 p-md-3 p-lg-3 p-order-0 p-p-1 p-as-center p-d-flex">
        <Column
          className="p-as-start"
          classTitle="p-d-none"
          classBody="c-color-green p-text-left"
          value={item.name}
        />
      </div>

      <Column
        className="p-col-12 p-md-3 p-lg-3 p-p-1 p-ai-md-center p-as-center p-order-5 p-order-md-5 p-order-lg-2"
        title="ИНН/КПП"
        value={`${item.inn} / ${item.kpp ? item.kpp : "-"}`}
      />

      <Column
        className="p-col-12 p-md-4 p-lg-4 p-p-1 p-ai-sm-center p-as-center p-order-6 p-order-md-1 p-order-lg-3"
        title="Юр.адрес"
        value={item.addressLegal}
      />
    </div>
  );
}

export default CardOrg;
