import React from "react";

function CardComponent(props) {
  const header = (
    <div className="p-col-12 p-d-flex p-flex-wrap p-jc-between">
      {props.label}
    </div>
  );

  return (
    <div className={`${props.className} c-shadow-2`}>
      {header}

      {props.children}
    </div>
  );
}

export default CardComponent;
