import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { socket } from "domain/controllers/socket";
import { getDistribution, getCodeActive } from "domain/actions/equipment/bid";
import {
  getWorkers,
  setSelectedWorker,
} from "domain/actions/organizations/index";
import { Menu } from "components/static";
import { ActionsColumn } from "components/static";
import AddWorkerWindow from "routes/pages/workers/add.window";
import EditWorkerWindow from "routes/pages/workers/edit.window";
import RefreshIcon from "@material-ui/icons/Refresh";
import ColumnTypeToString from "controllers/ColumnTypeToString";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

import {
  Organization,
  InstallPlace,
  TariffBid,
} from "routes/pages/equipments/components";

function ParamsBid(props) {
  const {
    isLocal,
    orgs,
    workers,
    organizationId,
    getWorkers,
    selectedWorker,
    setSelectedWorker,
    selected,
  } = props;

  const TypeToString = new ColumnTypeToString();

  const [org, setOrg] = useState(false);
  const [show, setShow] = useState(false);
  const [showEditWorker, setShowEditWorker] = useState(false);
  const refresh = () => getWorkers();

  useEffect(() => {
    const org = orgs.filter((i) => +i.id === +organizationId);
    setOrg(org.length ? org[0] : null);
  }, [orgs, organizationId, setOrg]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getWorkers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedWorker) {
      const _filter = workers.filter((i) => i.id === selectedWorker.id);
      if (_filter.length > 0) setSelectedWorker(_filter[0]);
    }

    if (selected && selected.cashboxes.length > 0) {
      const workerId = selected.cashboxes[0]?.workerId;
      if (workerId) {
        const _filter = workers.filter((i) => i.id === workerId);

        if (_filter.length > 0) setSelectedWorker(_filter[0]);
      }
    }
  }, [workers, selectedWorker]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    socket.on("getNotification", (notification) => {
      getWorkers();
    });

    return () => {
      socket.off("getNotification", () => {});
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onPreview = (item) => {
    setSelectedWorker(item);
    setShowEditWorker(true);
  };

  let menu = (item) => [
    {
      label: "Просмотр",
      icon: "pi pi-fw pi-info-circle",
      command: () => onPreview(item),
    },
  ];

  const action = (rowData) => (
    <ActionsColumn
      menuItems={menu(rowData)}
      element={rowData}
      setElement={() => {}}
    />
  );

  const handleAdd = () => setShow(true);

  return (
    <div className={props.className}>
      <Organization className="p-col-12 p-md-6 p-d-flex p-flex-wrap p-p-0 p-pr-md-3 p-pl-md-0 p-mb-4" />
      <InstallPlace className="p-col-12 p-md-6 p-flex-wrap p-p-0 p-pr-md-3 p-pl-md-0 p-mb-4 " />
      <TariffBid className="p-col-12 p-flex-wrap p-p-0 p-pr-md-3 p-pl-md-0 p-mb-4 " />

      <div
        className={`p-col-12 p-flex-wrap c-box-shadow ${
          isLocal?.id === 1 ? "p-d-flex" : "p-d-none"
        }`}
      >
        <h4 className="p-col-12 p-text-center"> Выберите "рабочее место"</h4>
        <div className="p-col-12">
          <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-ai-baseline">
            <div className="p-p-1">
              <Button
                label="Добавить"
                onClick={handleAdd}
                className="button-primary-out"
              />
            </div>
            <Menu
              list={[
                {
                  id: "2",
                  name: "Обновить страницу",
                  icon: RefreshIcon,
                  callback: () => refresh(),
                },
              ]}
              className="p-pl-3 p-pt-0 p-pb-0 p-pr-0 p-d-flex p-as-center"
            />
          </div>

          <DataTable
            value={workers}
            paginator
            rows={5}
            selection={selectedWorker}
            onSelectionChange={(e) => {
              if (e.value) setSelectedWorker(e.value);
            }}
            dataKey="id"
            responsiveLayout="scroll"
          >
            <Column
              selectionMode="single"
              headerStyle={{ width: "3rem" }}
              exportable={false}
            ></Column>
            <Column field="name" header="Рабочее место" sortable></Column>
            <Column
              field="isActive"
              header=""
              sortable
              sortField="isActive"
              style={{ width: "5rem" }}
              body={(rowData) => TypeToString.toActive(rowData.isActive)}
            ></Column>
            <Column body={action} style={{ width: "5rem" }}></Column>
          </DataTable>
        </div>
      </div>

      <AddWorkerWindow show={show} setShow={setShow} organization={org} />
      <EditWorkerWindow
        show={showEditWorker}
        setShow={setShowEditWorker}
        worker={selectedWorker}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLocal: state.bidEquipments.isLocal,
  orgs: state.organizations.organizations,
  organizationId: state.bidEquipments.organizationId,
  workers: state.organizations.workers,
  selectedWorker: state.organizations.selectedWorker,
  selected: state.bidList.select,
});

export default connect(mapStateToProps, {
  getDistribution,
  getCodeActive,
  getWorkers,
  setSelectedWorker,
})(ParamsBid);
