import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PieChart from "./chart";

import EmptyComponent from "../empty";
import Info from "./info";
import createData from "./createData";

import { SpinnerWrapper } from "components/wrappers";

function OrganizationsRevenue(props) {
  const [dataNull, setDataNull] = useState(true);
  const [title, showTitle] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    showTitle(false);
    try {
      if (props.data === null) return;
      const orgs = props.data.organizations;
      let emptyData = true;

      if (props.data.organizations.length) {
        orgs.forEach((i) => {
          if (i.moneyIn === 0 && i.moneyOut === 0) {
            return;
          } else {
            showTitle(true);
            emptyData = false;
          }
        });
      }
      setDataNull(props.data && emptyData);
    } catch (e) {
      console.error(e);
    }
  }, [props.data]);

  useEffect(() => {
    setData(createData(props.data ? props.data.organizations : []));
  }, [props.data]);

  const renderContent = (
    <React.Fragment>
      <div className="c-h-300 p-col-12 p-p-1">
        {dataNull ? (
          <EmptyComponent className="p-col-12 p-text-center" />
        ) : (
          <PieChart item={true} data={data} />
        )}
      </div>

      {title && (
        <div className="p-col-12  p-p-1 p-d-flex p-flex-column p-jc-center">
          <Info item={true} data={data} />
        </div>
      )}
    </React.Fragment>
  );

  return (
    <div className={props.className}>
      <div className="p-col-12 p-p-3 c-box-shadow">
        <div className="analytics-title">Суммы по организациям</div>

        <SpinnerWrapper position="center" progress={props.progress}>
          <div
            className="p-col-12 p-p-0 p-d-flex p-flex-wrap"
            style={{ minHeight: "390px" }}
          >
            {!props.progress && renderContent}
          </div>
        </SpinnerWrapper>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.analytics.data,
  progress: state.analytics.isProgress,
});
export default connect(mapStateToProps)(OrganizationsRevenue);
