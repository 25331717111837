import React, { useEffect } from "react";
import styles from "../index.module.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import InputMask from "react-input-mask";

import { isE164 } from "utils/phone.util";

function MobileField({
  className = "",
  name = "",
  label = "",
  value = "",
  rules = false,
  onValidate = () => null,
  onChange = () => console.log("onChange"),
  onBlur = () => console.log("onBlur"),
  disabled = false,
}) {
  if (value === null || value === undefined) {
    value = "";
  }

  useEffect(() => {
    if (rules && !value.length) return;
    onValidate(false);
  }, [rules, value, onValidate]);

  return (
    <div className={className}>
      <label className={`${styles.label} p-pb-1`}>{label}</label>
      <InputMask
        name={name}
        value={value}
        onChange={(e) => onChange(e)}
        onBlur={onBlur}
        className={`${styles.borderBottom} p-inputtext p-component`}
        mask="+7(999)999-9999"
        disabled={disabled}
      />
      {rules && !value.length && (
        <small className="p-error p-d-block">Обязательно для заполнения!</small>
      )}
    </div>
  );
}

function MobileFieldControl({
  control,
  errors,
  className = "",
  name = "",
  label = "",
  defaultValue = "",
  required = false,
  responseError = null,
  onClearError = () => {},
  disabled = false,
}) {
  let rules = {
    validate: (value) => {
      if (!required) return true;

      const phone = value.replace(/[+()-]/gm, "");
      if (!isE164(`+${phone}`)) {
        return "Введенный номер не прошел проверку";
      }
      return isE164(`+${phone}`);
    },
  };

  if (required) {
    rules.required = "Обязательно для заполнения!";
  }

  return (
    <div className={className}>
      <label className={`${styles.label} p-pb-1`}>
        {label} {required && <span style={{ color: "red" }}>*</span>}
      </label>
      <Controller
        name={name}
        control={control}
        render={(props) => (
          <InputMask
            value={props.value}
            onChange={(e) => {
              onClearError();
              props.onChange(e);
            }}
            className={`${styles.borderBottom} p-inputtext p-component`}
            onBlur={(e) => props.onBlur(e)}
            mask="+7(999)999-9999"
            disabled={disabled}
          />
        )}
        rules={rules}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        as={<p className="errorText">message</p>}
      />
      {responseError && (
        <small className="p-error p-d-block">{`${responseError}`}</small>
      )}
    </div>
  );
}

export { MobileField, MobileFieldControl };
