import React from "react";
import { connect } from "react-redux";
import { postSetting } from "domain/actions/settings";
import { Button } from "primereact/button";

function ConfirmBtn(props) {
  return (
    <div className={props.className}>
      <Button
        label="Применить настройки"
        onClick={props.postSetting}
        className="с-button-success c-btns"
      />
    </div>
  );
}

export default connect(null, { postSetting })(ConfirmBtn);
