import React from "react";
import Header from "./head";
import Body from "./body";
import { WindowUser } from "../windows";

function Layout(props) {
  return (
    <div className="p-pt-3 p-pr-3 p-pl-3 ">
      <Header className="p-d-flex p-flex-wrap p-col-12 p-p-0 p-jc-between" />
      <Body />

      <WindowUser className="p-col-12 p-md-10 p-lg-8 p-xl-6 p-p-0 " />
    </div>
  );
}

export default Layout;
