export const SORT_USERS = "SORT_USERS";
export const FILTER_USERS = "FILTER_USERS";
export const RANGE_USERS = "RANGE_USERS";
export const SET_LIKE_USERS = "SET_LIKE_USERS";
export const REFRESH_USERS = "REFRESH_USERS";

export const SET_USERS = "SET_USERS";
export const SET_CURRENT_USERS = "SET_CURRENT_USERS";
export const SET_RIGHTS = "SET_RIGHTS";
export const POST_USERS = "POST_USERS";

export const SET_PAGINATION_USERS = "SET_PAGINATION_USERS";

export const SELECT_USERS = "SELECT_USERS";
export const BREAK_USERS = "BREAK_USERS";

export const SHOW_WINDOW_USERS = "SHOW_WINDOW_USERS";

export const SET_ROLES_USER = "SET_ROLES_USER";

export const RESPONSE_ERROR_USER = "RESPONSE_ERROR_USER";
