import React from "react";
import { connect } from "react-redux";
import { Menu } from "components/static";
import RefreshIcon from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";

import { getEquipmentsByFilter } from "domain/actions/equipment";
import {
  handleShowWindow,
  getBidByFilter,
  clearEditBid,
} from "domain/actions/equipment/bid";

function MenuComponent(props) {
  const refresh = (page) => {
    props.getEquipmentsByFilter(page);
    props.getBidByFilter(page);
  };

  const listMenu = [
    {
      id: "1",
      name: "Создать заявку",
      icon: AddIcon,
      callback: () => {
        props.clearEditBid();
        props.handleShowWindow(true);
      },
    },
    {
      id: "2",
      name: "Обновить страницу",
      icon: RefreshIcon,
      callback: () => refresh(props.pageIndex),
    },
  ];

  return <Menu list={listMenu} className={props.className} />;
}

const mapStateToProps = (state) => ({
  pageIndex: state.equipmentPagination.page,
});
export default connect(mapStateToProps, {
  getEquipmentsByFilter,
  handleShowWindow,
  getBidByFilter,
  clearEditBid,
})(MenuComponent);
