import React from "react";
import styles from "../style.module.css";

function EmptyData({ value = "Список пуст" }) {
  return (
    <div className={styles.emptyData}>
      <span>{value}</span>
    </div>
  );
}

export default EmptyData;
