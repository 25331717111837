import React from "react";
import styles from "../../../style.module.css";
import moment from "moment";

const DayFilter = React.forwardRef((props, ref) => {
  const onClick = () => {
    const start = moment().startOf("day").format();
    const end = moment().endOf("day").format();
    if (props.active) return props.onChange(!props.active);

    props.onChange(!props.active, start, end);
  };

  return (
    <div
      className={`${styles.fastDateFilter} ${
        props.active ? styles.active : ""
      }`}
      onClick={onClick}
      ref={ref}
    >
      День
    </div>
  );
});

export default DayFilter;
