import {
  SET_PAGINATION_RECEIPT,
  SET_PAGINATION_RECEIPT_TOTAL,
} from "../../../types/receipts";

const initialState = {
  limit: 1,
  page: 1,
  pages: 1,
  total: 120,
};

export default function paginationReceipt(state = initialState, action) {
  switch (action.type) {
    case SET_PAGINATION_RECEIPT:
      const { limit, page, pages, total } = action.payload;
      return {
        ...state,
        limit: limit,
        page: page,
        pages: pages,
        total: total,
      };
    case SET_PAGINATION_RECEIPT_TOTAL:
      return {
        ...state,
        total: action.payload.total,
      };
    default:
      return state;
  }
}
