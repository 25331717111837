import React from "react";
import { connect } from "react-redux";
import { addItem } from "domain/actions/createReceipt";

import Table from "./table";
import Window from "../../components/windows/products";
import styles from "../../styles.module.css";

import { Button } from "primereact/button";

function Products(props) {
  const addingItem = () => {
    props.addItem();
  };

  return (
    <div className={props.className}>
      <Table className="p-p-0 p-m-1 p-d-flex p-flex-wrap c-box-shadow" />

      <Button
        className={`${styles.btn} c-darkgreen-btn`}
        label="Добавить товар"
        icon="pi pi-plus"
        onClick={addingItem}
      />

      <Window />
    </div>
  );
}

export default connect(null, { addItem })(Products);
