import React from "react";
import styles from "../../style.module.css";

import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";

function SortComponent(props) {
  const onChange = () => {
    let value = props.value === null ? true : props.value ? false : null;
    props.onChange(value);
  };

  return (
    <div
      className={`${styles.sortComponent} ${
        props.value !== null ? styles.sortActive : ""
      }`}
      onClick={onChange}
    >
      <span>{props.label}</span>
      <div>
        <TrendingFlatIcon
          className={`${styles.arrowLeft} ${
            props.value === false ? styles.arrowLeftActive : ""
          }`}
        />
        <TrendingFlatIcon
          className={`${styles.arrowRight} ${
            props.value === true ? styles.arrowRightActive : ""
          }`}
        />
      </div>
    </div>
  );
}

export default SortComponent;
