import React from "react";

function Empty({ className }) {
  return (
    <div className={className}>
      <div></div>
      <p> </p>
    </div>
  );
}

export default Empty;
