import { SET_DATA_ACCOUNT, SET_STATIC_TOKEN_ACCOUNT } from "../actionTypes";

const initialState = {
  data: null,
  staticToken: "",
};

export default function authReduce(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_ACCOUNT:
      return {
        ...state,
        data: action.payload,
      };
    case SET_STATIC_TOKEN_ACCOUNT:
      return {
        ...state,
        staticToken: action.payload,
      };
    default:
      return state;
  }
}
