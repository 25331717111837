import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setProp } from "domain/actions/equipment/bid";
import { MultiSelectField } from "components/forms/fields";
import getItemsFromList from "controllers/getItemsFromList";

function ListSno(props) {
  const [value, setValue] = useState(props.item.snoListId);
  const getValue = props.getValue;

  useEffect(() => {
    const item = getItemsFromList(props.sno, props.item.snoListId);
    setValue(item);
  }, [props.sno, props.item.snoListId, setValue]);

  useEffect(() => {
    getValue(value);
  }, [getValue, value]);

  const getSnoIds = (data) => {
    setValue(data);

    props.setProp(
      "snoListId",
      data.map((i) => Number(i.id)),
      props.item.id
    );
  };

  const isActive = props.selected ? props.selected.status !== 11 : false;

  return (
    <MultiSelectField
      label="Список СНО"
      options={props.sno}
      className={props.className}
      value={value}
      onChange={getSnoIds}
      disabled={isActive}
      required
    />
  );
}

const mapStateToProps = (state) => ({
  sno: state.requisites.sno,
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { setProp })(ListSno);
