import React, { useState } from "react";
import { connect } from "react-redux";

import { setSortsCashboxes } from "domain/actions/equipment/equipment/filters";
import { Sorts } from "components/static";
import SortComponent from "components/static/sorts/components/Sort.component";

function SortsPanels({ setSortsCashboxes, className }) {
  const [createdAt, toggleDate] = useState(null);
  const [organizationId, toggleOrganization] = useState(null);
  const [cashboxModelId, toggleCashboxModel] = useState(null);

  const valueMethods = {
    createdAt: (val) => toggleDate(val),
    organizationId: (val) => toggleOrganization(val),
    cashboxModelId: (val) => toggleCashboxModel(val),
  };

  const setValue = (value, type) => {
    for (let key in valueMethods) {
      if (key === type) valueMethods[key](value);
      else {
        valueMethods[key](null);
      }
    }
  };

  const onChange = (value, type) => {
    setValue(value, type);

    let params = {};
    params[type] = value ? "ASC" : "DESC";

    setSortsCashboxes(params);
  };

  return (
    <Sorts className={className}>
      <div className="p-d-flex p-flex-wrap p-jc-between p-jc-md-center">
        <SortComponent
          label="Дата"
          value={createdAt}
          onChange={(value) => onChange(value, "createdAt")}
        />

        <SortComponent
          label="Организации"
          value={organizationId}
          onChange={(value) => onChange(value, "organizationId")}
        />

        <SortComponent
          label="Модель"
          value={cashboxModelId}
          onChange={(value) => onChange(value, "cashboxModelId")}
        />
      </div>
    </Sorts>
  );
}

export default connect(null, { setSortsCashboxes })(SortsPanels);
