import {
  SORT_BID_EQUIPMENTS,
  FILTER_BID_EQUIPMENTS,
  RANGE_BID_EQUIPMENTS,
} from "domain/types/equipment";

const initialState = {
  sort: null,
  range: null,
  filter: null,
  like: "",
};

export default function bidFilters(state = initialState, action) {
  switch (action.type) {
    case SORT_BID_EQUIPMENTS:
      return {
        ...state,
        sort: action.payload,
      };
    case RANGE_BID_EQUIPMENTS:
      return {
        ...state,
        range: action.payload,
      };
    case FILTER_BID_EQUIPMENTS:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
}
