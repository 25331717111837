import React from "react";
import { connect } from "react-redux";

import styles from "../style.module.css";

function Summary(props) {
  const data = props.data ? props.data.users : [];
  const sum = data
    .reduce((sum, current) => sum + current.moneyIn, 0)
    .toFixed(2);
  const count = data
    .reduce((sum, current) => sum + current.receiptsIn, 0)
    .toFixed(0);

  const sumComponent = (
    <div className={styles.sum}>
      <span className={styles.icon}>&#8381;</span>
      <span className={styles.number}>{sum}</span>
    </div>
  );
  const countComponent = (
    <div className={styles.count}>
      <span className={styles.icon}>&Nu;</span>
      <span className={styles.number}>
        {count}
        <span className={styles.iconTwo}>шт.</span>
      </span>
    </div>
  );
  return (
    <div className={props.className}>
      <span className="p-col-12 p-p-0 p-d-block block-label-title title-silver">
        Общие показатели
      </span>

      <div className="p-col-12 p-p-0 p-d-flex">
        <div className="p-col-6 p-pl-0 p-pr-1">{sumComponent}</div>

        <div className="p-col-6 p-pr-0 p-pl-1">{countComponent}</div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.analytics.data,
});
export default connect(mapStateToProps)(Summary);
