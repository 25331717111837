import {
  setSortsReceipts,
  setFiltersReceipts,
  setRangeReceipts,
} from "./filters";
import { TOGGLE_PROGRESS } from "../../actionTypes";
import {
  SET_RECEIPTS,
  SELECT_RECEIPTS,
  REFRESH_RECEIPTS,
  SHOW_RECEIPTS,
  SET_PAGINATION_RECEIPT,
  SHOW_SEND_PAY_LINK,
  SHOW_SEND_LINK_RECEIPT,
  SET_PAGINATION_RECEIPT_TOTAL,
} from "../../types/receipts";

import { SHOW_SUCCESS } from "../../actionTypes";

import ClientController from "utils/Client";
import { numericItem } from "utils/numericItem";

const Client = new ClientController();

function getReceipt(page) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: TOGGLE_PROGRESS, payload: true });

    await Client.sleep(300);

    const response = await Client.get(
      `/receipt/list?limit=15&page=${page}`
    ).catch((e) => console.error(e));

    dispatch({
      type: SET_RECEIPTS,
      payload: response.data.result.data,
    });

    dispatch({
      type: SET_PAGINATION_RECEIPT,
      payload: response.data.result,
    });

    dispatch({ type: TOGGLE_PROGRESS, payload: false });
    return true;
  };
}

function getReceiptById(id) {
  return async function thunk(dispatch, getState) {
    const response = await Client.get(`/receipt/info/${id}`);

    dispatch({ type: SELECT_RECEIPTS, payload: response.data.result });
    dispatch({ type: SHOW_RECEIPTS, payload: true });

    return;
  };
}

function getReceiptByIdSelect(id) {
  return async function thunk(dispatch, getState) {
    const response = await Client.get(`/receipt/info/${id}`);
    dispatch({ type: SELECT_RECEIPTS, payload: response.data.result });
    return;
  };
}

function getReceiptByFilter(page) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: TOGGLE_PROGRESS, payload: true });

    await Client.sleep(300);

    let params = { limit: 10, page: page };
    let filters = getFilters(getState);

    Object.assign(params, filters);

    return await Client.post(`/receipt/list`, params)
      .then((response) => {
        let result = response.data.result;

        dispatch({
          type: SET_RECEIPTS,
          payload: numericItem(result.data, result, filters.sort?.createdAt),
        });

        dispatch({
          type: SET_PAGINATION_RECEIPT,
          payload: result,
        });

        dispatch({ type: TOGGLE_PROGRESS, payload: false });
        return true;
      })
      .catch((e) => dispatch({ type: TOGGLE_PROGRESS, payload: false }));
  };
}

function getByCursor(cursor) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: TOGGLE_PROGRESS, payload: true });

    let filters = getFilters(getState);
    let params = { limit: 10, lastId: cursor.id, cursor: [] };
    if (filters?.sort?.createdAt) params.cursor = [cursor.createdAt];
    if (filters?.sort?.sumDoc) params.cursor = [cursor.sumDoc];

    Object.assign(params, filters);

    return await Client.post(`/receipt/list`, params)
      .then((response) => {
        let result = response.data.result;

        dispatch({
          type: SET_PAGINATION_RECEIPT_TOTAL,
          payload: { total: result.count },
        });

        dispatch({
          type: SET_RECEIPTS,
          payload: result.data,
        });
      })
      .finally((e) => dispatch({ type: TOGGLE_PROGRESS, payload: false }));
  };
}

function sendPayLink(email) {
  return async function thunk(dispatch, getState) {
    let receiptId = getState().receipts.select?.id;
    if (!receiptId) console.error("receipt not select");

    try {
      await Client.post(`/receipt/${receiptId}/sendPayLink`, { email });
      dispatch({ type: SHOW_SEND_PAY_LINK, payload: false });
    } catch (error) {
      console.error(error);
    }
  };
}

function sendLinkReceipt(email) {
  return async function thunk(dispatch, getState) {
    let receiptId = getState().receipts.select?.id;
    if (!receiptId) console.error("receipt not select");

    try {
      await Client.post(`/receipt/${receiptId}/sendReceiptLink`, { email });
      dispatch({ type: SHOW_SEND_LINK_RECEIPT, payload: false });
    } catch (error) {
      console.error(error);
    }
  };
}

function refundReceipt(receiptUuid) {
  return async function thunk(dispatch) {
    dispatch({ type: TOGGLE_PROGRESS, payload: true });

    return await Client.post(`/receipt/refund/${receiptUuid}`)
      .then(() => {
        dispatch({
          type: SHOW_SUCCESS,
          payload: "Выполнен запрос на 'возврат платежа'",
        });
      })
      .finally((e) => dispatch({ type: TOGGLE_PROGRESS, payload: false }));
  };
}

function selectedReceipt(receipt) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SELECT_RECEIPTS, payload: { receipt: receipt } });
    dispatch({ type: SHOW_RECEIPTS, payload: true });
  };
}

function closeWinReceipt() {
  return async function thunk(dispatch, getState) {
    return dispatch({ type: SHOW_RECEIPTS, payload: false });
  };
}

function showSendPayLink(show) {
  return async function thunk(dispatch, getState) {
    return dispatch({ type: SHOW_SEND_PAY_LINK, payload: show });
  };
}

function showSendLinkReceipt(show) {
  return async function thunk(dispatch, getState) {
    return dispatch({ type: SHOW_SEND_LINK_RECEIPT, payload: show });
  };
}

function refreshReceipt() {
  return async function thunk(dispatch, getState) {
    let refresh = getState().receipts.refresh;
    dispatch({ type: REFRESH_RECEIPTS, payload: !refresh });
  };
}

function getFilters(getState) {
  let filters = {
    sort: getState().receiptsFilter.sort,
    range: getState().receiptsFilter.range,
    filter: getState().receiptsFilter.filter,
  };

  for (let key in filters) {
    if (filters[key] === null) delete filters[key];
  }

  return filters;
}

const mapDispatchToProps = (dispatch) => {
  return {
    controller: {
      get: (page) => dispatch(getReceiptByFilter(page)),
      getById: (id) => dispatch(getReceiptById(id)),
      getByFilter: (page) => dispatch(getReceiptByFilter(page)),
      getByCursor: (cursor) => dispatch(getByCursor(cursor)),
      selected: (item) => dispatch(selectedReceipt(item)),
      refresh: () => dispatch(getReceiptByFilter(1)),
      closeWinReceipt: () => dispatch(closeWinReceipt()),
    },
  };
};

export default mapDispatchToProps;
export {
  setSortsReceipts,
  setFiltersReceipts,
  setRangeReceipts,
  refreshReceipt,
  selectedReceipt,
  getReceiptByFilter,
  getReceiptById,
  getReceipt,
  closeWinReceipt,
  showSendPayLink,
  showSendLinkReceipt,
  sendLinkReceipt,
  sendPayLink,
  getReceiptByIdSelect,
  refundReceipt,
};
