import React from "react";
import { connect } from "react-redux";
import { showSendPayLink } from "domain/actions/receipt";

import { Button } from "primereact/button";

function SendPayment(props) {
  const { showSendPayLink, className } = props;

  return (
    <div className={className}>
      <Button
        label="Отправить ссылку оплаты"
        className="p-button-success p-col-12"
        style={{ height: "50px" }}
        onClick={() => showSendPayLink(true)}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  item: state.receipts.select,
});

export default connect(mapStateToProps, { showSendPayLink })(SendPayment);
