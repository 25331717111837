import React from "react";
import { connect } from "react-redux";
import { handleShowConnect } from "domain/actions/organizations/acquiring";

import Window from "components/windows";
import ContentAdd from "./add";

function WindowCreateConnect(props) {
  const { handleShowConnect } = props;

  return (
    <Window
      className="p-col-12 p-md-6 p-p-0"
      header="Добавление нового подключения"
      visible={props.show}
      onHide={() => handleShowConnect(false)}
      style={{ minWidth: "340px" }}
      draggable={false}
    >
      <ContentAdd
        className="p-col-12 p-d-flex p-flex-wrap"
        handleShowConnect={handleShowConnect}
      />
    </Window>
  );
}

const mapStateToProps = (state) => ({
  show: state.orgCreate.connectShow,
});
export default connect(mapStateToProps, { handleShowConnect })(
  WindowCreateConnect
);
