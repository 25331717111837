import React from "react";
import { LabelToUpperCase } from "components/static";
import { connect } from "react-redux";

function HeaderComponent(props) {
  const item = props.item;
  const isMark = !!props.item.markingCode;
  console.log(item);
  const paymentObject = props.requisite.paymentObject.filter(
    (i) => i.eng === item.paymentObject
  );

  const paymentMethod = props.requisite.paymentMethod.filter(
    (i) => i.eng === item.paymentMethod
  );

  const tax = props.requisite.tax.filter((i) => i.eng === item.tax.type);

  return (
    <div
      className="p-pl-2"
      style={{ width: "calc(100% - 40px)", paddingTop: "7px" }}
    >
      <div className="p-d-flex p-jc-between">
        <span className="c-color-darkgreen block-label-title title-bold">
          {props.item.name} {isMark && "M"}
        </span>
        <div>
          <span className="p-text-right">{`${item.price} * ${item.quantity} `}</span>
          <span className="c-color-green">{`= ${(
            item.price * item.quantity
          ).toFixed(2)}`}</span>
        </div>
      </div>
      <LabelToUpperCase
        label={paymentObject.length ? paymentObject[0].name : "отсутствует"}
        value={paymentMethod.length ? paymentMethod[0].name : "отсутствует"}
      />
      <LabelToUpperCase
        label=""
        value={`НДС: ${tax.length ? tax[0].name : "отсутствует"}`}
        className="p-mb-1"
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  requisite: state.requisites,
});
export default connect(mapStateToProps)(HeaderComponent);
