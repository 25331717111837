import {
  SORT_EQUIPMENTS,
  FILTER_EQUIPMENTS,
  // RANGE_EQUIPMENTS,
  // LIKE_EQUIPMENTS,
  REFRESH_EQUIPMENTS,
} from "domain/types/equipment";

export function setSortsCashboxes(...params) {
  return async function thunk(dispatch, getState) {
    if (!Object.keys(params[0]).length) params = null;

    dispatch({
      type: SORT_EQUIPMENTS,
      payload: params ? params[0] : params,
    });

    refreshThunk(dispatch, getState);
  };
}

export function setFiltersEquipments(params) {
  return async function thunk(dispatch, getState) {
    if (!Object.keys(params).length) params = null;

    dispatch({
      type: FILTER_EQUIPMENTS,
      payload: params,
    });

    refreshThunk(dispatch, getState);
  };
}

async function refreshThunk(dispatch, getState) {
  let refresh = getState().equipments.refresh;

  dispatch({
    type: REFRESH_EQUIPMENTS,
    payload: !refresh,
  });

  return true;
}
