import React from "react";
import { connect } from "react-redux";
import {
  updateItem,
  toggleShow,
  selectedItem,
} from "domain/actions/createReceipt";
import { showError } from "domain/actions/error.actions";
import validate from "../../../../../controllers/validation";

function ConfirmButton(props) {
  const { handleSubmit } = props.formHook;

  const onConfirm = (data) => {
    data.amount = data.price * data.quantity;

    if (props.agents) data.agentInfo = props.agents;
    if (props.supplier) data.supplierInfo = props.supplier;

    if (props.codes) Object.assign(data, props.codes);

    let valid = validate(data);
    if (!valid.check) return props.showError(valid.error);

    props.updateItem(data);
    props.selectedItem(null);
    props.toggleShow(false);
  };

  return (
    <input
      className="p-d-none"
      id={props.id}
      type="submit"
      onClick={handleSubmit(onConfirm)}
    ></input>
  );
}

const mapStateToProps = (state) => ({
  supplier: state.createReceiptEdit.supplier,
  agents: state.createReceiptEdit.agents,
  codes: state.createReceiptEdit.codes,
});
export default connect(mapStateToProps, {
  updateItem,
  toggleShow,
  selectedItem,
  showError,
})(ConfirmButton);
