import React, { useEffect } from "react";
import styles from "../index.module.css";

import InputMask from "react-input-mask";


function MaskField({
  className = "",
  name = "",
  label = "",
  value = "",
  rules = false,
  mask="",
  onValidate = () => null,
  onChange = () => console.log("onChange"),
  onBlur = () => console.log("onBlur"),
  disabled = false,
  reqStar = false,
}) {
  if (value === null || value === undefined) {
    value = "";
  }

  useEffect(() => {
    if (rules && !value.length) return;
    onValidate(false);
  }, [rules, value, onValidate]);

  return (
    <div className={className}>
      <label className={`${styles.label} p-pb-1`}>
        {label} {reqStar && <span style={{ color: "red" }}>*</span>}
      </label>
      <InputMask
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        className={`${styles.borderBottom} p-inputtext p-component`}
        mask={mask}
        disabled={disabled}
      />
      {rules && !value.length && (
        <small className="p-error p-d-block">Обязательно для заполнения!</small>
      )}
    </div>
  );
}


export { MaskField };
