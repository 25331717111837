import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  handleShowConnect,
  handleShowConnectEdit,
} from "domain/actions/organizations/acquiring";
import { selectOrg } from "domain/actions/organizations";
import { SpinnerWrapper } from "components/wrappers";
import { DebounceInput } from "react-debounce-input";
import ConnectionsTable from "./table";
import orderBy from "lodash/orderBy";
import { Sorts } from "components/static";
import SortComponent from "components/static/sorts/components/Sort.component";
import { Button } from "primereact/button";
import WindowCreateConnect from "./create.win";
import WindowEditConnect from "./edit.win";

function filterByValue(array, name) {
  if (array.length === 0) return array;
  return array.filter((obj) => {
    return obj["name"].toLowerCase().includes(name.toLowerCase());
  });
}

function Connections(props) {
  const { select, handleShowConnect, handleShowConnectEdit, selectOrg } = props;
  const [active, toggleActive] = useState(null);
  const [license, toggleLicense] = useState(null);
  const [name, toggleName] = useState(null);
  const [accepts, setAccepts] = useState([]);
  const [listens, setListen] = useState("");
  const [currentItem, setItem] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const onShow = () => {
    handleShowConnect(true);
  };
  const onEdit = (item) => {
    setItem(item);
    handleShowConnectEdit(true);
  };
  const onRefresh = () => {
    setLoading(true);
    selectOrg(select.id).finally(() => setLoading(false));
  };

  useEffect(() => {
    if (select?.accepts) {
      const data = select.accepts.map((item) => {
        const license =
          item?.bindParams && item?.bindParams?.licenseId
            ? select.acceptLicenses[item.bindParams.licenseId]
            : null;
        item.license = license;
        return item;
      });

      setAccepts(data);
    }
  }, [select]);

  useEffect(() => {
    if (accepts.length && active !== null) {
      toggleLicense(null);
      toggleName(null);

      const sort = active ? "asc" : "desc";
      const sortData = orderBy(accepts, ["isActive"], [sort]);

      setAccepts(sortData);
    }
  }, [active]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accepts.length && license !== null) {
      toggleActive(null);
      toggleName(null);

      const sort = license ? "asc" : "desc";
      const sortData = orderBy(accepts, ["license"], [sort]);

      setAccepts(sortData);
    }
  }, [license]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accepts.length && name !== null) {
      toggleActive(null);
      toggleLicense(null);

      const sort = name ? "asc" : "desc";
      const sortData = orderBy(accepts, ["name"], [sort]);

      setAccepts(sortData);
    }
  }, [name]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (select?.accepts) {
      const array = select.accepts.map((accept) => {
        if (accept.name === null) accept.name = "";
        return accept;
      });
      setAccepts(filterByValue(array, listens));
    }
  }, [listens]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={props.className}>
      <div className="p-col-12 p-md-6 p-xl-4">
        <Button
          label="Добавить подключение"
          className="c-btns--gradient "
          onClick={() => onShow(true)}
        />
      </div>

      {select?.accepts && select.accepts.length ? (
        <SpinnerWrapper
          className="c-pagination-table p-col-12 p-p-0"
          progress={isLoading}
        >
          <>
            <Sorts className="p-flex-wrap p-col-12 p-p-0 p-d-flex p-p-md-1">
              <>
                <div className="p-d-flex p-flex-wrap p-col-12 p-p-0">
                  <div className="p-col-12 p-md-4 p-p-0 p-mt-2 p-mt-md-0">
                    <SortComponent
                      label="Наименование"
                      value={name}
                      onChange={(value) => {
                        toggleName(value);
                      }}
                    />
                  </div>

                  <div className="p-col-12 p-md-4 p-p-0 p-mt-2 p-mt-md-0">
                    <SortComponent
                      label="Активность токена"
                      value={active}
                      onChange={(value) => {
                        toggleActive(value);
                      }}
                    />
                  </div>

                  <div className="p-col-12 p-md-4 p-p-0 p-mt-2 p-mt-md-0">
                    <SortComponent
                      label="Действия лицензии"
                      value={license}
                      onChange={(value) => {
                        toggleLicense(value);
                      }}
                    />
                  </div>
                </div>
              </>
            </Sorts>

            <DebounceInput
              minLength={0}
              debounceTimeout={1000}
              placeholder="Поиск по наименованию"
              className="p-inputtext p-component"
              onChange={(event) => setListen(event.target.value)}
            />
            <ConnectionsTable
              className="p-col-12 p-p-1"
              accepts={accepts}
              cashboxes={select.cashbox}
              acquires={select.organizationAcquiring}
              acceptLicenses={select.acceptLicenses}
              setItem={onEdit}
              refresh={onRefresh}
            />
          </>
        </SpinnerWrapper>
      ) : (
        <div className="p-col-12 p-text-center empty-list">Список пуст ...</div>
      )}

      <WindowCreateConnect />
      <WindowEditConnect item={currentItem} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  select: state.orgCreate.select,
});
export default connect(mapStateToProps, {
  handleShowConnect,
  handleShowConnectEdit,
  selectOrg,
})(Connections);
