import React, { useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import styles from "../style.module.css";

function DateFilter(props) {
  const [visible, setVisible] = useState(false);
  const [dates, setDates] = useState(null);
  const [dateFormat, setDateFormat] = useState("Не выбрано");
  const [yearRange] = useState(`2010 : ${new Date().getFullYear()}`);

  useEffect(() => {
    let text = `Не выбрано`;
    if (dates && !!dates[0])
      text = `c ${moment(dates[0]).format("Do MMMM YYYY")}`;
    if (dates && !!dates[1]) {
      setVisible(false);
      text = `${text} по ${moment(dates[1]).format("Do MMMM YYYY")}`;
    }

    setDateFormat(text);
  }, [dates, setDateFormat]);

  useEffect(() => {
    let text = `Не выбрано`;
    if (props.date && !!props.date[0])
      text = `c ${moment(props.date[0]).format("Do MMMM YYYY")}`;
    if (props.date && !!props.date[1])
      text = `${text} по ${moment(props.date[1]).format("Do MMMM YYYY")}`;

    setDateFormat(text);
  }, [props.date]);

  const setDate = props.setDate;
  useEffect(() => {
    let start = null;
    let end = null;

    if (dates && !!dates[0]) start = moment(dates[0]).format();
    if (dates && !!dates[1]) end = moment(dates[1]).endOf("day").format();
    setDate([start, end]);
  }, [dates, setDate]);

  return (
    <div className={props.className}>
      <div className={styles.dFlex}>
        <span className={styles.datePickerLabel}> Даты: </span>
        <div className={styles.dFlexColumn}>
          <label
            htmlFor="DateFilter"
            className={styles.datePickerLabel}
            style={{ minWidth: "250px" }}
          >
            {dateFormat}
          </label>
          <Calendar
            inputId="DateFilter"
            value={dates}
            onChange={(e) => {
              if (!e.value) setDate(e.value);

              props.isDateEvent(true);
              setDates(e.value);
            }}
            selectionMode="range"
            onVisibleChange={(event) => {
              setVisible(event.visible);
              if (!event.visible) props.refresh();
            }}
            numberOfMonths={2}
            readOnlyInput
            showButtonBar
            visible={visible}
            className={styles.datePicker}
            yearNavigator
            yearRange={yearRange}
          />
        </div>
      </div>
      {props.children}
    </div>
  );
}

export default DateFilter;
