import React, { useEffect } from "react";
import { connect } from "react-redux";
import { showSendLinkReceipt } from "domain/actions/receipt";
import Content from "./layouts/content";
import Window from "components/windows";

function LinkReceiptWindow(props) {
  const setShow = props.showSendLinkReceipt;
  const show = props.show;

  useEffect(() => {
    return () => setShow(false);
  }, [setShow]);

  const header = <p className="p-m-0">Отправить ссылку на чек</p>;

  return (
    <Window
      className={``}
      header={header}
      visible={show}
      onHide={() => setShow(false)}
      modal={false}
      draggable={false}
    >
      <Content />
    </Window>
  );
}

const mapStateToProps = (state) => ({
  show: state.receipts.showSendLinkReceipt,
});
export default connect(mapStateToProps, { showSendLinkReceipt })(
  LinkReceiptWindow
);
