import React from "react";
import { connect } from "react-redux";

function FormComponent({ currentUser }) {
  let accountName = currentUser ? currentUser.accountName : "";
  let lastName = currentUser ? currentUser.lastName : "";
  let firstName = currentUser ? currentUser.firstName : "";
  let middleName = currentUser ? currentUser.middleName : "";

  return (
    <div className="p-col-12">
      <div className="p-col-12 p-d-flex p-jc-start">
        <b className="p-mr-1">Имя аккаунта:</b>
        <span className="">{accountName}</span>
      </div>

      <div className="p-col-12 p-d-flex p-jc-start">
        <b className="p-mr-1">ФИО:</b>
        <span className="">
          {lastName} {firstName} {middleName}
        </span>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.users.current,
});
export default connect(mapStateToProps)(FormComponent);
