import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { showError } from "domain/actions/error.actions";

import {
  putConnect,
  postConnectFiles,
  getConnectFile,
  selectOrg,
  regenerationToken,
} from "domain/actions/organizations";

import { TextField, ColorField } from "components/forms/fields";
import SingleUploadImage from "components/forms/upload";
import { Select } from "components/forms/fields";
import { Button } from "primereact/button";
import { SpinnerWrapper } from "components/wrappers";
import { showSuccess } from "domain/actions/error.actions";
import { WarningNewToken } from "./warning.new.toke";

const styleLabel = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "14px",
  color: "#000",
};

function ContentEdit(props) {
  const [isLoading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [sum, setSum] = useState("");
  const [acqType, setAcquiring] = useState(null);
  const [cashbox, setCashbox] = useState(null);
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [primary, setPrimary] = useState(null);
  const [background, setBackground] = useState(null);
  const [currentPrimary, setCurrentPrimary] = useState(null);
  const [currentBackground, setCurrentBackground] = useState(null);
  const {
    item,
    org,
    showError,
    showSuccess,
    getConnectFile,
    selectOrg,
    putConnect,
    postConnectFiles,
    handleShowConnect,
    regenerationToken,
  } = props;

  let refPrimary = useRef(null);
  let refBackground = useRef(null);

  useEffect(() => {
    if (!item) return;

    setLoading(true);
    getConnectFile(item.id)
      .then((r) => {
        setCurrentPrimary(r.frontImage);
        setCurrentBackground(r.background);

        if (item?.name) setName(item?.name);
        if (item?.bindParams?.paymentTitle)
          setTitle(item?.bindParams?.paymentTitle);
        if (item?.bindParams?.defaultOrgAcqId && org) {
          const filter = org.organizationAcquiring.filter(
            (acq) => acq.id === item?.bindParams?.defaultOrgAcqId
          );
          setAcquiring(filter.length ? filter[0] : null);
        }
        if (item?.bindParams?.defaultCashboxId && org) {
          const filter = org.cashbox.filter(
            (cash) => cash.id === item?.bindParams?.defaultCashboxId
          );
          setCashbox(filter.length ? filter[0] : null);
        }
        if (item?.bindParams?.defaultPaymentSum)
          setSum(item?.bindParams?.defaultPaymentSum);
        if (item?.bindParams?.textColor)
          setColor(item?.bindParams?.textColor.replace(/#/gi, ""));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [item]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRegenerationToken = () => {
    regenerationToken(item.id).then(() => {
      showSuccess("Сгенерирован новый токен для подключения");
    });
  };

  const confirm = () => {
    if (!name) return showError('Поле "Наименование" не заполнено');
    // if (!title) return showError('Поле "Описание" не заполнено');
    // if (!acqType) return showError('Поле "Тип платежной системы" не заполнено');
    // if (!cashbox) return showError('Поле "Касса" не заполнено');
    // if (!color) return showError('Поле "Цвет текста" не заполнено');

    const data = {
      acceptId: item.id,
      name,
      title,
      acqType,
      cashbox,
      color,
      defaultPaymentSum: sum,
      logo: item?.bindParams?.logo,
      background: item?.bindParams?.background,
    };

    setLoading(true);
    putConnect(data)
      .then((r) => {
        const images = {};

        if (primary) images.primary = primary;
        if (background) images.background = background;
        if (primary || background)
          return postConnectFiles({ id: item.id, ...images });
        return;
      })
      .then((r) => {
        return selectOrg(org.id);
      })
      .then((r) => {
        setLoading(false);
        handleShowConnect(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <SpinnerWrapper
      position="center"
      progress={isLoading}
      classNameSpinner="custom-spinner-connect"
    >
      <div className={props.className}>
        <TextField
          className="p-col-12"
          label="Наименование"
          value={name}
          onChange={setName}
          reqStar
        />

        <div className="p-col-12">
          <Select
            className="p-col-12 p-p-0"
            options={org ? org.organizationAcquiring : []}
            label="Тип платежной системы(по умолчанию)"
            optionLabel="acquiring.name"
            value={acqType}
            onChange={setAcquiring}
            // required
          />
        </div>

        <div className="p-col-12">
          <Select
            className="p-col-12 p-p-0"
            options={org ? org.cashbox : []}
            label="Касса(по умолчанию)"
            value={cashbox}
            onChange={setCashbox}
            // required
          />
        </div>

        <TextField
          className="p-col-12"
          label="Описание"
          value={title}
          onChange={setTitle}
          // reqStar
        />

        <TextField
          className="p-col-12"
          label="Сумма(по умолчанию)"
          value={sum}
          onChange={setSum}
          // reqStar
        />

        <Button
          label="Перегенерация токена"
          className="с-button-success p-col-12 p-md-6"
          onClick={() => {
            setVisible(true);
          }}
        />

        <ColorField
          className="p-col-12"
          label="Цвет текста"
          value={color}
          onChange={setColor}
          // reqStar
        />

        <div className="p-col-12 p-d-flex p-flex-wrap p-p-0">
          <div className="p-col-12 p-md-6">
            <label className={` p-pb-1`} style={styleLabel}>
              Основное изображение: <span style={{ color: "red" }}></span>
            </label>
            <SingleUploadImage
              ref={refPrimary}
              label={"Загрузить фотографию"}
              file={primary}
              onSelectFIle={setPrimary}
              currentImg={currentPrimary}
            />
          </div>

          <div className="p-col-12 p-md-6">
            <label className={`p-pb-1`} style={styleLabel}>
              Фоновое изображение: <span style={{ color: "red" }}></span>
            </label>
            <SingleUploadImage
              ref={refBackground}
              label={"Загрузить фотографию"}
              file={background}
              onSelectFIle={setBackground}
              currentImg={currentBackground}
            />
          </div>
        </div>

        <div className="p-col-12 p-d-flex p-flex-wrap p-jc-end p-p-0">
          <Button
            label="Применить"
            className="с-button-success p-col-12 p-md-6"
            onClick={confirm}
          />
        </div>
      </div>

      <WarningNewToken
        visible={visible}
        setVisible={setVisible}
        accept={handleRegenerationToken}
      />
    </SpinnerWrapper>
  );
}

const mapStateToProps = (state) => ({
  org: state.orgCreate.select,
});
export default connect(mapStateToProps, {
  showError,
  putConnect,
  postConnectFiles,
  selectOrg,
  getConnectFile,
  regenerationToken,
  showSuccess,
})(ContentEdit);
