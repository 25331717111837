import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import Title from "../titleBlock";
import Slide1 from "./components/Slide_1";
import Slide2 from "./components/Slide_2";
import Slide3 from "./components/Slide_3";

// import styles from "../../../index.module.css";

function HowWeSolveProblems(state) {
  SwiperCore.use([Navigation]);

  return (
    <div className={state.className}>
      <Title name={state.params.name} id={state.params.id} />

      <Swiper slidesPerView={1} navigation>
        <SwiperSlide className="p-col-12">
          <Slide1 />
        </SwiperSlide>

        <SwiperSlide className="p-col-12">
          <Slide2 />
        </SwiperSlide>

        <SwiperSlide className="p-col-12">
          <Slide3 />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default HowWeSolveProblems;
