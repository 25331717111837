import React from "react";
import { connect } from "react-redux";
import Logotype from "components/logotype";
import styles from "./header.module.css";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import { useAuth } from "components/auth/hooks";
import Notification from "routes/features/notification";

import { confirmPopup } from "primereact/confirmpopup";
import { Menu } from "@material-ui/icons";
import { Button } from "primereact/button";
import WarnMessage from "routes/features/notification/warn";

function HeaderComponent({ user, onSidebar }) {
  const { onLogout } = useAuth();

  const handleLogout = async () => await onLogout();

  const accept = () => handleLogout();

  const reject = () => {};

  const confirmLogout = (event) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Вы действительно хотите выйти?",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept,
      reject,
    });
  };

  return (
    <>
      <header
        className={`p-d-flex p-justify-between p-pr-1 p-pl-1 p-col-12 ${styles.header}`}
      >
        <div className="p-d-flex p-pt-1 p-pb-1">
          <Button className={`${styles.icon} p-button-text`}>
            <Menu className="c-mdi-icon--header" onClick={onSidebar} />
          </Button>

          <a href="/" className={`p-d-flex p-p-0`}>
            <Logotype className="customer-logotype" />
          </a>
        </div>

        <div className="p-d-flex">
          <SettingsIcon
            style={{
              alignSelf: "center",
              color: "rgba(0, 0, 0, 0.3)",
            }}
            className="c-mdi-icon--header p-d-none"
          />
          <Notification className="p-d-flex p-flex-column p-jc-center" />
          <span
            className={`${styles.currentUser} p-d-none p-d-md-flex p-jc-center p-flex-column p-mr-2 p-ml-2`}
          >
            {user ? user.accountName : ""}: {user ? user.lastName : ""}{" "}
            {user ? user.firstName : ""}
          </span>
          <Button
            onClick={confirmLogout}
            icon="pi pi-sign-out"
            className={`${styles.iconPrime} p-button-text`}
          ></Button>
        </div>
      </header>

      <WarnMessage />
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.users.current,
});

export default connect(mapStateToProps)(HeaderComponent);
