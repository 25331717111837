import React, { useState, useEffect } from "react";
import TuneIcon from "@material-ui/icons/Tune";
import styles from "../style.module.css";

function FiltersIcon(props) {
  const [active, setActive] = useState(true);
  const onClick = () => {
    if (!active) return;
    props.setActive(!props.active);

    setActive(false);
    setTimeout(() => setActive(true), 1000);
  };

  return (
    <div
      className={`${styles.filtersIcon} ${
        !props.active ? "" : styles.filtersIconActive
      }`}
      onClick={onClick}
    >
      <span>Фильтры</span>
      <TuneIcon />
    </div>
  );
}

function MultiSelectFilter(props) {
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    if (props.active) setTimeout(() => setOverflow(props.active), 1000);

    if (!props.active) setOverflow(props.active);
  }, [props.active]);

  return (
    <div
      className={`${props.className} ${styles.filterComponent} ${
        !props.active ? "" : styles.filterComponentActive
      } ${overflow && props.active ? styles.overflow : ""}`}
    >
      {props.children}
    </div>
  );
}

export { MultiSelectFilter, FiltersIcon };
