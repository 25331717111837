import React from "react";
import { Label } from "components/based";
import toStatus from "controllers/toStatusReceipt";

import styles from "../../../../style.module.css";

function Status(props) {
  const status = (
    <div className={styles.status + " line-height-14"}>
      {toStatus(props.status)}
    </div>
  );

  return (
    <div className={props.className}>
      <div className="p-d-flex p-col-12 p-p-0">
        <Label value="Статус чека" className="p-mr-4" />
        {status}
      </div>
    </div>
  );
}

export default Status;
