import React from "react";
import Title from "../titleBlock";
import { ListCMS } from "./layouts";

const cms = [
  {
    id: 1,
    name: "1С Битрикс",
    img: "/img/bitrix_logo.png",
    urlManual: process.env.PUBLIC_URL + "/docs/1CBitrix_1.0_20210611.pdf",
    urlLoadingModule: "",
  },
  {
    id: 2,
    name: "WordPress",
    img: "/img/wordpress_logo.png",
    urlManual: process.env.PUBLIC_URL + "/docs/WordPress_1.0_20210611.pdf",
    urlLoadingModule: "",
  },
];

function IntegrationCMS(props) {
  return (
    <div className={props.className}>
      <Title name={props.params.name} id={props.params.id} />

      <ListCMS data={cms} className="p-col-12 p-p-0 p-d-flex p-flex-wrap" />
    </div>
  );
}

export default IntegrationCMS;
