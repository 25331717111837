import React from "react";
import { connect } from "react-redux";
import { postBid, putBid } from "domain/actions/equipment/bid";
import { Button } from "primereact/button";

function ConfirmButton(props) {
  const confirm = () => {
    if (props.selected) return props.putBid(props.selected.id);

    return props.postBid();
  };

  return (
    <div className={props.className}>
      <Button
        label={!props.selected ? "Подать заявку" : "Изменить заявку"}
        className="с-button-success"
        onClick={confirm}
        disabled={props.isLocal === null}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLocal: state.bidEquipments.isLocal,
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { postBid, putBid })(ConfirmButton);
