import React from "react";

const style = {
  width: "100%",
};
function LineBorder({ height = "1px", color = "#4CAF50", className = "" }) {
  let styles = Object.assign({}, style);
  styles.height = height;
  styles.backgroundColor = color;

  return <div style={styles} className={className}></div>;
}

export default LineBorder;
