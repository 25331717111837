import React from "react";
import { connect } from "react-redux";
import { postOrderACall } from "domain/actions/forms.actions";

import { Button } from "primereact/button";

import styles from "../../../../../style.module.css";

function ConfirmButton(props) {
  const onConfirm = async (data) => {
    props.postOrderACall(data).then((r) => props.confirm(true));
  };

  return (
    <div className="p-col-12 p-d-flex p-jc-end">
      <div className={`p-col-12 p-p-0 ${styles.buttons}`}>
        <Button
          label="Отправить"
          className="p-col-12 p-p-0 с-button-success"
          type="confirm"
          onClick={props.handleSubmit(onConfirm)}
          disabled={!props.access}
        />
      </div>
    </div>
  );
}

export default connect(null, { postOrderACall })(ConfirmButton);
