import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setFiltersEquipments } from "domain/actions/equipment/equipment/filters";

import { MultiSelectFilter } from "components/static/multiSelectFilter";
import MultiSelectComponent from "components/static/multiSelectFilter/components/MultiSelectFilter";
import ActiveComponent from "./isActive";
import VirtualComponent from "./isVirtual";

function Filters(props) {
  const [cashboxes, setCashbox] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [isVirtual, setIsVirtual] = useState(false);

  const setFiltersEquipments = props.setFiltersEquipments;

  useEffect(() => {
    let params = {
      cashboxModelId: cashboxes && cashboxes.length ? cashboxes : null,
    };

    for (let key in params) {
      if (params[key] === null) {
        delete params[key];
      } else {
        params[key] = params[key].map((i) => i.id);
      }
    }

    const activeIds = isActive ? [0] : [1, 2];
    params.status = isActive !== null ? activeIds : null;

    params.getVirtual = isVirtual;

    setFiltersEquipments(params);
  }, [cashboxes, isActive, isVirtual, setFiltersEquipments]);

  return (
    <MultiSelectFilter className={props.className} active={props.showFilters}>
      <div className="p-d-flex p-flex-wrap">
        <MultiSelectComponent
          label="Модель"
          optionLabel="model"
          value={cashboxes}
          setValue={setCashbox}
          options={props.listModel}
          className="p-d-flex p-col-12 p-lg-6"
        />

        <ActiveComponent
          value={isActive}
          setValue={setIsActive}
          className="p-d-flex p-col-12 p-lg-6 p-as-center"
        />

        <VirtualComponent
          value={isVirtual}
          setValue={setIsVirtual}
          className="p-d-flex p-col-12 p-as-center"
        />
      </div>
    </MultiSelectFilter>
  );
}

const mapStateToProps = (state) => ({
  listModel: state.requisites ? state.requisites.cashboxModel : [],
  showFilters: state.equipmentFilters.show,
});
export default connect(mapStateToProps, {
  setFiltersEquipments,
})(Filters);
