import React from "react";
import { CheckboxField } from "components/forms/fields";

import styles from "../../../style.module.css";

function PrintOnPaper(props) {
  return (
    <CheckboxField
      label="Распечатать на бумаге"
      className={`${styles.printOnPaper} ${props.className}`}
      checked={props.value}
      onChange={(e) => props.setValue(e.checked)}
    />
  );
}

export default PrintOnPaper;
