import {
  SET_DATA_ANALYTICS,
  SET_PROGRESS_ANALYTICS,
  SET_FILTER_ANALYTICS_CASHBOX,
} from "domain/types/analytics";

const initialState = {
  data: null,
  receipts: null,
  filters: {
    cashbox: {
      status: [0],
    },
  },
  organizations: null,
  isProgress: false,
};

export default function analytics(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_ANALYTICS:
      return {
        ...state,
        data: action.payload,
      };
    case SET_FILTER_ANALYTICS_CASHBOX:
      return {
        ...state,
        filters: {
          ...state.filters,
          cashbox: action.payload,
        },
      };
    case SET_PROGRESS_ANALYTICS:
      return {
        ...state,
        isProgress: action.payload,
      };
    default:
      return state;
  }
}
