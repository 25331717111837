import {
  Main,
  Analytics,
  Organizations,
  Users,
  Receipts,
  Payments,
  CreateReceipt,
  Equipments,
  Settings,
  NotFound,
  Templates,
  Services,
} from "../pages";

let privateRoute = [
  {
    path: "/main",
    component: Main,
    routes: [
      {
        path: "/main/analytics",
        component: Analytics,
      },
      {
        path: "/main/organizations",
        component: Organizations,
      },
      {
        path: "/main/users",
        component: Users,
      },
      {
        path: "/main/receipts",
        component: Receipts,
      },
      {
        path: "/main/payments",
        component: Payments,
      },
      {
        path: "/main/equipments",
        component: Equipments,
      },
      {
        path: "/main/settings",
        component: Settings,
      },
      {
        path: "/main/create/receipt",
        component: CreateReceipt,
      },
      {
        path: "/main/templates",
        component: Templates,
      },
      {
        path: "/main/services",
        component: Services,
      },
      {
        path: "/main/*",
        component: NotFound,
      },
    ],
  },
];

export default privateRoute;
