import React from "react";
import { HTable } from "components/based";

function HeadTable(props) {
  return (
    <div className={props.className}>
      <HTable className="p-col-2 p-p-1 p-text-start" value="Название" />
      <HTable
        className="p-col-2 p-p-1 p-text-center"
        value="Тип оборудования"
      />
      <HTable className="p-col-2 p-p-1 p-text-center" value="Заводской номер" />
      <HTable
        className="p-col-2 p-p-1 p-text-center"
        value="Действие лицензии"
      />
      <HTable className="p-col-2 p-p-1 p-text-center" value="Организация" />
      <div className="p-col-2 p-p-0 p-text-center p-d-flex">
        <HTable className="p-col-6 p-p-1 p-text-center" value="Статус" />
        <HTable className="p-col-6 p-p-1 p-text-center" value="Просмотр" />
      </div>
    </div>
  );
}

export default HeadTable;
