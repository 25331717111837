import React from "react";
import {
  CashMonitoring,
  CashRevenue,
  OrganizationsRevenue,
  // ReceiptAverage,
  // ReceiptCount,
  ReceiptTypePayments,
  RevenueInterval,
  RevenueWeek,
} from "./components";

function Dashboard(props) {
  const classNameField = "p-col-12 p-p-1 p-md-6 p-xl-4";
  return (
    <div className="p-col-12 p-d-flex p-flex-wrap">
      <RevenueInterval className="p-col-12 p-lg-6" />
      <RevenueWeek className="p-col-12 p-lg-6" />

      <ReceiptTypePayments className={classNameField} />
      <OrganizationsRevenue className={classNameField} />
      {
        //<ReceiptCount className={classNameField} />
      }
      <CashMonitoring className={classNameField} />
      <CashRevenue className={classNameField} />
      {
        //<ReceiptAverage className={classNameField} />
      }
    </div>
  );
}

export default Dashboard;
