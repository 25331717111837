import React from "react";
import { connect } from "react-redux";
import { CashboxData, GeneralData, PanelButtons, ListSno } from "./components";
import CancelButton from "components/btns/cancelBtn";
import { handleShowWindow } from "domain/actions/equipment";
// import { PayoutWindow } from "../../../../windows";

function Layouts(props) {
  const { handleShowWindow } = props;
  return (
    <div className={props.className}>
      <GeneralData />
      <CashboxData />
      <ListSno />

      <PanelButtons
        className="p-col-12 p-p-0 p-mt-3 p-d-flex p-flex-wrap"
        active={props.item.status === 0}
      />

      <div className="p-col-12 p-d-flex p-p-0">
        <CancelButton
          className="p-col-12 p-md-6 p-xl-4"
          onShow={() => handleShowWindow(false)}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  item: state.equipments.select,
});
export default connect(mapStateToProps, { handleShowWindow })(Layouts);
