import React from "react";
import { Dropdown } from "primereact/dropdown";
import { Label } from "components/based";

function DropdownField(props) {
  return (
    <div className={props.className}>
      <Label className="p-d-block p-d-xl-none" value={props.label} />
      <Dropdown
        value={props.value}
        options={props.options}
        optionLabel="name"
        className="c-border-bottom"
        onChange={(e) => props.onChange(props.name, e.value)}
        disabled={props.disabled}
      />
    </div>
  );
}

export default DropdownField;
