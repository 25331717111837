import React from "react";
import { Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { isINN } from "../../utils/rus.tax.codes.util";
import { ErrorMessage } from "@hookform/error-message";

function InputVatin({
  control,
  errors,
  name = "InputVatin",
  className = "",
  label = "ИНН",
  defaultValue = null,
}) {
  return (
    <div className={className}>
      <label htmlFor="type-receipt" className="c-label-font p-pb-1">
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        render={(props) => (
          <InputText
            value={props.value}
            onChange={(e) => {
              let number = e.target.value.replace(/[\D]+/g, "");
              if (number.length <= 12) props.onChange(e);
            }}
            className="c-input c-input-opacity-1"
            keyfilter="int"
          />
        )}
        rules={{
          validate: (value) => {
            if (!value.length) {
              return true;
            }

            if (!isINN(value)) {
              return "Введенный ИНН не прошел проверку";
            }

            return true;
          },
        }}
        defaultValue={defaultValue ? defaultValue : ""}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        as={<p className="errorText">message</p>}
      />
    </div>
  );
}

export default InputVatin;
