import axios from "axios";
import store from "domain/store";
import ErrorController from "../domain/Error";
import { SHOW_ERROR, SET_VISIBLE_UNAUTHORIZED } from "domain/actionTypes";
class ClientController {
  constructor() {
    this.Error = new ErrorController();
  }
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  DataException(error, message) {
    this.message = message;
    this.error = error;
  }

  dispatchCall(type, payload) {
    store.dispatch({
      type: type,
      payload: payload,
    });
  }
  getStateLoading() {
    return store.getState().auth.loading;
  }
  getCountError() {
    return store.getState().auth.countError;
  }

  getTokensStorage() {
    let tokens = {
      accessToken: null,
    };

    if (localStorage.getItem("AccessToken")) {
      tokens.accessToken = localStorage.getItem("AccessToken");
    } else if (sessionStorage.getItem("AccessToken")) {
      tokens.accessToken = sessionStorage.getItem("AccessToken");
    }

    return tokens;
  }

  getHeaders() {
    const env = process.env;
    const tokens = this.getTokensStorage();

    let headers = {
      timeout: 10000,
      headers: {
        "Content-Type": "application/json",
      },
      baseURL:
        env.REACT_APP_ENVIRONMENT === "production"
          ? env.REACT_APP_BACKEND_PROD
          : env.REACT_APP_BACKEND_LOCAL,
    };

    if (tokens.accessToken) {
      headers.headers.Authorization = `Bearer ${tokens.accessToken}`;
    }

    if (env.REACT_APP_ENVIRONMENT === "development") {
      headers.baseURL = env.REACT_APP_BACKEND_DEV;
    }

    return headers;
  }

  handleError(e) {
    const timeoutRegexp = /timeout/i;
    const timeout = "Убедитесь в подключении к Интернету и повторите запрос";
    const forbidden = "У вас недостаточно прав на выполнение данной операции";
    const unauthorized = "Пользователь не авторизован";

    if (e.message === "Network Error") {
      this.dispatchCall(SHOW_ERROR, timeout);
      throw new Error(timeout);
    }

    if (timeoutRegexp.test(e.message)) {
      this.dispatchCall(SHOW_ERROR, timeout);
      throw new Error(timeout);
    }

    if (e.response.status === 403) {
      this.dispatchCall(SHOW_ERROR, forbidden);
      throw new Error(forbidden);
    }

    if (e.response.status === 401) {
      this.dispatchCall(SET_VISIBLE_UNAUTHORIZED, true);
      throw new Error(unauthorized);
    }

    if (e.response.data && e.response.data.error === "1220") {
      // this.dispatchCall(SHOW_ERROR, this.Error.getText(e));
      throw new this.DataException(
        e.response.data.error,
        this.Error.getText(e)
      );
    }

    this.dispatchCall(SHOW_ERROR, this.Error.getText(e));
    throw new Error(e.message);
  }

  async get(urlP) {
    const url = `/api${urlP}`;

    return await axios
      .get(url, this.getHeaders())
      .catch((e) => this.handleError(e));
  }

  async post(urlP, body) {
    const url = `/api${urlP}`;

    return await axios
      .post(url, body, this.getHeaders())
      .catch((e) => this.handleError(e));
  }

  async put(urlP, body) {
    const url = `/api${urlP}`;

    return await axios
      .put(url, body, this.getHeaders())
      .catch((e) => this.handleError(e));
  }

  async delete(urlP) {
    const url = `/api${urlP}`;

    return await axios
      .delete(url, this.getHeaders())
      .catch((e) => this.handleError(e));
  }
}

export default ClientController;
