import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Pagination from "components/static/pagination";
import { getOrganizations } from "domain/actions/organizations";
import { SpinnerWrapper } from "components/wrappers";
import CardOrg from "./org.card";
import { ScrollPanel } from "primereact/scrollpanel";

function OrgList(props) {
  const [page, setPage] = useState(1);
  const [update, setUpdate] = useState(1);
  const {
    selected,
    setSelect,
    orgs,
    getOrganizations,
    pagination,
    select,
  } = props;

  useEffect(() => getOrganizations(page), [getOrganizations, page]);

  useEffect(() => {
    if (select.scope) {
      const scope = select.scope.scope;
      if (scope?.organization) {
        const org = [];
        scope.organization.forEach((id) => {
          const _filter = orgs.filter((org) => org.id === id);
          if (_filter.length) org.push(_filter[0]);
        });
        setSelect(org);
      }
    }
  }, [select]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderedListOrgs = orgs.map((i, index) => {
    const isSelect = selected.map((org) => org.id).includes(i.id);
    return (
      <CardOrg
        key={index}
        update={update}
        item={i}
        setUpdate={setUpdate}
        setSelect={(item) => {
          setSelect((prev) => {
            const index = prev.map((org) => org.id).indexOf(i.id);
            if (index > -1) {
              prev.splice(index, 1);
            } else {
              prev.push(item);
            }
            return prev;
          });
        }}
        isSelect={isSelect}
      />
    );
  });

  return (
    <>
      <Pagination callback={setPage} pagination={pagination} />
      <ScrollPanel
        style={{
          width: "100%",
          height: "290px",
          boxShadow: "0px 0px 6px silver",
        }}
      >
        <SpinnerWrapper className="c-pagination-table">
          <div className="p-col-12 p-p-1">{renderedListOrgs}</div>
        </SpinnerWrapper>
      </ScrollPanel>
      <Pagination callback={setPage} pagination={pagination} />
    </>
  );
}

const mapStateToProps = (state) => ({
  select: state.users.select,
  orgs: state.organizations.organizations,
  pagination: state.orgPagination,
});
export default connect(mapStateToProps, { getOrganizations })(OrgList);
