import React, { useEffect, useRef, useState } from "react";
import styles from "../index.module.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { AddressSuggestions } from "react-dadata";

function KladrField(props) {
  const [updateKey, forceUpdate] = useState(1);

  const onValidate = props.onValidate;
  let ref = useRef(null);

  useEffect(() => {
    if (
      props.rules &&
      typeof props.value === "object" &&
      !props.value.value.length
    )
      return;
    !!onValidate && onValidate(false);
  }, [props.rules, props.value, onValidate]);

  useEffect(() => {
    if (props.defaultQuery.length) {
      forceUpdate((prev) => prev + 1);
    }
  }, [props.defaultQuery, forceUpdate]);

  return (
    <div className={props.className} disabled={props.disabled} key={updateKey}>
      <label className={`${styles.label} p-pb-1`}>{props.label}</label>
      <AddressSuggestions
        ref={ref}
        token={process.env.REACT_APP_KLADR_KEY}
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          className: `${styles.borderBottom} p-inputtext p-component`,
          disabled: props.disabled,
        }}
        defaultQuery={props.defaultQuery ? props.defaultQuery : ""}
        disabled={props.disabled}
      />

      {props.rules &&
        (typeof props.value === "object"
          ? !props.value.value.length
          : !props.defaultQuery.length) && (
          <small className="p-error p-d-block">
            Обязательно для заполнения!
          </small>
        )}
    </div>
  );
}

function KladrFieldControl(props) {
  let rules = { validate: props.validate };

  if (props.required) {
    rules.required = "Обязательно для заполнения!";
  }

  return (
    <div className="p-col-12 p-d-flex p-flex-column">
      <label className={`${styles.label} p-pb-1`}>{props.label}</label>
      <Controller
        as={AddressSuggestions}
        name={props.name}
        control={props.control}
        token={process.env.REACT_APP_KLADR_KEY}
        rules={rules}
        defaultValue=""
      />
      <ErrorMessage
        errors={props.errors}
        name={props.name}
        as={<p className="errorText">message</p>}
      />
    </div>
  );
}

export { KladrField, KladrFieldControl };
