import React, { useEffect, useState } from "react";
import { Paginator } from "primereact/paginator";

import styles from "./style.module.css";

const style = {
  disabled: {
    opacity: ".5",
  },
  enabled: {
    opacity: "1",
  },
};

function Pagination(props) {
  const [first, setFirst] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const onPageChange = (event) => {
    if (disabled) return false;

    setFirst(event.first);
    props.callback(event.page + 1);

    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 600);
  };
  useEffect(() => {
    setFirst(props.pagination.limit * (props.pagination.page - 1));
  }, [props.pagination, setFirst]);

  return (
    <React.Fragment>
      <Paginator
        first={first}
        currentPage={props.pagination.page}
        rows={props.pagination.limit}
        totalRecords={props.pagination.total}
        onPageChange={onPageChange}
        style={disabled ? style.disabled : style.enabled}
        className={styles.pagination}
      ></Paginator>
    </React.Fragment>
  );
}

export default Pagination;
