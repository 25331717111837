import React from "react";
import { connect } from "react-redux";
import {
  Cashboxes,
  BtnAdd,
  ConfirmBtn,
  Parameters,
  // DeleteBtn,
} from "./components";
import CancelButton from "components/btns/cancelBtn";
import { handleShowWindow } from "domain/actions/equipment/bid";

function Layouts(props) {
  const isVisible = props.selected ? props.selected.status === 11 : true;
  const { handleShowWindow, organizationId } = props;

  return (
    <div className={props.className}>
      <Parameters className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-jc-between" />
      {organizationId && (
        <>
          <Cashboxes />

          <BtnAdd
            className={`p-col-12 p-p-0 p-mt-4 p-mb-4 c-box-shadow c-color-green ${
              isVisible ? "" : "p-d-none"
            }`}
          />

          <div
            className={`p-col-12 p-p-0 p-mt-6 p-flex-wrap p-jc-between ${
              isVisible ? "p-d-flex" : "p-d-none"
            }`}
          >
            {/* <div className="p-col-12 p-md-4 p-lg-3 p-xl-2 p-pl-0 p-pr-0 p-order-1 p-order-md-0"></div> */}
            {/* <DeleteBtn className="p-col-12 p-md-4 p-lg-3 p-xl-2 p-pl-0 p-pr-0 p-order-1 p-order-md-0" /> */}
            <CancelButton
              onShow={() => handleShowWindow(false)}
              className="p-col-12 p-md-4 p-lg-3 p-xl-2 p-pl-0 p-pr-0 p-order-0 p-order-md-1"
            />
            <ConfirmBtn className="p-col-12 p-md-4 p-lg-3 p-xl-2 p-pl-0 p-pr-0 p-order-0 p-order-md-1" />
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  selected: state.bidList.select,
  organizationId: state.bidEquipments.organizationId,
});
export default connect(mapStateToProps, { handleShowWindow })(Layouts);
