import {
  SET_EQUIPMENTS,
  BREAK_EQUIPMENTS,
  SELECT_EQUIPMENTS,
  SHOW_WINDOW__EQUIPMENTS,
} from "../actionTypes";
import { REFRESH_EQUIPMENTS } from "../types/equipment";

const initialState = {
  equipments: [],
  select: null,
  refresh: false,
  show: false,
  break: false,
};

export default function equipmentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EQUIPMENTS:
      return {
        ...state,
        equipments: action.payload,
        break: true,
      };
    case SELECT_EQUIPMENTS:
      return {
        ...state,
        select: action.payload.equipment,
      };
    case BREAK_EQUIPMENTS:
      return {
        ...state,
        break: action.payload,
      };
    case SHOW_WINDOW__EQUIPMENTS:
      return {
        ...state,
        show: action.payload,
      };
    case REFRESH_EQUIPMENTS:
      return {
        ...state,
        refresh: action.payload,
      };
    default:
      return state;
  }
}
