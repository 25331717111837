import React from "react";

function Pictures({ className }) {
  return (
    <div className={className}>
      <img
        src={process.env.PUBLIC_URL + "/img/greeting/trying.jpg"}
        alt="Для кого мы стараемся?"
      />
      <img
        src={process.env.PUBLIC_URL + "/img/greeting/tag.png"}
        alt="Для кого мы стараемся?"
      />
    </div>
  );
}

export default Pictures;
