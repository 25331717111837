import React from "react";

import { Column } from "components/dataView";
import InfoIcon from "@material-ui/icons/Info";

import ColumnTypeToString from "controllers/ColumnTypeToString";

function Card(props) {
  const TypeToString = new ColumnTypeToString();
  const item = props.item;

  return (
    <div className="p-d-flex p-flex-wrap p-pt-4 p-pb-4 c-card-bb">
      <Column
        className="p-col-7 p-md-2 p-order-0 p-p-1 p-as-center"
        classTitle="p-d-none"
        classBody="c-color-green p-text-left"
        title="Название"
        value={
          item.cashboxModel ? item.cashboxModel.model : "Нет названия кассы"
        }
      />

      <Column
        className="p-col-6 p-sm-3 p-md-2 p-p-1 p-as-center p-order-2 p-order-md-1"
        classTitle="p-d-block p-d-md-none"
        title="Тип оборудования"
        value={item.address === "virtual" ? "Виртуальная касса" : "Касса"}
      />

      <Column
        className="p-col-6 p-sm-3 p-md-2 p-p-1 p-as-center p-order-3 p-order-md-2"
        classTitle="p-d-block p-d-md-none"
        title="Заводской номер"
        value={item.factoryNumber}
      />

      <Column
        className="p-col-6 p-sm-3 p-md-2 p-p-1 p-as-center p-order-4 p-order-md-3"
        classTitle="p-d-block p-d-md-none"
        title="Действие лицензии"
        value={TypeToString.toDateColor(item.license?.activeTo)}
      />

      <Column
        className="p-col-6 p-sm-3 p-md-2 p-p-1 p-as-center p-order-5 p-order-md-4"
        classTitle="p-d-block p-d-md-none"
        title="Организация"
        value={item.organization ? item.organization.name : ""}
      />

      <div className="p-col-5 p-md-2 p-d-flex p-p-1 p-order-1 p-order-md-5">
        <Column
          className="p-col-6 p-p-1 p-as-center "
          classTitle="p-d-none"
          title="Статус"
          value={TypeToString.toStatusEquipment(item.status)}
        />

        <div className="p-col-6 p-p-1 c-hover-cursor p-text-center">
          <InfoIcon
            style={{ height: "2rem", width: "2rem" }}
            onClick={props.onPreview}
          />
        </div>
      </div>
    </div>
  );
}

export default Card;
