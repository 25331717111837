import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setLoading } from "domain/actions/equipment/bid/index";
import {
  AddressConnect,
  OsConnect,
  InterfaceConnect,
} from "routes/pages/equipments/components";
import { showError } from "domain/actions/error.actions";

import { socket } from "domain/controllers/socket";
import { Button } from "primereact/button";

function DConnect(props) {
  const [os, setOs] = useState(null);
  const [interfaces, setInterfaces] = useState([]);
  const [connect, setConnect] = useState({
    os: null,
    interface: null,
    address: null,
  });
  const showError = props.showError;

  const getValueOs = (value) => {
    // console.log(value)
    setOs(value);
  };

  useEffect(() => {
    if (os) {
      let interfaces = os.interface.map((item) => ({ name: item, id: item }));
      setInterfaces(interfaces);
    }
  }, [os, props.item]);

  const onCheckConnect = () => {
    if (!props.selectedWorker) return showError('Не выбрано "Рабочее место"');
    if (!props.selectedWorker.isActive)
      return showError('"Рабочее место" не активно');
    if (!connect.interface) return showError('Не выбран "Интерфейс"');
    if (!connect.address) return showError("Не заполнен Адрес(порт, IP-порт)");
    // if (!!connect.address && !!connect.interface && !!props.selectedWorker) {

    // }

    socket.emit(
      "getCashboxHardware",
      props.selectedWorker.workerUuid,
      connect.address,
      connect.interface
    );

    props.setLoading(true);
    setTimeout(() => {
      props.setLoading(false);
    }, 6000);
  };

  return (
    <div className={props.className}>
      <span className="block-label-title p-pl-2">
        Данные соединения с кассой
      </span>
      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
        <InterfaceConnect
          className="p-col-12 p-md-6 p-lg-4 "
          item={props.item}
          interface={interfaces}
          setConnect={setConnect}
        />

        <AddressConnect
          className="p-col-12 p-md-6 p-lg-4 "
          item={props.item}
          setConnect={setConnect}
        />

        <OsConnect
          className="p-col-12 p-md-6 p-lg-4 "
          item={props.item}
          getValue={(value) => {
            // console.log(value)
            getValueOs(value);
          }}
          setConnect={setConnect}
        />

        <div className="p-col-12 p-md-6 p-lg-4  p-d-flex p-flex-wrap p-text-center">
          <Button
            label="Проверить подключение"
            onClick={() => onCheckConnect()}
            className="p-col-12"
          />
          {props.text.type !== null ? (
            <span
              className="p-col-12 p-p-1 p-d-block"
              style={{ color: props.text.type === "success" ? "green" : "red" }}
            >
              {props.text.message}
            </span>
          ) : (
            <span className="p-col-12 p-p-1 p-d-block"> ... </span>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  selectedWorker: state.organizations.selectedWorker,
});

export default connect(mapStateToProps, { setLoading, showError })(DConnect);
