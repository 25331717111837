import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getTariffs } from "domain/actions/requisites.actions";
import ListClient from "./cashboxClient";
import ListProvider from "./cashboxProvider";

function ItemComponent(props) {
  const { getTariffs, isLocal, loading, setLoading, className } = props;

  useEffect(() => isLocal && getTariffs(isLocal), [getTariffs, isLocal]);

  return (
    <div className="p-col-12">
      {isLocal.id === 1 ? (
        <ListClient
          className="p-col-12 c-position-relative"
          item={props.item}
          loading={loading}
          setLoading={setLoading}
        />
      ) : (
        <ListProvider className="p-col-12" item={props.item} />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLocal: state.bidEquipments.isLocal,
});
export default connect(mapStateToProps, { getTariffs })(ItemComponent);
