import React from "react";
import { connect } from "react-redux";
import { AddAcquiring, AcquiringEdit, Acquiring } from "../index";
import PSBParams from "../sbp/psb.params";
import SberParams from "../sbp/sber.params";
import { handleShowAcquiring } from "domain/actions/organizations/acquiring";

function AcquiringData(props) {

  const acquiring = props.select
    ? props.select.organizationAcquiring
    : props.item.organizationAcquiring;

  let renderItems = acquiring.map((i, index) => {
    let className = "p-col-12 p-p-3 p-mb-3 p-d-flex p-flex-wrap c-box-shadow";
    if (props.select) {
      return (
        <AcquiringEdit
          key={index}
          index={index}
          className={className}
          item={i}
        />
      );
    }
    
    const _acquiring = props.acquiring.filter((_item) => _item.id === +i.acquiringId);
    if(_acquiring.length <= 0) return <></>;

    return (
      <>
        {
          _acquiring[0].name === "PSB" ? <PSBParams key={index} index={index} className={className} item={i}/>
          : _acquiring[0].name === "SberQR" ? <SberParams key={index} index={index} className={className} item={i}/>
          : <Acquiring key={index} index={index} className={className} item={i} />
        }
      </>
    )
  });

  return (
    <div className={props.className} key={props.showWInAcquiring}>
      {acquiring.length ? (
        <div className="p-col-12 p-p-0">{renderItems}</div>
      ) : (
        <div className="p-col-12 p-text-center empty-list">Список пуст ...</div>
      )}

      <div className="p-col-12 p-d-flex p-jc-center">
        <AddAcquiring
          className="p-col-12 p-md-3"
          onShow={props.handleShowAcquiring}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  item: state.orgCreate.item,
  select: state.orgCreate.select,
  showWInAcquiring: state.orgCreate.acquiringShow,
  acquiring: state.requisites.acquiring
});
export default connect(mapStateToProps, { handleShowAcquiring })(AcquiringData);
