import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { setSortsBids } from "domain/actions/equipment/bid/filters";
import { Sorts } from "components/static";
import SortComponent from "components/static/sorts/components/Sort.component";

function SortsPanels({ setSortsBids, className }) {
  const [date, toggleDate] = useState(false);

  useEffect(() => {
    let params = {
      createdAt: date ? "ASC" : "DESC",
    };
    if (date === null) delete params.createdAt;

    setSortsBids(params);
  }, [date, setSortsBids]);

  return (
    <Sorts className={className}>
      <div className="p-d-flex">
        <SortComponent
          label="Дата"
          value={date}
          onChange={(value) => {
            toggleDate(value);
          }}
        />
      </div>
    </Sorts>
  );
}

export default connect(null, { setSortsBids })(SortsPanels);
