import React from "react";
import { EmailReceipt } from "../../../../components/fields";

function Shop(props) {
  const { disabled = false } = props;
  return (
    <div className={props.className}>
      <span className="block-label-title p-pb-4 title-silver p-col-12">
        Параметры магазина
      </span>

      <EmailReceipt className="p-col-12" disabled={disabled} />
    </div>
  );
}

export default Shop;
