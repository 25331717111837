import React, { useState, useEffect } from "react";
import ColumnTypeToString from "controllers/ColumnTypeToString";
import { Button } from "primereact/button";

import { Number, Column } from "components/dataView";
import { ExpansionIcon, ExpansionItem } from "components/static";
import { showSuccess } from "domain/actions/error.actions";

// import InfoIcon from "@material-ui/icons/Info";
import { ActionsColumn } from "components/static";
import { connect } from "react-redux";

function CardMobileComponent(props) {
  const [show, setShow] = useState(false);
  const [currentElementId, setCurrentElementId] = useState(null);
  const TypeToString = new ColumnTypeToString();
  const { item, onSetReceipt } = props;
  const onPreview = props.onPreview ? props.onPreview : null;
  const numColor = TypeToString.toStatusClass(item.status);

  useEffect(() => {
    if (currentElementId) onSetReceipt(currentElementId);
  }, [currentElementId, onSetReceipt]);

  let menu = () => [
    {
      label: "Просмотр платежа",
      icon: "pi pi-fw pi-info-circle",
      command: () => onPreview(),
    },
  ];

  return (
    <div className="p-col-12 p-mb-2 c-shadow-2">
      <div className="p-col-12  p-d-flex p-flex-wrap">
        <div className="p-col-8 p-sm-2 p-lg-1 p-p-1 p-d-flex p-as-end p-order-0">
          <ExpansionIcon
            active={show}
            setActive={setShow}
            className="p-d-lg-none"
          />

          <Number
            className={`p-as-end p-ml-1 ${numColor}`}
            value={item?.number}
          />
        </div>

        <Column
          className="p-col-8 p-sm-3 p-lg-2 p-ai-start p-ai-sm-center p-sm-2 p-p-1 p-as-end p-order-2 p-order-sm-1"
          title="Дата создания"
          value={TypeToString.toDate(item?.createdAt)}
        />

        <Column
          className="p-col-4 p-sm-3 p-lg-2 p-ai-end p-ai-sm-center p-sm-2 p-p-1 p-as-end p-order-3 p-order-sm-6"
          title="Сумма"
          value={TypeToString.toNull(item?.amount)}
        />

        <div className="p-col-4 p-sm-1 p-p-1 p-d-flex p-jc-end p-order-1 p-order-sm-6">
          <ActionsColumn
            menuItems={menu(item)}
            element={item}
            setElement={() => setCurrentElementId(item.id)}
          />
        </div>
      </div>
      <ExpansionItem className="p-col-12 p-p-0" active={show} withLine>
        <div className=" p-d-flex p-flex-wrap p-col-12">
          <div className="p-sm-2 p-d-none p-d-sm-block"></div>

          {/* <Column
            className="p-col-6 p-sm-3 p-ai-end p-ai-sm-center p-lg-2 p-p-1 p-as-end"
            title="Тип чека"
            value={TypeToString.toNull(item?.receiptType?.name)}
          /> */}
          <Column
            className="p-col-12 p-sm-6 p-ai-end p-ai-sm-center p-lg-2 p-p-1 p-as-end"
            title="Тип чека"
            value={item?.acqName}
          />

          <div className="p-sm-2 p-d-none p-d-sm-block"></div>
        </div>
      </ExpansionItem>
    </div>
  );
}

export default connect(null, { showSuccess })(CardMobileComponent);
