import React, { useState, useEffect } from "react";
import { TextField } from "components/forms/fields/text";
import { connect } from "react-redux";
import { getCodeForRefreshToken } from "domain/actions/settings";
import { showSuccess } from "domain/actions/error.actions";
import {
  getStaticToken,
  generationStaticToken,
} from "domain/actions/account.actions";

import CachedIcon from "@material-ui/icons/Cached";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { ConfirmGetToken, WarningGetToken } from "../../windows";

import copyToClipboard from "controllers/copyToClipboard";

function StaticToken(props) {
  const [show, setShow] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { getStaticToken, generationStaticToken, rights, disabled } = props;

  useEffect(() => getStaticToken(), [getStaticToken]);

  const allowGenericToken = rights?.includes("USERS_GENERATE_TOKEN");

  const handleGetCode = () => {
    generationStaticToken();
  };

  const handleGetStaticToken = () => {
    if (!props.value) handleGetCode();
    else setShowWarning(true);
  };

  const accept = () => handleGetCode();

  const handleCopy = () => {
    copyToClipboard(props.value);
    props.showSuccess("Токен скопирован");
  };

  return (
    <div className={props.className}>
      <div className="p-col-12 p-p-0 p-d-flex">
        <TextField
          label="Статический токен"
          value={props.value}
          className="label-color-green p-col-9 p-p-0"
          disabled={disabled}
        />

        <div className="p-col-3 p-d-flex p-jc-around p-ai-center">
          <FileCopyIcon
            style={{ width: "1.5rem", height: "1.5rem" }}
            onClick={handleCopy}
            tooltip="Скопировать в буфер обмена"
          />

          {allowGenericToken && (
            <CachedIcon
              style={{ width: "1.5rem", height: "1.5rem" }}
              onClick={handleGetStaticToken}
              tooltip="Обновить текущий токен"
            />
          )}
        </div>
      </div>

      <ConfirmGetToken
        show={show}
        close={setShow}
        className="p-col-12 p-md-8 p-xl-4 p-p-0"
      />
      <WarningGetToken
        visible={showWarning}
        setVisible={setShowWarning}
        accept={accept}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  value: state.account.staticToken,
  rights: state.users.rights,
});
export default connect(mapStateToProps, {
  getCodeForRefreshToken,
  showSuccess,
  getStaticToken,
  generationStaticToken,
})(StaticToken);
