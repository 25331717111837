import {
  SORT_BID_EQUIPMENTS,
  FILTER_BID_EQUIPMENTS,
  RANGE_BID_EQUIPMENTS,
  REFRESH_BID_EQUIPMENTS,
} from "domain/types/equipment";

export function setSortsBids(...params) {
  return async function thunk(dispatch, getState) {
    if (!Object.keys(params[0]).length) params = null;

    dispatch({
      type: SORT_BID_EQUIPMENTS,
      payload: params ? params[0] : params,
    });

    refreshThunk(dispatch, getState);
  };
}
export function setFiltersBids(params) {
  return async function thunk(dispatch, getState) {
    if (!Object.keys(params).length) params = null;

    dispatch({
      type: FILTER_BID_EQUIPMENTS,
      payload: params,
    });

    refreshThunk(dispatch, getState);
  };
}
export function setRangeBids(...data) {
  return async function thunk(dispatch, getState) {
    const filters = { ...getState().receiptsFilter.range };

    let params = {};

    if (data[1]) {
      params[data[0]] = {
        from: data[1][0],
        to: data[1][1],
      };
    }

    if (!Object.keys(params).length) params = null;

    for (let key in filters) {
      if (key === data[0] && params !== null) {
        filters[key] = params[key];
      }
    }

    Object.assign(params ? params : {}, filters);

    dispatch({
      type: RANGE_BID_EQUIPMENTS,
      payload: params,
    });

    refreshThunk(dispatch, getState);
  };
}

async function refreshThunk(dispatch, getState) {
  let refresh = getState().bidList.refresh;

  dispatch({
    type: REFRESH_BID_EQUIPMENTS,
    payload: !refresh,
  });

  return true;
}
