import React from "react";
import { Button } from "primereact/button";

function TopUp(props) {
  return (
    <div className={props.className}>
      <Button
        label="Пополнить"
        onClick={props.callback}
        className="c-btns--gradient"
      />
    </div>
  );
}

export default TopUp;
