import React from "react";
import { connect } from "react-redux";

import { postOperationCashbox } from "domain/actions/equipment";
import { Button } from "primereact/button";

function ConfirmButton(props) {
  const confirm = () => {
    props.postOperationCashbox(props.data);
  };

  return (
    <div className={props.className}>
      <Button
        label="Выполнить"
        className="с-button-success"
        onClick={confirm}
      />
    </div>
  );
}

export default connect(null, { postOperationCashbox })(ConfirmButton);
