import React from "react";
import { connect } from "react-redux";
import { handleShowAcquiring } from "domain/actions/organizations/acquiring";

import Window from "components/windows";
import ContentAdd from "./layouts/add";
import ContentEdit from "./layouts/edit";

function WindowAcquiring(props) {
  const close = props.handleShowAcquiring;
  let title = props.select
    ? "Информация по эквайринг"
    : "Добавление эквайринга";

  return (
    <Window
      className={props.className}
      header={title}
      visible={props.show}
      onHide={close}
      style={{ minWidth: "340px" }}
      draggable={false}
    >
      {props.select 
        ? <ContentEdit className="p-col-12 p-d-flex p-flex-wrap" />
        : <ContentAdd className="p-col-12 p-d-flex p-flex-wrap" />
      }
      
    </Window>
  );
}

const mapStateToProps = (state) => ({
  show: state.orgCreate.acquiringShow,
  select: state.orgAcquiring.select,
});
export default connect(mapStateToProps, { handleShowAcquiring })(
  WindowAcquiring
);
