import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import { handleShowBindCashbox, handleBindCashbox } from "domain/actions/organizations/acquiring";

import Window from "components/windows";
import { Select } from "components/forms/fields/select";
import { TextField } from "components/forms/fields";
import { Button } from "primereact/button";

function WindowBindCashbox(props) {
  const {organization, acquiring, handleBindCashbox} = props;
  const [cashbox, setCashbox] = useState(null);
  const [name, setName] = useState("");
  const [cashboxList, setCashboxList] = useState([]);
  const close = props.handleShowBindCashbox;
  let title = "Связь эквайринга с кассой";

  useEffect(() =>{
    const cashbox = organization ? organization.cashbox : [];
    cashbox.forEach((i) => {
      try {
        i.name = i.cashboxModel.model;
      } catch (err) {
        i.name = `Не определенно`;
      }
    });

    setCashboxList(cashbox);
  },[organization])

  const confirm = () => {
    const data = {
      name: name,
      defaultOrgAcqId: acquiring ? acquiring.id : null,
      defaultCashboxId: cashbox.id
    }
    handleBindCashbox(data)
  }

  return (
    <Window
      className={props.className}
      header={title}
      visible={props.show}
      onHide={close}
      style={{ minWidth: "340px" }}
      draggable={false}
    >

      <TextField
        className="p-col-12"
        label="Наименование"
        value={name}
        onChange={(e) => setName(e)}
      />
      <Select
        className="p-col-12"
        name="cashbox"
        value={cashbox}
        label="Выбор кассы"
        options={cashboxList}
        onChange={(e) => setCashbox(e)}
        showClear={true}
      />

      <Button label="Применить" className="с-button-success" onClick={confirm} />
    </Window>
  );
}

const mapStateToProps = (state) => ({
  show: state.orgCreate.bindCashboxShow,
  acquiring: state.orgAcquiring.select,
  organization: state.orgCreate.select
});
export default connect(mapStateToProps, { handleShowBindCashbox, handleBindCashbox })(
  WindowBindCashbox
);
