import React from "react";
import { EditData, Director } from "../../index";
import { Comment } from "../../../../../../components/fields";

function Legal(props) {
  const { isRights } = props;
  return (
    <div className={props.className}>
      <div className="p-col-12 p-md-6 p-pl-md-0">
        <div className="p-col-12 ">
          <EditData
            className="p-p-3 c-box-shadow p-d-flex p-flex-wrap"
            isLegalEntity={props.isLegalEntity}
            disabled={!isRights}
          />
        </div>
      </div>

      <div className="p-col-12 p-md-6 p-pr-md-0">
        <div className="p-col-12 p-pl-md-0">
          <Director
            className="p-p-3 c-box-shadow p-d-flex p-flex-wrap"
            disabled={!isRights}
          />
        </div>
      </div>

      <Comment className="p-col-12 p-p-3" disabled={!isRights} />
    </div>
  );
}

export default Legal;
