import React, { useState } from "react";
import Window from "../../../../../../../marking";
import { Button } from "primereact/button";

function CodeItem(props) {
  const [display, setDisplay] = useState(false);

  const onShow = (e) => {
    e.preventDefault();
    setDisplay(true);
  };
  const onHide = (e) => setDisplay(false);

  return (
    <div className={props.className}>
      <Window show={display} onHide={onHide} />
      <Button
        label="Код маркировки"
        onClick={onShow}
        className="c-btns--gradient"
      />
    </div>
  );
}

export default CodeItem;
