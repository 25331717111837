import React from "react";
import { TextField } from "components/forms/fields/text";
import IconsTypeCard from "./iconsTypeCard";

function CardInvoice(props) {
  return (
    <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
      <TextField
        label="Номер счета"
        value={props.invoice.id}
        disabled={true}
        className="p-col-12 p-md-5"
      />

      <TextField
        label="Сумма"
        value={props.invoice.sum}
        disabled={true}
        className="p-col-12 p-md-5"
      />

      <IconsTypeCard className="p-col-12 p-md-2 p-as-center" />
    </div>
  );
}

export default CardInvoice;
