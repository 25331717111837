import React from "react";

import { Button } from "primereact/button";
import { connect } from "react-redux";
import { addCashbox } from "domain/actions/equipment/bid";

function BtnAddCashbox(props) {
  const addingItem = () => props.addCashbox();

  let isRender = props.isLocal
    ? props.isLocal.id === 1
      ? true
      : false
    : false;

  return (
    <div className={props.className}>
      {isRender && (
        <Button
          className="p-col-12 p-p-3 p-text-left c-button c-button--icon"
          label="Добавить новую кассу"
          icon="pi pi-plus"
          onClick={addingItem}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLocal: state.bidEquipments.isLocal,
});
export default connect(mapStateToProps, { addCashbox })(BtnAddCashbox);
