import React from "react";

import { Column } from "components/dataView";
import { connect } from "react-redux";

function Body(props) {
  const getDefaultSno = (id) => {
    if (id) {
      return props.sno.filter((sno) => +sno.id === +id)[0].name;
    } else {
      return "Не указано!";
    }
  };

  const renderedListItems = props.list.map((i, index) => {
    return (
      <div
        key={index}
        className="p-d-flex p-col-12 p-pl-0 p-pr-0 p-pt-1 p-pb-1 p-flex-wrap"
      >
        <Column
          className="p-col-4 p-pl-0 p-md-3 p-as-center p-ai-start"
          classTitle="p-d-block p-d-md-none"
          classBody="c-color-green p-text-left"
          title="ОФД"
          value={i.ofd ? i.ofd.name : "Не указано!"}
        />

        <Column
          className="p-col-3 p-md-1 p-as-center p-ai-start"
          classTitle="p-d-block p-d-md-none"
          classBody="p-text-left"
          title="Кол-во"
          value={`${props.amount} `}
        />
        <Column
          className="p-col-5 p-md-4 p-as-center p-ai-start p-text-start"
          classTitle="p-d-block p-d-md-none"
          classBody="p-text-left"
          title="СНО(по умолчанию)"
          value={getDefaultSno(i.defaultSnoId)}
        />

        <Column
          className="p-col-12 p-md-4 p-as-center p-ai-start p-text-start"
          classTitle="p-d-block p-d-md-none"
          classBody="p-text-left"
          title="Комментарий"
          value={i.comment === "" ? i.comment : "-"}
        />
      </div>
    );
  });

  return <div className={props.className}>{renderedListItems}</div>;
}

const mapStateToProps = (state) => ({
  sno: state.requisites.sno,
});
export default connect(mapStateToProps)(Body);
