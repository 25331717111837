import React from "react";
import { MobileFieldControl } from "components/forms/fields";

function MobileField(props) {
  return (
    <MobileFieldControl
      className="p-p-2"
      name="phone"
      control={props.formHook.control}
      errors={props.formHook.errors}
      label="Номер телефона:"
      required={true}
    />
  );
}

export default MobileField;
