import {
  ADD_ITEMS_CREATERECEIPTS,
  SET_ITEMS_CREATERECEIPTS,
  UPDATE_ITEMS_CREATERECEIPTS,
  DELETE_ITEMS_CREATERECEIPTS,
  SHOW_WINDOW_CREATERECEIPTS,
  CLEARE_ITEMS_CREATERECEIPTS,
  UPDATE_SCHEMA_CREATERECEIPTS,
  UPDATE_TYPE_CREATERECEIPTS,
} from "../../../actionTypes";
import {
  SET_DEFAULT_TAXATION_EDIT_CREATERECEIPTS,
  RETURN_DEFAULT_TAXATION_EDIT_CREATERECEIPTS,
} from "../../../types/createReceipt";

const initialState = {
  items: [],
  showWindow: false,
  type: "receipt",
  id: 1,
  schemaItem: {
    name: "",
    type: { id: 1, eng: "position", name: "Товар" },
    paymentObject: { id: 1, eng: "commodity", name: "Товар" },
    paymentMethod: { id: 4, eng: "fullPayment", name: "Полный расчет" },
    quantity: null,
    price: null,
    tax: { id: 6, eng: "none", name: "Без НДС" },
    amount: 0,
    measurementUnit: { id: 5, name: "штука", short: "шт" },
    infoDiscountAmount: null,
    department: 1,
  },
};

export default function CreateReceiptReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ITEMS_CREATERECEIPTS:
      return {
        ...state,
        items: [...state.items, action.payload],
        id: state.id + 1,
      };
    case UPDATE_TYPE_CREATERECEIPTS:
      return {
        ...state,
        type: action.payload,
      };
    case SET_ITEMS_CREATERECEIPTS:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    // case SELECTED_ITEMS_CREATERECEIPTS:
    //   return {
    //     ...state,
    //     editItem: action.payload,
    //   };
    case UPDATE_ITEMS_CREATERECEIPTS:
      return {
        ...state,
        items: action.payload,
      };
    case UPDATE_SCHEMA_CREATERECEIPTS:
      return {
        ...state,
        schemaItem: action.payload,
      };
    case DELETE_ITEMS_CREATERECEIPTS:
      return {
        ...state,
        items: action.payload,
      };
    case SHOW_WINDOW_CREATERECEIPTS:
      return {
        ...state,
        showWindow: action.payload,
      };
    case CLEARE_ITEMS_CREATERECEIPTS:
      return {
        ...state,
        items: [],
      };
    case SET_DEFAULT_TAXATION_EDIT_CREATERECEIPTS:
      let schemaItem = state.schemaItem;
      schemaItem.tax = { id: 5, eng: "vat20", name: "20%" };
      return {
        ...state,
        schemaItem: schemaItem,
      };
    case RETURN_DEFAULT_TAXATION_EDIT_CREATERECEIPTS:
      let defSchema = state.schemaItem;
      defSchema.tax = { id: 6, eng: "none", name: "Без НДС" };
      return {
        ...state,
        schemaItem: defSchema,
      };
    default:
      return state;
  }
}
