import React from "react";
import { TextField, CheckboxField } from "components/forms/fields";

import { Button } from "primereact/button";

function Content(props) {
  const setValue = () => console.log("e");

  return (
    <div className="p-col-12 p-d-flex p-flex-wrap">
      <TextField
        label="Сумма пополнения"
        value={null}
        className="p-col-12 p-pr-0 p-pl-0 label-color-green"
      />
      <TextField
        label="Адрес получателя"
        value="asdas"
        className="p-col-12 p-md-6 p-pr-0 p-pl-0"
      />

      <CheckboxField
        label="Распечатать чек"
        className="p-col-12 p-md-6 p-d-flex label-color-green p-jc-center p-ai-center label-printOnPaper"
        checked={false}
      />

      <Button
        label="Выставить счет"
        onClick={setValue}
        className="c-btns--gradient"
      />
    </div>
  );
}

export default Content;
