import React from "react";
import SortsPanels from "./sorts";
import Filters from "./filters";

function CashboxSettingsPanel(props) {
  return (
    <div className={props.className}>
      <SortsPanels className="p-flex-wrap p-col-12 p-d-flex p-p-md-3" />
      <Filters className="p-col-12 p-p-0 p-p-md-2" />
    </div>
  );
}

export default CashboxSettingsPanel;
