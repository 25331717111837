import React from "react";
import { connect } from "react-redux";
import { EmailField } from "components/forms/fields/email";
import { setDocEmail } from "domain/actions/settings";

function AccountingEmail(props) {
  return (
    <div className={props.className}>
      <EmailField
        label="Email - Бухгалтерии"
        value={props.email}
        onChange={props.setDocEmail}
        isValid={true}
        className="label-color-green"
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  email: state.settings.emailDocs,
  isValid: true,
});
export default connect(mapStateToProps, { setDocEmail })(AccountingEmail);
