import {
  SET_RECEIPTINFO_CREATERECEIPTS,
  SET_ORGANIZATION_CREATERECEIPTS,
  SET_RECEIPTINFO_EXTERNAL_ID,
  SET_TYPE_CREATERECEIPTS,
  SET_TAXATIONTYPE_CREATERECEIPTS,
  SET_CASHBOX_CREATERECEIPTS,
  SET_CLIENTINFO_CREATERECEIPTS,
  SET_ELECTRONICALLY_CREATERECEIPTS,
  SET_OPERATORINFO_CREATERECEIPTS,
  SET_AGENTINFO_CREATERECEIPTS,
  SET_SUPPLIERINFO_CREATERECEIPTS,
  SET_CORRECTINFO_CREATERECEIPTS,
  SET_PLACE_CREATERECEIPTS,
  SET_PAYMENTS_CREATERECEIPTS,
  DELETE_PAYMENTS_CREATERECEIPTS,
  SET_TAXES_CREATERECEIPTS,
  DELETE_TAXES_CREATERECEIPTS,
  CLEARE_RECEIPT_CREATERECEIPTS,
  CLEAR_RECEIPT_PAYMENT,
  CREATE_RECEIPT_LOADING,
} from "../actionTypes";
import {
  SET_PAYSYSTEM_CREATERECEIPTS,
  SET_ISONLINEPAY_CREATERECEIPTS,
  ACQUIRING_PAYMENTS_CREATERECEIPTS,
  CREATE_RECEIPT_SHOW_BILLING,
  CREATE_RECEIPT_SHOW_BILLING_ERROR,
} from "../types/createReceipt";

const initialState = {
  org: null,
  type: { id: 1, eng: "sell", name: "Чек прихода" },
  taxationType: null,
  externId: null,
  cashbox: null,
  clientInfo: { name: "", emailOrPhone: "", vatin: "" },
  operator: null,
  agentInfo: null,
  supplierInfo: null,
  // paymentsPlace: "Рабочий стол",
  items: [],
  electronically: false,
  payments: [{ type: "cash", sum: null }],
  correctionType: "",
  correctionBaseDate: "",
  correctionBaseNumber: "",
  taxes: [],
  isOnlinePayment: false,
  organizationAcquiringId: 1,
  onlinePayment: {
    organizationAcquiringId: 1,
    paymentCallback: window.location.origin,
    amount: {
      value: "0.00",
      currency: "RUB",
    },
    paymentMethodData: "bank_card",
    description: "",
  },
  windows: {
    billing: false,
  },
  error: "",
  isLoading: false,
};

export default function CreateReceiptReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RECEIPTINFO_CREATERECEIPTS:
      return {
        ...state,
        org: action.payload.org,
        type: action.payload.type,
        clientInfo: action.payload.clientInfo,
      };
    case SET_RECEIPTINFO_EXTERNAL_ID:
      return {
        ...state,
        externId: action.payload,
      };
    case SET_ORGANIZATION_CREATERECEIPTS:
      return {
        ...state,
        org: action.payload,
      };
    case SET_TYPE_CREATERECEIPTS:
      return {
        ...state,
        type: action.payload,
      };
    case SET_TAXATIONTYPE_CREATERECEIPTS:
      return {
        ...state,
        taxationType: action.payload,
      };
    case SET_CASHBOX_CREATERECEIPTS:
      return {
        ...state,
        cashbox: action.payload,
      };
    case SET_CLIENTINFO_CREATERECEIPTS:
      return {
        ...state,
        clientInfo: action.payload,
      };
    case SET_PLACE_CREATERECEIPTS:
      return {
        ...state,
        paymentsPlace: action.payload,
      };
    case SET_ELECTRONICALLY_CREATERECEIPTS:
      return {
        ...state,
        electronically: action.payload,
      };
    case SET_OPERATORINFO_CREATERECEIPTS:
      return {
        ...state,
        operator: action.payload,
      };
    case SET_AGENTINFO_CREATERECEIPTS:
      return {
        ...state,
        agentInfo: action.payload,
      };
    case SET_SUPPLIERINFO_CREATERECEIPTS:
      return {
        ...state,
        supplierInfo: action.payload,
      };
    case SET_CORRECTINFO_CREATERECEIPTS:
      return {
        ...state,
        correctionType: action.payload.correctionType,
        correctionBaseDate: action.payload.correctionBaseDate,
        correctionBaseNumber: action.payload.correctionBaseNumber,
      };

    case SET_PAYMENTS_CREATERECEIPTS:
      return {
        ...state,
        payments: [...state.payments, action.payload],
      };
    case DELETE_PAYMENTS_CREATERECEIPTS:
      return {
        ...state,
        payments: action.payload,
      };
    case SET_TAXES_CREATERECEIPTS:
      return {
        ...state,
        taxes: [...state.taxes, action.payload],
      };
    case DELETE_TAXES_CREATERECEIPTS:
      return {
        ...state,
        taxes: action.payload,
      };
    case SET_PAYSYSTEM_CREATERECEIPTS:
      return {
        ...state,
        organizationAcquiringId: action.payload,
      };
    case SET_ISONLINEPAY_CREATERECEIPTS:
      return {
        ...state,
        isOnlinePayment: action.payload,
      };

    case ACQUIRING_PAYMENTS_CREATERECEIPTS:
      const payments = {
        type: "electronically",
        sum: state.payments.reduce((i, c) => i + c.sum, 0),
      };

      return {
        ...state,
        payments: [payments],
      };

    case CREATE_RECEIPT_SHOW_BILLING:
      return {
        ...state,
        windows: {
          billing: action.payload,
        },
      };
    case CREATE_RECEIPT_SHOW_BILLING_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case CREATE_RECEIPT_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case CLEARE_RECEIPT_CREATERECEIPTS:
      return {
        ...state,
        type: { id: 1, eng: "sell", name: "Чек прихода" },
        taxationType: {
          id: 1,
          eng: "osn",
          name: "Общая система налогообложения",
        },
        clientInfo: { name: "", emailOrPhone: "", vatin: "" },
        agentInfo: null,
        supplierInfo: null,
        items: [],
        electronically: false,
        payments: [{ type: "cash", sum: null }],
        taxes: [{ type: "vat20", sum: null }],
        correctionType: "",
        correctionBaseDate: "",
        correctionBaseNumber: "",
        isOnlinePayment: false,
        organizationAcquiringId: 1,
      };
    case CLEAR_RECEIPT_PAYMENT:
      return {
        ...state,
        payments: [{ type: "bank_card", sum: null }],
        isOnlinePayment: true,
      };
    default:
      return state;
  }
}
