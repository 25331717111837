import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { EditBtn } from "components/btns/iconBtn";
import { TextField, Select } from "components/forms/fields";

export function AcquiringLocalEdit(props) {
  const {listTypeSystem, item, className, handleSelected} = props;
  const [ type, setType ] = useState(null)
  const [ name, setName ] = useState("")
  
  useEffect( () => {
    const filter = listTypeSystem.filter( i => i.name.toUpperCase() === item.localAcquiringType.toUpperCase() )
    if(filter.length) setType(filter[0])
    setName(item.title)
  }, [item, listTypeSystem])

  return (
    <div className={className}>
      <Select
        className="p-col-12 p-md-4 p-xl-3"
        options={listTypeSystem}
        label="Тип платежной системы"
        value={type}
        disabled={true}
      />

      <TextField
        className={className}
        label="Наименование"
        value={name}
        disabled={true}
      />

      <div className="p-col-4 p-md-1 p-d-flex p-jc-center p-ai-end">
        <EditBtn onClick={handleSelected} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  listTypeSystem: state.requisites.acquiring,
});

export default connect(mapStateToProps)(AcquiringLocalEdit);
