import React from "react";
import { connect } from "react-redux";
import { handleShowBindCashbox } from "domain/actions/organizations/acquiring";
import { Button } from "primereact/button";

function BindCashbox(props) {
  const handlerShow = (event) => {
    event.preventDefault();
    props.handleShowBindCashbox(true);
  };

  return (
    <div className="p-col-12 p-mt-2 p-mb-2">
      <Button
        className="с-button-success"
        label="Сформировать подключение"
        onClick={handlerShow}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  select: state.orgAcquiring.select,
});

export default connect(mapStateToProps, { handleShowBindCashbox })(BindCashbox);
