import React from "react";

function ConnectingToStoreCms({ className }) {
  return (
    <div className={className}>
      <div>
        <strong>Подключение к CMS магазина</strong>
      </div>
      <p></p>
    </div>
  );
}

export default ConnectingToStoreCms;
