import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setProp } from "domain/actions/equipment/bid";
import { Select } from "components/forms/fields";

function Ofd(props) {
  const [value, setValue] = useState(null);
  const [updateKey, forceUpdate] = useState(1);
  const getValue = props.getValue;
  const setProp = props.setProp;

  useEffect(() => {
    if (props.item.ofdId) getValue(props.item.ofdId);
  }, [getValue, props.item.ofdId]);

  useEffect(() => {
    const item = props.ofd.filter((i) => +i.id === +props.item.ofdId);

    setValue(item.length ? item[0] : props.ofd[0]);
    getValue(item.length ? item[0] : props.ofd[0]);
  }, [setValue, props.ofd, props.item.ofdId, getValue]);

  useEffect(() => {
    if (!props.item.ofdId && !!value)
      setProp("ofdId", +value.id, props.item.id);
  }, [props.item, setProp, value]);

  const isActive = props.selected ? props.selected.status !== 11 : false;

  return (
    <Select
      key={updateKey}
      label="Тип ОФД"
      options={props.ofd}
      className={props.className}
      value={value}
      onChange={(e) => {
        props.setProp("ofdId", +e.id, props.item.id);
        forceUpdate(updateKey + 1);
      }}
      disabled={isActive}
      required
    />
  );
}

const mapStateToProps = (state) => ({
  ofd: state.requisites.ofd,
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { setProp })(Ofd);
