import React from "react";
import BidSettingsPanel from "./bid";
import CashboxSettingsPanel from "./cashbox";

function SettingsPanel(props) {
  return (
    <div className={props.className}>
      {props.tabIndex === 1 && <BidSettingsPanel className="p-col-12 p-p-0" />}
      {props.tabIndex === 0 && (
        <CashboxSettingsPanel className="p-col-12 p-p-0" />
      )}
    </div>
  );
}

export default SettingsPanel;
