import { isE164 } from "utils/phone.util";
import { isEmail } from "utils/email.util";
import { isINN } from "utils/rus.tax.codes.util";

export default class Validate {
  #errorText = "";

  getErrorText() {
    return this.#errorText;
  }

  isValid(receipt) {
    if (!receipt.type) {
      this.#errorText = "Не выбран 'Тип чека' ";
      return false;
    }

    if (receipt.type?.id > 4) {
      if (!this.isValidCorrect(receipt)) return false;
    }

    if (!this.isValidOrg(receipt)) return false;
    if (!this.isValidOnPrint(receipt)) return false;
    if (!this.isValidItems(receipt)) return false;
    if (!this.isValidPayments(receipt)) return false;

    return true;
  }

  isValidPayments(receipt) {
    let paymentsError = false;

    if (receipt.isOnlinePayment) {
      let valid = true;
      receipt.payments.forEach((i) => {
        if (i.type !== "electronically") {
          this.#errorText =
            "При оплате через эквайринг, способ расчета только 'Безналичными'";
          paymentsError = true;
          valid = false;
        }
      });

      if (!valid) return !paymentsError;
    }

    let amount = receipt.payments.reduce((i, c) => i + c.sum, 0);
    if (amount >= 100000000) {
      this.#errorText = "Максимальная сумма чека 99 999 999.99";
      paymentsError = true;
      return !paymentsError;
    }

    if (receipt.isOnlinePayment) {
      if (receipt.clientInfo.emailOrPhone === "") {
        this.#errorText = "Невозможна оплата без указания email";
        paymentsError = true;
        return !paymentsError;
      }
      if (!isEmail(receipt.clientInfo.emailOrPhone)) {
        this.#errorText =
          "Невозможна оплата без указания email. Email указан не верно.";
        paymentsError = true;
        return !paymentsError;
      }
    }

    receipt.payments.forEach((i) => {
      if (!i.sum && receipt.type?.id <= 4) {
        if (i.sum >= 100000000) {
          this.#errorText = "Максимальная сумма чека 99 999 999.99";
          paymentsError = true;
        } else {
          this.#errorText = "Оплата не должна быть меньше или равна 0";
          paymentsError = true;
        }
      }
    });
    return !paymentsError;
  }

  isValidCorrect(receipt) {
    if (!this.isValidCorrectInfo(receipt)) return false;

    if (!this.isValidTaxesCorrect(receipt)) return false;

    if (!this.isValidSumCorrect(receipt)) return false;

    return true;
  }

  isValidOnPrint(receipt) {
    if (receipt.electronically) {
      if (!this.isValidClient(receipt)) return false;
    }

    if (!this.isValidClientEmail(receipt)) return false;

    if (!this.isValidClientVatin(receipt)) return false;

    return true;
  }

  isValidCorrectInfo(receipt) {
    if (
      !receipt.correctionBaseDate ||
      !receipt.correctionType ||
      !receipt.correctionBaseNumber
    ) {
      this.#errorText = "'Основание коррекции' Не все поля заполнены";
      return false;
    }
    return true;
  }

  isValidTaxesCorrect(receipt) {
    let taxesError = false;

    if (receipt.taxes.length) {
      let paymentSum = receipt.payments.reduce((c, i) => c + i.sum, 0);

      receipt.taxes.forEach((i) => {
        if (i.sum > paymentSum) {
          this.#errorText =
            "Сумма налога на чек не должна превышать общую сумму к оплате";
          taxesError = true;
        }
      });
    }

    return !taxesError;
  }

  isValidSumCorrect(receipt) {
    let sum = receipt.payments.reduce((c, i) => c + Number(i.sum), 0);

    if (!sum) {
      this.#errorText = "Сумма к оплате не должна равняться нулю";
      return false;
    }

    return true;
  }

  isValidOrg(receipt) {
    if (!receipt.org) {
      this.#errorText = "Не выбрана организация";
      return false;
    }
    return true;
  }

  isValidItems(receipt) {
    if (!receipt.items) {
      this.#errorText = "Список товаров пуст";
      return false;
    }
    if (!receipt.items.length && receipt.type?.id <= 4) {
      this.#errorText = "Список товаров пуст";
      return false;
    }

    if (!this.isValidItem(receipt.items)) return false;

    return true;
  }

  isValidItem(items) {
    if (!this.isValidItemName(items)) return false;

    if (!this.isValidItemQuantity(items)) return false;

    if (!this.isValidItemPrice(items)) return false;

    if (!this.isValidItemMarking(items)) return false;

    if (!this.isValidItemAmount(items)) return false;

    if (!this.isValidItemSupplier(items)) return false;

    if (!this.isValidItemAgents(items)) return false;

    return true;
  }

  isValidItemName(items) {
    let result = true;
    items.forEach((i) => {
      if (!Object.keys(i).length) {
        this.#errorText = "Товар сформирован не верно";
        result = false;
      }
      if (!i.name?.length) {
        this.#errorText = "Не заполнено наименование товара";
        result = false;
      }
    });

    return result;
  }

  isValidItemQuantity(items) {
    let result = true;
    items.forEach((data) => {
      if (data.quantity <= 0 || data.quantity === null) {
        this.#errorText = "Кол-во товара не введено";
        result = false;
      }
    });

    return result;
  }

  isValidItemPrice(items) {
    let result = true;
    items.forEach((data) => {
      if (data.price <= 0 || data.price === null) {
        this.#errorText = "Стоимость товара не введено";
        result = false;
      }
    });

    return result;
  }

  isValidItemAmount(items) {
    let result = true;
    items.forEach((data) => {
      if (data.amount >= 100000000) {
        this.#errorText = "Максимальная сумма товара 99 999 999.99";
        result = false;
      }
    });

    return result;
  }

  isValidItemMarking(items) {
    let result = true;
    items.forEach((data) => {
      if (!!data.nomenclatureCode && !!data.nomenclatureCode.type) {
        if (data.quantity > 1) {
          this.#errorText =
            "Кол-во промаркированного товара не должно превышать 1 единицы";
          result = false;
        }
      }
    });

    return result;
  }

  isValidItemSupplier(items) {
    let result = true;
    items.forEach((item) => {
      const supplier = item.supplierInfo;

      if (supplier && result && supplier.name === "") {
        this.#errorText = `Не указано наименование поставщика в товаре ${item.name}`;
        result = false;
      }

      if (supplier && result && !isINN(String(supplier.vatin))) {
        this.#errorText = `Не верно указан ИНН поставщика в товаре ${item.name}`;
        result = false;
      }
    });

    return result;
  }

  isValidItemAgents(items) {
    let result = true;
    items.forEach((item) => {
      const agentInfo = item.agentInfo;
      const supplier = item.supplierInfo;

      if (supplier && !agentInfo) {
        this.#errorText = `Не указан "Тип агента" в товаре ${item.name}`;
        result = false;
      }
    });

    return result;
  }

  isValidClient(receipt) {
    if (
      !receipt.clientInfo ||
      receipt.clientInfo.emailOrPhone === "" ||
      !receipt.clientInfo.emailOrPhone
    ) {
      this.#errorText = "Не введен адрес получателя";
      return false;
    }

    return true;
  }

  isValidClientEmail(receipt) {
    if (receipt.clientInfo.emailOrPhone !== "") {
      let isContactValid =
        isE164(receipt.clientInfo.emailOrPhone) ||
        isEmail(receipt.clientInfo.emailOrPhone);
      if (!isContactValid) {
        this.#errorText = "E-mail или номер телефона введен не корректно";
        return false;
      }
    }

    return true;
  }

  isValidClientVatin(receipt) {
    if (receipt.clientInfo.vatin !== "") {
      console.log(
        "vatin",
        receipt.clientInfo.vatin,
        isINN(String(receipt.clientInfo.vatin))
      );
      if (!isINN(String(receipt.clientInfo.vatin))) {
        this.#errorText = "ИНН получателя введен не корректно";
        return false;
      }
    }

    return true;
  }
}

export function formatReceiptEng(receipt) {
  receipt.type = receipt.type.eng;

  if (receipt.taxationType && receipt.taxationType !== "")
    receipt.taxationType = receipt.taxationType.eng;

  if (receipt.correctionType && receipt.correctionType !== "")
    receipt.correctionType = receipt.correctionType.eng;

  if (receipt.agentInfo && receipt.agentInfo !== "")
    receipt.agentInfo = formatAgents({ ...receipt.agentInfo });

  return receipt;
}

export function formatAgents(agents) {
  agents.agents = agents.agents.map((i) => i.eng);
  return agents;
}
