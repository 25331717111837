import React from "react";

function ReceiptCount(props) {
  return (
    <div className={props.className}>
      <div className="p-col-12 p-p-3 c-box-shadow">
        <div className="analytics-title">Кол-во чеков</div>
      </div>
    </div>
  );
}

export default ReceiptCount;
