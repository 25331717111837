import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { setCorrect } from "../../../../domain/actions/createReceipt.actions";

import Correction from "../components/Correction.item";

import Window from "components/windows";
import { Button } from "primereact/button";

import styles from "../styles.module.css";

function CorrectWindow({ show, close, setCorrect, ...states }) {
  const { register, control, errors, handleSubmit } = useForm();

  const onConfirm = (data) => {
    setCorrect(data);

    close();
  };

  const footer = (
    <div className="p-col-12 p-d-flex p-jc-end p-mt-3">
      <div className={`p-col-12 ${styles.buttons}`}>
        <Button label="Добавить" className="p-button-success" type="confirm" />
      </div>
    </div>
  );

  return (
    <Window
      header="Основание коррекции"
      visible={show}
      className={`${styles.dialog} p-col-12 p-md-8 p-xl-4 p-p-0 dialog-header`}
      onHide={close}
      draggable={false}
    >
      <form
        onSubmit={handleSubmit(onConfirm)}
        className="p-d-flex p-flex-column p-jc-between"
      >
        <Correction
          register={register}
          errors={errors}
          control={control}
          defaultValue={states}
          className="p-col-12 off-shadow"
          requisites={states.requisites}
        />

        {footer}
      </form>
    </Window>
  );
}

const mapStateToProps = (state) => ({
  correctionType: state.createReceipt.correctionType,
  correctionBaseDate: state.createReceipt.correctionBaseDate,
  correctionBaseNumber: state.createReceipt.correctionBaseNumber,
  requisites: state.requisites,
});

export default connect(mapStateToProps, { setCorrect })(CorrectWindow);
