import React, { useState, useEffect } from "react";
import { ExpansionIcon, ExpansionItem } from "../expansion";

function CardOpenScreen(props) {
  const [show, setShow] = useState(false);

  useEffect(() => setShow(!!props.defaultShow), [props.defaultShow]);

  const header = (
    <div className="p-col-12 p-pt-2 p-pb-2 p-d-flex p-flex-wrap p-jc-between ">
      {props.label}
      <ExpansionIcon active={show} setActive={setShow} />
    </div>
  );

  return (
    <div className={`${props.className} c-shadow-2`}>
      {header}

      <ExpansionItem active={show} className={props.classNameChildren} withLine>
        {props.children}
      </ExpansionItem>
    </div>
  );
}

export default CardOpenScreen;
