import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setRangePayments } from "domain/actions/payments/filters";
import DateFilter from "components/static/dateFilter";
import Day from "components/static/dateFilter/components/day";
import Month from "components/static/dateFilter/components/month";
import Week from "components/static/dateFilter/components/week";

function DatePanel({ setRangePayments, className }) {
  const [dActive, toggleDActive] = useState(false);
  const [mActive, toggleMActive] = useState(false);
  const [wActive, toggleWActive] = useState(false);
  const [date, setDate] = useState(null);
  const [dateEvent, toggleDateEvent] = useState(false);

  useEffect(() => {
    if (dateEvent) {
      toggleDActive(false);
      toggleMActive(false);
      toggleWActive(false);
    }
  }, [dateEvent]);

  const funcDActive = (active, start, end) => {
    toggleDActive(active);
    toggleMActive(false);
    toggleWActive(false);
    toggleDateEvent(false);
    setDate([start, end]);
  };

  const funcMActive = (active, start, end) => {
    toggleMActive(active);
    toggleDActive(false);
    toggleWActive(false);
    toggleDateEvent(false);
    setDate([start, end]);
  };

  const funcWActive = (active, start, end) => {
    toggleWActive(active);
    toggleMActive(false);
    toggleDActive(false);
    toggleDateEvent(false);
    setDate([start, end]);
  };

  useEffect(() => {
    if (!date || date[0] === null) return setRangePayments("createdAt", null);
    if (date[1] !== null) return setRangePayments("createdAt", date);
  }, [date, setRangePayments]);

  const onChangeDate = () => {
    if (!date || date[0] === null) return setRangePayments("createdAt", null);
    setRangePayments("createdAt", date);
  };

  return (
    <DateFilter
      className={className}
      date={date}
      setDate={setDate}
      isDateEvent={toggleDateEvent}
      refresh={onChangeDate}
    >
      <div className="p-d-flex">
        <Day active={dActive} onChange={funcDActive} />
        <Week active={wActive} onChange={funcWActive} />
        <Month active={mActive} onChange={funcMActive} />
      </div>
    </DateFilter>
  );
}

export default connect(null, { setRangePayments })(DatePanel);
