import {
  SORT_USERS,
  FILTER_USERS,
  RANGE_USERS,
  SET_LIKE_USERS,
} from "domain/types/users";

const initialState = {
  sort: null,
  range: null,
  filter: null,
  like: "",
};

export default function filters(state = initialState, action) {
  switch (action.type) {
    case SORT_USERS:
      return {
        ...state,
        sort: action.payload,
      };
    case RANGE_USERS:
      return {
        ...state,
        range: action.payload,
      };
    case FILTER_USERS:
      return {
        ...state,
        filter: action.payload,
      };
    case SET_LIKE_USERS:
      return {
        ...state,
        like: action.payload,
      };
    default:
      return state;
  }
}
