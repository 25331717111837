import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setProp } from "domain/actions/equipment/bid";
import { TextField } from "components/forms/fields";

function AddressConnect(props) {
  const [value, setValue] = useState(props.item.connect.address);
  const [updateKey, forceUpdate] = useState(1);
  const isActive = props.selected ? props.selected.status !== 11 : false;

  return (
    <TextField
      key={updateKey}
      label="Адрес (порт, IP-порт...)"
      className={props.className}
      value={value}
      onChange={(e) => {
        if (e.length < 256) setValue(e);
      }}
      onBlur={(e) => {
        let obj = {
          os: props.item.connect.os,
          interface: props.item.connect.interface,
          address: e.target.value,
        };
        props.setProp("connect", obj, props.item.id);
        props.setConnect(obj);
        forceUpdate(updateKey + 1);
      }}
      disabled={isActive}
      reqStar
    />
  );
}

const mapStateToProps = (state) => ({
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { setProp })(AddressConnect);
