import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setProp, setPropEdit } from "domain/actions/organizations/created";
import { MobileField } from "components/forms/fields";

function Phone(props) {
  const [value, setValue] = useState("");
  const [updateKey, forceUpdate] = useState(1);
  const { disabled = false } = props;

  const setProp = !props.select ? props.setProp : props.setPropEdit;

  useEffect(() => {
    if (props.select) return setValue(props.select.phone);
    setValue(props.value);
  }, [props.value, props.select]);

  return (
    <MobileField
      className={props.className}
      key={updateKey}
      label="Телефон"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      rules={props.required}
      onBlur={(e) => {
        setProp("phone", e.target.value.replace(/[()-]/gm, ""));
        forceUpdate(updateKey + 1);
      }}
      disabled={disabled}
    />
  );
}

const mapStateToProps = (state) => ({
  value: state.orgCreate.item.phone,
  select: state.orgCreate.select,
  required: state.orgCreate.required,
});
export default connect(mapStateToProps, { setProp, setPropEdit })(Phone);
