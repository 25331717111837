import React from "react";
import { CardComponent } from "components/static";
import { connect } from "react-redux";

function Cashier(props) {
  const { item } = props;
  const user = item?.user ? item.user : false;
  const operator = item?.taskJson?.parameters?.operator;

  const label = (
    <span className="block-label-title title-bold p-pl-2 p-as-center">
      Кассир
    </span>
  );

  const content = (
    <span className="p-col-12 p-d-flex p-pl-3 p-pb-3 p-pt-1 p-text-uppercase">
      {operator?.name?.length
        ? operator.name
        : !!user
        ? `${user.lastName} ${user.firstName} ${user.middleName} `
        : "Отсутствует"}
    </span>
  );
  return (
    <CardComponent label={label} className={props.className}>
      {content}
    </CardComponent>
  );
}

const mapStateToProps = (state) => ({
  item: state.receipts.select,
});
export default connect(mapStateToProps)(Cashier);
