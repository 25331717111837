export default function toStatusReceipt(status) {
  if (!status && status < 0) return this.toNull();

  switch (status) {
    case 0:
      return <span className="text-color-success">Распечатан</span>;
    case 1:
      return <span className="text-color-danger">Отказано</span>;
    case 11:
      return <span className="text-color-warning">В ожидании</span>;
    case 10:
      return <span className="text-color-warning">Отправлено на печать</span>;
    case 100:
      return (
        <span className="text-color-danger">Произошла ошибка на сервере</span>
      );
    case 101:
      return <span className="text-color-danger">Нет ответа от Worker</span>;
    case 111:
      return (
        <span className="text-color-danger">
          Произошла ошибка на сервере. Чек распечатан.
        </span>
      );
    case 211:
      return <span className="text-color-warning">Ожидание оплаты</span>;
    case 202:
      return <span className="text-color-danger">Время оплаты истекло</span>;
    case 201:
      return (
        <span className="text-color-warning">Отказано в оплате квитанции</span>
      );
    default:
      return <span className="text-color-danger">Статус не определен</span>;
  }
}
