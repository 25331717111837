import React from "react";

function WorkWithItems({ className }) {
  return (
    <div className={className}>
      <div>
        <strong>Работа с товарами:</strong>
      </div>
      <p>
        создание каталогов и карточек товара, загрузка наименований и
        характеристик товара, учет
      </p>
    </div>
  );
}

export default WorkWithItems;
