import React, { useState } from "react";
import Window from "components/windows";
import { Form, Complete } from "../index";

import { SpinnerWrapper } from "components/wrappers";

function WindowComponent(props) {
  const [result, setResult] = useState(false);

  const onClose = () => {
    setResult(false);
    props.setShow(false);
  };

  return (
    <Window
      header="Заказать обратную связь"
      visible={props.show}
      onHide={onClose}
      className="p-col-12 p-md-8 p-lg-6 p-xl-4 p-p-0 c-header-center"
      draggable={false}
    >
      <SpinnerWrapper position="center">
        {!result ? (
          <Form confirm={setResult} />
        ) : (
          <Complete confirm={onClose} />
        )}
      </SpinnerWrapper>
    </Window>
  );
}

export default WindowComponent;
