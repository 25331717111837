import React, { useState } from "react";

import {
  AddressOfd,
  VatinOfd,
  NameOfd,
  Ofd,
} from "routes/pages/equipments/components";

function DOFD(props) {
  const [ofd, setOfd] = useState(null);

  return (
    <div className={props.className}>
      <span className="block-label-title p-pl-2">Данные ОФД</span>

      <Ofd
        className="p-col-12 p-md-6 p-lg-4 p-xl-3"
        item={props.item}
        getValue={setOfd}
      />

      <div className={!!ofd && ofd.id === 2 ? "p-d-block" : "p-d-none"}>
        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
          <NameOfd
            className="p-col-12 p-md-6 p-lg-4 p-xl-3"
            item={props.item}
          />
          <VatinOfd
            className="p-col-12 p-md-6 p-lg-4 p-xl-3"
            item={props.item}
          />
          <AddressOfd
            className="p-col-12 p-md-6 p-lg-4 p-xl-3"
            item={props.item}
          />
        </div>
      </div>
    </div>
  );
}

export default DOFD;
