import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DeleteOutline } from "@material-ui/icons";

import Taxes from "./components/TaxationOnReceipt";
import styles from "./styles.module.css";

import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import mapDispatchToProps from "../../../domain/actions/createReceipt.actions";

function PaymentsComponent({
  onConfirm,
  methods,
  requisite,
  receipt,
  items,
  isOnlinePayment,
  type,
}) {
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [priceC, setPrice] = useState(0);
  const [sum, setSum] = useState(0);
  const [cashback, setCashback] = useState(0);
  const [updateKey, forceUpdate] = useState(1);
  const [updateSum, forceUpdateSum] = useState(1);

  useEffect(() => {
    if (type === "payment") {
      receipt.payments.forEach((payment, index) => {
        if (index === 0) {
          methods.selectedValuePaymnet("bank_card", 0);
        } else methods.deletePayments(payment);
      });
    }
  }, [type]);

  useEffect(() => {
    let typeReceiptId = receipt.type ? receipt.type.id : null;
    let payments = receipt.payments;
    let amount = items.reduce((a, c) => {
      return a + c.amount;
    }, 0);

    if (payments.length === 1 && typeReceiptId < 4) {
      methods.selectedSumPaymnet(amount, 0);
    }
  }, [items, receipt, methods]);

  useEffect(() => {
    let amount = items.reduce((a, c) => {
      return a + c.amount;
    }, 0);
    let price = receipt.payments.reduce((a, c) => {
      return a + c.sum;
    }, 0);
    setSum(+Number(amount).toFixed(2));
    setPrice(+Number(price).toFixed(2));

    let cash = price - amount;
    setCashback(cash >= 0 ? cash : 0);
  }, [setSum, receipt.payments, items, updateSum]);

  const onConfirmPayment = () => {
    const cb = () => {
      setDisabledBtn(false);
    };
    setDisabledBtn(true);
    onConfirm(cb);
  };

  return (
    <React.Fragment>
      <div className={` p-col-12 p-d-flex p-flex-wrap`}>
        <div
          className={`p-col-12 p-md-3 p-p-0 ${
            receipt.type
              ? receipt.type.id > 4
                ? "p-d-block"
                : "p-d-none"
              : "p-d-none"
          }`}
        >
          <Taxes
            requisite={requisite}
            taxes={receipt.taxes}
            setValueTaxes={methods.selectedValueTaxes}
            setSumTaxes={methods.selectedSumTaxes}
            setTaxes={methods.setTaxes}
            deleteTaxes={methods.deleteTaxes}
          />
        </div>
        <div className="p-col-12 p-md-6 p-p-0">
          <div className="p-m-1">
            <div
              className={`${styles.form} p-mr-1 p-d-flex p-flex-wrap`}
              key={updateKey}
            >
              <div className={`p-col-12 p-p-2 p-d-md-flex p-d-none`}>
                <div className="p-col-12 p-md-6 p-xl-7">
                  <label className={`${styles.font}`}>Способ расчета</label>
                </div>
                <div className="p-col-12 p-md-6 p-xl-7">
                  <label className={`${styles.font}`}>Оплата</label>
                </div>
              </div>
              {receipt.payments.map((i, index) => {
                return (
                  <div key={index} className={`p-col-12 p-d-flex p-flex-wrap`}>
                    <div className="p-col-12 p-md-6 p-xl-7 p-d-flex p-flex-column">
                      <label
                        className={`${styles.font} p-pb-1 p-d-md-none p-d-flex`}
                      >
                        Способ расчета
                      </label>
                      <Dropdown
                        value={
                          requisite
                            ? requisite.paymentType.filter(
                                (pay) => pay.eng === i.type
                              )[0]
                            : null
                        }
                        options={requisite ? requisite.paymentType : []}
                        onChange={(e) => {
                          methods.selectedValuePaymnet(e.value.eng, index);
                          forceUpdate(updateKey + 1);
                        }}
                        optionLabel="name"
                        className={`${styles.borderBottom}`}
                        disabled={type === "payment"}
                      />
                    </div>
                    <div className="p-col-12 p-md-4 p-d-flex p-flex-column p-fluid">
                      <label
                        className={`${styles.font} p-pb-1 p-d-md-none p-d-flex`}
                      >
                        Оплата
                      </label>
                      <InputNumber
                        value={receipt.payments[index].sum}
                        onChange={(e) => {
                          methods.selectedSumPaymnet(e.value, index);
                          forceUpdateSum(updateSum + 1);
                        }}
                        mode="decimal"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        className={`${styles.borderBottom}`}
                        placeholder="00.00"
                      />
                    </div>

                    {index === 0 ? (
                      <div> </div>
                    ) : (
                      <div className=" p-col-4 p-md-2 p-xl-1 p-d-flex p-flex-column p-as-end">
                        <button
                          className="c-btn-outline c-btn-danger"
                          onClick={() => methods.deletePayments(i)}
                        >
                          <DeleteOutline />
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <Button
            className={`${styles.btn}`}
            label="Добавить способ расчета"
            icon="pi pi-plus"
            onClick={methods.setPayments}
            disabled={isOnlinePayment}
          />
        </div>

        <div
          className={`p-p-0 p-col-12 p-md-${
            receipt.type ? (receipt.type.id > 4 ? "3" : "6") : "6"
          } `}
        >
          <div className={`p-d-flex p-flex-wrap p-jc-end p-m-1`}>
            <div
              className={`p-col-12 p-lg-${
                receipt.type ? (receipt.type.id > 4 ? "12" : "7") : "7"
              } p-flex-wrap p-d-flex p-jc-between p-p-3 p-flex-row-reverse`}
            >
              <div
                className={`p-col-6 p-md-12 p-d-flex p-flex-column p-p-0 p-fluid`}
              >
                <label className={`${styles.font} p-pb-1 p-as-end`}>
                  Итог чека
                </label>
                <InputNumber
                  value={sum}
                  mode="decimal"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  className={`${styles.borderNone} ${styles.amount} p-as-end`}
                  disabled
                />
              </div>

              <div
                className={`p-col-6 p-md-12 p-d-flex p-flex-column p-p-0 p-fluid`}
              >
                <label className={`${styles.font} p-pb-1 p-as-end`}>
                  Сдача
                </label>
                <InputNumber
                  value={cashback}
                  mode="decimal"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  className={`${styles.borderNone} ${styles.amount} p-as-end`}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="p-col-12 p-d-flex p-jc-end">
            <div
              className={`p-col-12 ${
                receipt.type
                  ? receipt.type.id > 4
                    ? ""
                    : "p-md-6 p-lg-5"
                  : "p-md-6 p-lg-5"
              } `}
            >
              <Button
                id="button-confirm-payment"
                label="Сформировать"
                className="с-button-success"
                onClick={onConfirmPayment}
                disabled={disabledBtn || !(priceC - sum >= 0)}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  receipt: state.createReceipt,
  items: state.createReceiptItems.items,
  isOnlinePayment: state.createReceipt.isOnlinePayment,
  type: state.createReceiptItems.type,
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsComponent);
