import React from "react";
import styles from "../style.module.css";

function Signature(props) {
  return (
    <div className={props.className}>
      <span className={styles.signature}>Release v 2.8.0 от 13.02.2024</span>
    </div>
  );
}

export default Signature;
