import React from "react";
import { connect } from "react-redux";
import {
  Tid,
  CertKey
} from "../../../../../components/fields/sber";
import DeleteBtn from "../../../components/acquiring/deleteBtn";
import {
  TypePaymentSystem,
  TokenPayment,
  PaymentCallback,
  ShopIdOrLogin,
  SecretOrPassword,
  PaymentComment,
} from "../../../../../components/fields";
import { Message } from 'primereact/message';

function SberParams(props) {

  return (
    <div className={props.className}>
      <TypePaymentSystem
        className="p-col-12 p-md-4 p-xl-3"
        value={props.item.acquiringId}
        index={props.index}
      />
      <TokenPayment
        className="p-col-12 p-md-7 p-xl-8"
        value={props.item.paymentToken}
        index={props.index}
      />

      <DeleteBtn
        className="p-col-4 p-md-1 p-d-flex p-jc-center p-ai-end"
        index={props.index}
      />

      <PaymentCallback
        className="p-col-12 p-md-4"
        value={props.item.paymentCallback}
        index={props.index}
      />
      <ShopIdOrLogin
        className="p-col-12 p-md-4"
        value={props.item.paymentLogin}
        index={props.index}
      />
      <SecretOrPassword
        className="p-col-12 p-md-4"
        value={props.item.paymentPassword}
        index={props.index}
      />

      <Tid
        className="p-col-12 p-md-4"
        value={props.item.tid}
        index={props.index}
      />

      <CertKey
        className="p-col-12 p-md-8"
        value={props.item.certkey}
        index={props.index}
      />

      <PaymentComment
        className="p-col-12"
        value={props.item.comment}
        index={props.index}
      />

      <Message 
        className="p-col-12"
        severity="info" 
        text="Добавление сертификата будет доступно в детальном просмотре 'Эквайринга' после создания организации."
        closable={false}
      />
    
    </div>
  );
}

export default connect(null)(SberParams);
