import React from "react";
import { SelectControl } from "components/forms/fields";
import { connect } from "react-redux";

function RootLevel(props) {
  const { control, errors } = props.formHook;
  const { disabled } = props;

  return (
    <SelectControl
      label="Права доступа"
      className={props.className}
      name="roles"
      control={control}
      errors={errors}
      optionLabel="name"
      options={props.roles}
      disabled={disabled}
    />
  );
}

const mapStateToProps = (state) => ({
  roles: state.users.roles,
});

export default connect(mapStateToProps)(RootLevel);
