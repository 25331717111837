import { SET_TRANSLATE_ROLES_REQUISITES } from "../actionTypes";

const initialState = null;

export default function translatesReduce(state = initialState, action) {
  switch (action.type) {
    case SET_TRANSLATE_ROLES_REQUISITES:
      return {
        ...state,
        roles: action.payload,
      };

    default:
      return state;
  }
}
