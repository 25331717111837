import React, { useState } from "react";
import {
  PrintOnPaper,
  SumDoc,
  TypeOperation,
  ConfirmButton,
} from "../../components";

function Layouts(props) {
  const [type, setType] = useState(1);
  const [sum, setSum] = useState();
  const [print, setPrint] = useState(false);

  return (
    <div className={props.className}>
      <TypeOperation
        value={type}
        setValue={setType}
        className="p-pt-2 p-pb-2"
      />
      <SumDoc value={sum} setValue={setSum} className="p-pt-2 p-pb-2" />
      <PrintOnPaper
        value={print}
        setValue={setPrint}
        className="p-pt-3 p-pb-2 p-d-flex"
      />
      <ConfirmButton
        className="p-pt-3"
        data={{ type: type, sum: sum, print: print }}
      />
    </div>
  );
}

export default Layouts;
