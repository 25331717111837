import axios from "axios";

export class AuthController {
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  setTokenStorage(tokens) {
    localStorage.setItem("AccessToken", tokens.accessToken);
  }

  clearTokens() {
    localStorage.removeItem("AccessToken");
  }

  getTokenStorage() {
    let tokens = {
      accessToken: null,
    };

    if (localStorage.getItem("AccessToken")) {
      tokens.accessToken = localStorage.getItem("AccessToken");
    }

    return tokens;
  }

  async getTokenSSO() {
    const url = `/api/user/ctxToken`;

    return await axios
      .get(url)
      .then((r) => {
        if (r.data === "no session") return null;

        this.setTokenStorage({ accessToken: r.data });
        return true;
      })
      .catch((e) => {
        return null;
      });
  }

  async login() {
    window.location.href = `/api/auth/login`;
  }

  async logout() {
    window.location.href = `/api/auth/logout`;
  }
}
