import React from "react";
import { ConfirmDialog } from "primereact/confirmdialog";

function WarningGetToken(props) {
  return (
    <ConfirmDialog
      className="p-col-12 p-md-6 p-xl-4 p-p-0 c-confirm-dialog"
      visible={props.visible}
      onHide={() => props.setVisible(false)}
      message="Текущий Статический токен может использоваться, его смена приведет к невозможности работы расширений, в которых он прописан. Вы уверены что хотите его заменить?"
      header="Предупреждение"
      icon="pi pi-exclamation-triangle"
      accept={props.accept}
      reject={() => props.setVisible(false)}
    />
  );
}

export default WarningGetToken;
