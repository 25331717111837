import React, { useState } from "react";
import { DeleteOutline } from "@material-ui/icons";

import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import styles from "../styles.module.css";

function TaxationOnReceiptComponent({
  className,
  taxes,
  requisite,
  setSumTaxes,
  setValueTaxes,
  setTaxes,
  deleteTaxes,
}) {
  const [updateKey, forceUpdate] = useState(1);

  return (
    <React.Fragment>
      <div
        className={`${styles.form} p-m-1 p-d-flex p-flex-wrap`}
        key={updateKey}
      >
        <div className={`p-col-12 p-p-2 p-d-md-flex p-d-none`}>
          <div className="p-col-12">
            <label className={`${styles.font}`}>Налог на чек</label>
          </div>
        </div>

        {taxes.map((i, index) => {
          return (
            <div key={index} className={`p-col-12 p-d-flex p-flex-wrap`}>
              <div className="p-col-12 p-lg-5 p-d-flex p-flex-column p-fluid">
                <label className={`${styles.font} p-pb-1 p-d-md-none p-d-flex`}>
                  Налог на чек
                </label>
                <InputNumber
                  value={taxes[index].sum}
                  onChange={(e) => {
                    setSumTaxes(e.value, index);
                  }}
                  mode="decimal"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  className={`${styles.borderBottom}`}
                  placeholder="00.00"
                />
              </div>

              <div className="p-col-8 p-lg-5 p-d-flex p-flex-column">
                <Dropdown
                  value={
                    requisite
                      ? requisite.tax.filter((tax) => tax.eng === i.type)[0]
                      : null
                  }
                  options={requisite ? requisite.tax : []}
                  onChange={(e) => {
                    setValueTaxes(e.value.eng, index);
                    forceUpdate(updateKey + 1);
                  }}
                  optionLabel="name"
                  className={`${styles.borderBottom}`}
                />
              </div>

              {/* {index === 0 ? (
                <div> </div>
              ) : (
                <div className=" p-col-4 p-md-2 p-d-flex p-flex-column p-as-end">
                  <button
                    className="c-btn-outline c-btn-danger"
                    onClick={() => deleteTaxes(i)}
                  >
                    <DeleteOutline />
                  </button>
                </div>
              )} */}
              <div className=" p-col-4 p-md-2 p-d-flex p-flex-column p-as-end">
                <button
                  className="c-btn-outline c-btn-danger"
                  onClick={() => deleteTaxes(i)}
                >
                  <DeleteOutline />
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <Button
        className={`${styles.btn}`}
        label="добавить налог"
        icon="pi pi-plus"
        onClick={setTaxes}
      />
    </React.Fragment>
  );
}

export default TaxationOnReceiptComponent;
