import React from "react";
import { Success } from "components/static";
import styles from "components/static/style.module.css";

function SuccessIcon(props) {
  return (
    <div className="c-position-relative">
      <img src={process.env.PUBLIC_URL + "/img/VD.png"} alt="VDCloud" />
      <div className={styles.successIcon}>
        <Success />
      </div>
    </div>
  );
}

export default SuccessIcon;
