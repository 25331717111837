import React from "react";
import { CardOpenScreen, LabelInfo } from "components/static";
import { toDate } from "controllers/typeToString";

function RequisiteComponent(props) {
  const requisitesHeader = (
    <span className="block-label-title p-pl-2 p-as-center">Реквизиты чека</span>
  );
  const item = props.item;
  const cashbox = item.cashbox;

  return (
    <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
      <CardOpenScreen
        label={requisitesHeader}
        className="p-col-12 p-p-0 p-mb-2"
        defaultShow={true}
      >
        <div className="p-d-flex p-flex-wrap p-p-3 p-mb-3">
          <LabelInfo
            label="Наименование ККТ"
            info={
              cashbox && cashbox.cashboxModel.model
                ? cashbox.cashboxModel.model
                : "-"
            }
            className=" p-col-6 p-md-4"
          />

          <LabelInfo
            label="РН ККТ"
            info={cashbox && cashbox.regNumber ? cashbox.regNumber : "-"}
            className="-col-6 p-md-4"
          />

          <LabelInfo
            label="ЗН ККТ"
            info={
              cashbox && cashbox.factoryNumber ? cashbox.factoryNumber : "-"
            }
            className="p-col-6 p-md-4"
          />

          <LabelInfo
            label="Номер ФН"
            info={item && item.fn.number ? item.fn.number : "-"}
            className="p-col-6 p-md-4"
          />

          <LabelInfo
            label="Номер ФД"
            info={item && item.fdn ? item.fdn : "-"}
            className="p-col-6 p-md-4"
          />

          <LabelInfo
            label="Номер ФП"
            info={item && item.fpd ? item.fpd : "-"}
            className="p-col-6 p-md-4"
          />

          <LabelInfo
            label="Тип СНО"
            info={item && item.sno.name ? item.sno.name : "-"}
            className="p-col-6 p-md-4"
          />

          <LabelInfo
            label="Тип чека"
            info={item && item.receiptType.name ? item.receiptType.name : "-"}
            className="p-col-6 p-md-4"
          />

          <LabelInfo
            label="Дата создания"
            info={item && item.createdAt ? toDate(item.createdAt) : "-"}
            className="p-col-6 p-md-4"
          />
          <LabelInfo
            label="Смена"
            info={item && item.shift ? item.shift : "-"}
            className="p-col-6 p-md-4"
          />
          <LabelInfo
            label="ИНН"
            info={item && item.organization.inn ? item.organization.inn : "-"}
            className="p-col-6 p-md-4"
          />
          <LabelInfo
            label="Дата фискализации"
            info={item && item.fnDateTime ? toDate(item.fnDateTime) : "-"}
            className="p-col-6 p-md-4"
          />
        </div>
      </CardOpenScreen>
    </div>
  );
}

export default RequisiteComponent;
