import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setProp, setPropEdit } from "domain/actions/organizations/created";
import { CheckboxField } from "components/forms/fields";

import styles from "../../../style.module.css";

function Delegate(props) {
  const [value, setValue] = useState(false);
  const [updateKey, forceUpdate] = useState(1);
  const { disabled = false } = props;
  const setProp = !props.select ? props.setProp : props.setPropEdit;

  useEffect(() => {
    if (props.select) return setValue(props.select.delegate);
    setValue(props.value);
  }, [props.value, props.select]);

  return (
    <CheckboxField
      className={props.className + " " + styles.delegate}
      key={updateKey}
      label="Есть представитель"
      checked={value}
      onChange={(e) => {
        setProp("delegate", e.checked);
        forceUpdate(updateKey + 1);
      }}
      disabled={disabled}
    />
  );
}

const mapStateToProps = (state) => ({
  value: state.orgCreate.item.delegate,
  select: state.orgCreate.select,
});
export default connect(mapStateToProps, { setProp, setPropEdit })(Delegate);
