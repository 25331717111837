import React, { useEffect, useMemo, useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import styles from "../style.module.css";

export const COLORS = ["#40af03d4", "#dc8919d4", "#721494d4", "#ccc"];

function Chart(props) {
  const [topSum, setTopSum] = useState(0);

  const data = useMemo(() => (props.data ? props.data : []), [props.data]);

  useEffect(() => {
    const sum = data.reduce((s, c) => {
      return +s + +c.moneyIn;
    }, 0);
    setTopSum(sum.toFixed(2));
  }, [topSum, data]);

  const renderActiveShape = (props) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
    } = props;
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#000">
          <tspan className={styles.topOrgTspan} x={cx} y={cy - 40}>
            Общая
          </tspan>
          <tspan className={styles.topOrgTspanAmount} x={cx} y={cy + 5}>
            {topSum}
          </tspan>
          <tspan x={cx} y={cy + 25}>
            руб.
          </tspan>
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip p-p-2">
          <p className="dashboard-label">{`${payload[0].name}`}</p>
          <p className="dashboard-label-other">{`Приход: ${payload[0].payload.moneyIn} руб.`}</p>
          <p className="dashboard-label-other">{`Кол-во чеков: ${payload[0].payload.receiptsIn} шт.`}</p>
          <p className="dashboard-label-other">{`Расход: ${payload[0].payload.moneyOut} руб.`}</p>
          <p className="dashboard-label-other">{`Кол-во чеков: ${payload[0].payload.receiptsOut} шт.`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%">
      <PieChart>
        <Pie
          data={data}
          activeIndex={0}
          activeShape={renderActiveShape}
          dataKey="moneyIn"
          cx="50%"
          cy="50%"
          innerRadius={70}
          outerRadius={100}
          labelLine={false}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>

        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default Chart;
