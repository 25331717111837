import React, { useEffect } from "react";
import { connect } from "react-redux";
import Params from "../../../../../../../Parameters.item";
import {
  setUnitFunc,
  setParametersFunc,
} from "../../../../../controllers/setValues";

function Parameters(props) {
  const formHook = props.formHook;

  useEffect(() => {
    if (props.item) {
      let data = { ...props.item };

      setParametersFunc(data, formHook.setValue);
      setUnitFunc(data.measurementUnit, formHook.setValue, props.requisite);
    }
  }, [props.item, formHook.setValue, props.requisite]);

  return (
    <div className={props.className}>
      <Params
        register={formHook.register}
        errors={formHook.errors}
        control={formHook.control}
        setValue={formHook.setValue}
        requisite={props.requisite}
        item={props.item}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  item: state.createReceiptEdit.item,
  requisite: state.requisites,
});
export default connect(mapStateToProps)(Parameters);
