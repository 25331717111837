import { SHOW_ERROR } from "domain/actionTypes";
import {
  SET_BID_EQUIPMENTS,
  REFRESH_BID_EQUIPMENTS,
  SET_LOCAL_BID_EQUIPMENTS,
  SELECT_BID_EQUIPMENTS,
  SHOW_WINDOW_BID_EQUIPMENTS,
  ADD_CASHBOX_BID_EQUIPMENTS,
  UPDATE_CASHBOX_BID_EQUIPMENTS,
  DELETE_CASHBOX_BID_EQUIPMENTS,
  CLEAR_CASHBOX_BID_EQUIPMENTS,
  CLEAR_BID_EQUIPMENTS,
  SET_ORG_BID_EQUIPMENTS,
  SET_URL_BID_EQUIPMENTS,
  SORT_BID_EQUIPMENTS,
  SET_PAGINATION_BID_EQUIPMENT,
  SET_TARIFF_BID_EQUIPMENTS,
  LOADING_CASHBOX_BID_EQUIPMENTS,
} from "../../../types/equipment";
import { TOGGLE_PROGRESS } from "domain/actionTypes";
import formationData from "./funcs/getData";
import { getDataOnEdit } from "./funcs/getDataOnEdit";
import ClientController from "utils/Client";
import orderBy from "lodash/orderBy";
import { ApiController } from "domain/controllers";

const Client = new ClientController();
const Api = new ApiController();

export function getBid() {
  return async function thunk(dispatch, getState) {
    return await Client.get("/bid/list")
      .then((response) => {
        let result = response.data.result;

        dispatch({ type: SET_BID_EQUIPMENTS, payload: result });
      })
      .catch((e) => dispatch({ type: SHOW_ERROR, payload: e.message }));
  };
}

export function getBidByFilter(page) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: TOGGLE_PROGRESS, payload: true });

    await Client.sleep(300);

    let params = { limit: 10, page: page };
    let filters = getFilters(getState);

    Object.assign(params, filters);

    return await Client.post(`/bid/list`, params)
      .then((response) => {
        let result = response.data.result;
        dispatch({
          type: SET_BID_EQUIPMENTS,
          payload: result.data,
        });

        dispatch({
          type: SET_PAGINATION_BID_EQUIPMENT,
          payload: result,
        });

        dispatch({ type: TOGGLE_PROGRESS, payload: false });
        return true;
      })
      .catch((e) => dispatch({ type: TOGGLE_PROGRESS, payload: false }));
  };
}

export function getDistribution(distribution) {
  return async function thunk(dispatch, getState) {
    const response = await Api.get(`/worker/installer`, {
      version: distribution,
    });

    const url = response.data;

    window.open(
      url,
      "_blank" // <- This is what makes it open in a new window.
    );
  };
}

export function getCodeActive(worker) {
  return async function thunk(dispatch, getState) {
    const response = await Api.post(`/worker/self`, worker);

    return response.data;
  };
}

export function updateWorker(workerId, worker) {
  return async function thunk(dispatch, getState) {
    const response = await Api.put(`/worker/${workerId}`, worker);

    return response.data;
  };
}

export function deleteWorker(workerId) {
  return async function thunk(dispatch, getState) {
    await Api.delete(`/worker/self/${workerId}`);
  };
}

export function postBid() {
  return async function thunk(dispatch, getState) {
    const data = formationData(getState, true);

    if (data.isValid !== undefined && !data.isValid)
      return dispatch({
        type: SHOW_ERROR,
        payload: data.error,
      });

    return await Api.post("/bid/new", data).then((response) => {
      clearFull(dispatch);
    });
    // .catch((e) => dispatch({ type: SHOW_ERROR, payload: e.message }));
  };
}

export function putBid(id) {
  return async function thunk(dispatch, getState) {
    const data = formationData(getState);

    if (data.isValid !== undefined && !data.isValid)
      return dispatch({
        type: SHOW_ERROR,
        payload: data.error,
      });

    delete data.externId;

    return await Api.put(`/bid/${id}`, data).then((response) => {
      clearFull(dispatch);
    });
    // .catch((e) => dispatch({ type: SHOW_ERROR, payload: e.message }));
  };
}

export function deleteBid(id) {
  return async function thunk(dispatch, getState) {
    console.log(id, "deleteBid");
    // return await Client.delete(`/bid/${id}`)
    //   .then((response) => {
    //     clearFull(dispatch)
    //   })
    //   .catch((e) => dispatch({ type: SHOW_ERROR, payload: e.message }));
  };
}

export function setIsLocal(value) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_LOCAL_BID_EQUIPMENTS, payload: value });
  };
}
export function setOrganization(value) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_ORG_BID_EQUIPMENTS, payload: value.id });
  };
}

export function setURL(value) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_URL_BID_EQUIPMENTS, payload: value });
  };
}

export function setTariff(tariffId) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SET_TARIFF_BID_EQUIPMENTS, payload: tariffId });
  };
}

export function setProp(key, value, id) {
  return async function thunk(dispatch, getState) {
    const items = getState().bidEquipmentsCashbox.list.slice();
    const index = items.map((i) => i.id).indexOf(id);

    if (items[index] === undefined) {
      return;
    }

    items[index][key] = value;

    dispatch({ type: UPDATE_CASHBOX_BID_EQUIPMENTS, payload: items });
  };
}

export function clearCashbox() {
  return async function thunk(dispatch, getState) {
    return dispatch({ type: CLEAR_CASHBOX_BID_EQUIPMENTS });
  };
}

export function updateCashbox() {
  return async function thunk(dispatch, getState) {
    const list = getState().bidEquipmentsCashbox.list.slice();

    return dispatch({ type: UPDATE_CASHBOX_BID_EQUIPMENTS, payload: list });
  };
}

export function deleteCashbox(id) {
  return async function thunk(dispatch, getState) {
    const list = getState().bidEquipmentsCashbox.list.slice();
    let index = list.map((el) => el.id).indexOf(id);

    list.splice(index, 1);

    return dispatch({ type: DELETE_CASHBOX_BID_EQUIPMENTS, payload: list });
  };
}

export function addCashbox() {
  return async function thunk(dispatch, getState) {
    const isLocal = getState().bidEquipments.isLocal;
    const schemaItemClient = {
      ...getState().bidEquipmentsCashbox.schemaItemClient,
    };
    const schemaItemProvider = {
      ...getState().bidEquipmentsCashbox.schemaItemProvider,
    };
    let id = getState().bidEquipmentsCashbox.id;

    schemaItemProvider.id = id;
    schemaItemClient.id = id;

    let cashboxes = getState().bidEquipmentsCashbox.list.slice();

    if (cashboxes.length >= 50)
      return dispatch({
        type: SHOW_ERROR,
        payload: "Достигнут лимит по кол-ву касс в одной заявке",
      });

    if (isLocal.id === 1)
      return dispatch({
        type: ADD_CASHBOX_BID_EQUIPMENTS,
        payload: schemaItemClient,
      });

    return dispatch({
      type: ADD_CASHBOX_BID_EQUIPMENTS,
      payload: schemaItemProvider,
    });
  };
}

export function selectBid(data) {
  return async function thunk(dispatch, getState) {
    const res = { ...data };
    const bid = getDataOnEdit(res, getState);
    const tariffId = bid.billingBidInfo ? bid.billingBidInfo.tariffId : null;
    console.log(bid);
    dispatch({ type: SELECT_BID_EQUIPMENTS, payload: { ...bid } });
    dispatch({ type: SET_TARIFF_BID_EQUIPMENTS, payload: tariffId });

    dispatch({ type: SET_ORG_BID_EQUIPMENTS, payload: bid.organization });
    dispatch({ type: SET_LOCAL_BID_EQUIPMENTS, payload: bid.isLocal });
    dispatch({
      type: UPDATE_CASHBOX_BID_EQUIPMENTS,
      payload: bid.cashboxes.slice(),
    });

    dispatch({ type: SHOW_WINDOW_BID_EQUIPMENTS, payload: true });
  };
}

export function handleShowWindow(value) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: SHOW_WINDOW_BID_EQUIPMENTS, payload: value });
  };
}

export function clearEditBid() {
  return async function thunk(dispatch, getState) {
    dispatch({ type: CLEAR_CASHBOX_BID_EQUIPMENTS });
    // dispatch({ type: CLEAR_BID_EQUIPMENTS });
    dispatch({ type: SELECT_BID_EQUIPMENTS, payload: null });
  };
}

export function sortBid(params) {
  return async function thunk(dispatch, getState) {
    const items = getState().bidList.equipments.slice();

    for (let key in params) {
      const data = orderBy(items, [key], params[key].toLowerCase());
      dispatch({ type: SORT_BID_EQUIPMENTS, payload: data });
    }
  };
}

function getFilters(getState) {
  let filters = {
    sort: getState().bidFilter.sort,
    range: getState().bidFilter.range,
    filter: getState().bidFilter.filter,
  };

  for (let key in filters) {
    if (filters[key] === null) delete filters[key];
  }

  return filters;
}

export function setLoading(isLoading) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: LOADING_CASHBOX_BID_EQUIPMENTS, payload: isLoading });
  };
}

function clearFull(dispatch) {
  dispatch({ type: SHOW_WINDOW_BID_EQUIPMENTS, payload: false });
  dispatch({ type: CLEAR_CASHBOX_BID_EQUIPMENTS });
  dispatch({ type: CLEAR_BID_EQUIPMENTS });
  dispatch({ type: SELECT_BID_EQUIPMENTS, payload: null });
  dispatch({ type: REFRESH_BID_EQUIPMENTS });
}
