import React from "react";
import styles from "../style.module.css";

function Heading({ className = "", title = "" }) {
  return (
    <div className={className}>
      <h3 className={styles.main_title}>{title}</h3>
    </div>
  );
}

export default Heading;
