import React, { useContext, createContext } from "react";
import { useSelector } from "react-redux";

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
export const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

export function useProvideAuth() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return {
    isAuthenticated,
  };
}
