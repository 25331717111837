import React from "react";
import { connect } from "react-redux";
import { ResultSum } from "../../../components";
import { CardComponent, LabelToUpperCase } from "components/static";

function Payments(props) {
  const payment = props.item.payment;
  const label = <ResultSum sumDoc={props.item.sumDoc} />;
  const sum = payment
    ? (+payment.cash + +payment.electronically).toFixed(2)
    : null;
  const cashback = sum - +props.item.sumDoc;
  const content = (
    <div className="p-col-12 p-pl-3 p-pb-3">
      <LabelToUpperCase
        label="НАЛИЧНЫЕ"
        value={`=${payment ? Number(payment.cash) : "ERROR"}`}
      />
      <LabelToUpperCase
        label="БЕЗНАЛИЧНЫМИ"
        value={`=${payment ? Number(payment.electronically) : "ERROR"}`}
      />
      <LabelToUpperCase
        label="ПОЛУЧЕНО"
        value={`=${payment ? Number(sum) : "ERROR"}`}
      />
      <LabelToUpperCase label="СДАЧА" value={`=${cashback}`} />
    </div>
  );
  return (
    <div className={props.className}>
      <CardComponent label={label} className={props.className}>
        {content}
      </CardComponent>
    </div>
  );
}

const mapStateToProps = (state) => ({
  item: state.receipts.select,
});
export default connect(mapStateToProps)(Payments);
