import React from "react";
import Form from "./components/form";
import Window from "components/windows";

function MarkingWindow(props) {
  return (
    <Window
      header="Код маркировки"
      visible={props.show}
      className="p-col-12 p-md-6 p-p-0 c-dialog"
      onHide={props.onHide}
      draggable={false}
    >
      <Form
        className="p-d-flex p-flex-column p-jc-between"
        close={props.onHide}
      ></Form>
    </Window>
  );
}

export default MarkingWindow;
