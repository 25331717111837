import React from "react";
import { Button } from "primereact/button";
import { TextField } from "components/forms/fields";

function CodeWorker(props) {
  const { code } = props;

  const handleCopy = () => navigator.clipboard.writeText(code);

  return (
    <div className="p-col-12 p-d-flex p-p-0">
      <TextField
        label="Код подключения"
        className="p-col-11"
        value={code.slice(0, 19) + "..."}
      />

      <div className="p-col-1 p-p-0 p-d-flex p-ai-center">
        <Button
          className="p-button-rounded p-button-text icon-style-1"
          icon="pi pi-copy"
          onClick={handleCopy}
        />
      </div>
    </div>
  );
}

export default CodeWorker;
