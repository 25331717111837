import React from "react";
import { connect } from "react-redux";

function HeaderWin(props) {
  const number = props.item ? props.item.id : " - ";
  const numbers = (
    <span
      className="p-ml-4 p-as-end"
      style={{ color: "rgb(76, 175, 80)", fontSize: "18px" }}
    >
      №{number}
    </span>
  );

  return (
    <div className="p-d-flex">
      <p className="p-m-0">Информация о чеке </p>
      {numbers}
    </div>
  );
}

const mapStateToProps = (state) => ({
  item: state.receipts.select,
});
export default connect(mapStateToProps)(HeaderWin);
