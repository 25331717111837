import {
  CLEAR_ERROR,
  SHOW_ERROR,
  CLEAR_SUCCESS,
  SHOW_SUCCESS,
} from "../actionTypes";

export function showError(message) {
  return async function registerThunk(dispatch, getState) {
    dispatch({
      type: SHOW_ERROR,
      payload: message,
    });
  };
}

export function clearError() {
  return async function registerThunk(dispatch, getState) {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
}

export function showSuccess(message) {
  return async function Thunk(dispatch, getState) {
    dispatch({
      type: SHOW_SUCCESS,
      payload: message,
    });
  };
}

export function clearSuccess() {
  return async function Thunk(dispatch, getState) {
    dispatch({
      type: CLEAR_SUCCESS,
    });
  };
}
