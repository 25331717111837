import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setTariff } from "domain/actions/equipment/bid";
import Label from "components/based/label";
import { Select } from "components/forms/fields";
import styles from "../../style.module.css";

function TariffBid(props) {
  const [updateKey, forceUpdate] = useState(1);
  const [value, setValue] = useState(null);
  const { organizationId, selected, tariffs, tariffId, setTariff } = props;

  useEffect(() => {
    if (!tariffs) return;
    const item2 = tariffs.filter((i) => i.id === tariffId);
    setValue(item2.length ? item2[0] : null);
  }, [tariffs, tariffId, setValue]);

  const isActive = selected ? selected.status !== 11 : false;

  return (
    <>
      {organizationId && (
        <div className={props.className}>
          <Label
            value="Выберите, тариф"
            className={`${styles.hLabel} p-col-12 p-pr-2 p-pl-0`}
            required
          />

          <Select
            key={updateKey}
            options={tariffs}
            optionLabel="name"
            value={value}
            onChange={(e) => {
              setTariff(e.id);
              forceUpdate(updateKey + 1);
            }}
            className={`${styles.local} p-col-12 p-p-0 `}
            disabled={isActive}
          />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  organizationId: state.bidEquipments.organizationId,
  tariffId: state.bidEquipments.tariffId,
  tariffs: state.requisites?.tariffs,
  selected: state.bidList.select,
});
export default connect(mapStateToProps, {
  setTariff,
})(TariffBid);
