import React from "react";
import Layouts from "./layouts";
import RightsPage from "components/rightsControl/RightsPage";

function Receipts(props) {
  return (
    <RightsPage right="CHECK">
      <Layouts history={props.history} />
    </RightsPage>
  );
}

export default Receipts;
