import React from "react";
import { Button } from "primereact/button";

function HistoryBtn(props) {
  return (
    <div className={props.className}>
      <Button
        label="История платежей"
        onClick={() => console.log("История платежей")}
        className="c-btns--gradient"
      />
    </div>
  );
}

export default HistoryBtn;
