import React from "react";
import { connect } from "react-redux";

function RightsPage({ right, rights, children }) {
  return (
    <>
      {!rights.includes(right) ? (
        <div className="p-col-12 p-d-flex p-flex-center p-ai-center">
          <p>У вас недостаточно прав для просмотра данной страницы</p>
        </div>
      ) : (
        children
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  rights: state.users.rights,
});

export default connect(mapStateToProps)(RightsPage);
