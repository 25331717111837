import React from "react";
import { TextAreaFieldControl } from "components/forms/fields";

function TextArea(props) {
  const paramTextArea = {
    rows: 3,
    cols: 10,
    autoResize: true,
    placeholder: "Текст сообщения",
  };

  return (
    <TextAreaFieldControl
      className="p-p-2"
      name="request"
      control={props.formHook.control}
      errors={props.formHook.errors}
      label="Напишите нам:"
      params={paramTextArea}
      defaultValue=""
    />
  );
}

export default TextArea;
