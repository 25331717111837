import React, { useState, useCallback } from "react";
import { connect } from "react-redux";

import { KladrField } from "components/forms/fields";
import {
  setProp,
  setPropEdit,
  setIsValidate,
} from "domain/actions/organizations/created";

function AddressActual(props) {
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const { defaultQuery, select, rules, disabled } = props;
  const setIsValidate = props.setIsValidate;

  const setProp = !select ? props.setProp : props.setPropEdit;

  const validate = useCallback(
    (value) => {
      if (Object.keys(value).length) {
        if (!value.data.postal_code) {
          setError(true);
          setIsValidate(false);
        } else {
          setError(false);
        }
      }
    },
    [setError, setIsValidate]
  );

  return (
    <div className={props.className}>
      <KladrField
        label="Фактический адрес"
        className="p-col-12 p-p-0"
        value={value}
        onChange={(e) => {
          setValue(e);
          validate(e);
          setProp("addressActual", e.value);
        }}
        defaultQuery={select ? select.addressActual : defaultQuery}
        rules={rules}
        onValidate={props.setIsValidate}
        disabled={disabled}
      />
      {error && (
        <small className="p-error p-d-block">Адрес введен не полностью!</small>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  value: state.orgCreate.item.addressActual,
  defaultQuery: state.orgCreate.item.addressActual,
  rules: state.orgCreate.required,
  select: state.orgCreate.select,
});
export default connect(mapStateToProps, {
  setProp,
  setPropEdit,
  setIsValidate,
})(AddressActual);
