import React from "react";
import { connect } from "react-redux";
import { selectedEquipment } from "domain/actions/equipment";
import { Card } from "./components";

function BodyTable(props) {
  const onEdit = (item) => props.selectedEquipment(item);

  const renderedListItems = props.cashbox.map((i, index) => {
    return <Card key={index} item={i} onPreview={() => onEdit(i)} />;
  });

  return <div className={props.className}>{renderedListItems}</div>;
}

const mapStateToProps = (state) => ({
  cashbox: state.equipments.equipments,
});

export default connect(mapStateToProps, { selectedEquipment })(BodyTable);
