import {
  SET_DOCS_SETTINGS,
  SET_SUPPORT_SETTINGS,
  SET_ACCOUNT_NAME_SETTINGS,
  SET_SETTINGS,
} from "domain/types/settings";

const initialState = {
  name: "",
  emailDocs: "",
  emailTech: "",
};

export default function SettingsReduce(state = initialState, action) {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        name: action.payload.name,
        emailDocs: action.payload.emailDocs,
        emailTech: action.payload.emailTech,
      };
    case SET_ACCOUNT_NAME_SETTINGS:
      return {
        ...state,
        name: action.payload,
      };
    case SET_DOCS_SETTINGS:
      return {
        ...state,
        emailDocs: action.payload,
      };
    case SET_SUPPORT_SETTINGS:
      return {
        ...state,
        emailTech: action.payload,
      };
    default:
      return state;
  }
}
