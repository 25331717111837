import React from "react";
import { connect } from "react-redux";
import { Menu } from "components/static";

import { getUsers } from "domain/actions/users";
import { handleShowWindow } from "domain/actions/users";

import AddIcon from "@material-ui/icons/Add";
import RefreshIcon from "@material-ui/icons/Refresh";

function MenuComponent(props) {
  const listMenu = [
    {
      id: "1",
      name: "Добавить пользователя",
      icon: AddIcon,
      callback: () => props.handleShowWindow(true),
    },
    {
      id: "2",
      name: "Обновить страницу",
      icon: RefreshIcon,
      callback: () => props.getUsers(props.pageIndex),
    },
  ];

  if (!props?.rights.includes("USERS_CREATE")) listMenu.shift();

  return <Menu list={listMenu} className={props.className} />;
}

const mapStateToProps = (state) => ({
  pageIndex: state.orgPagination.page,
  rights: state.users.rights,
});

export default connect(mapStateToProps, { getUsers, handleShowWindow })(
  MenuComponent
);
