import React from "react";
import { connect } from "react-redux";

import { Header, Dashboard } from "./components";

function Layouts(props) {
  return (
    <div className="p-d-flex p-flex-wrap p-col-12 p-p-0">
      <Header />

      <Dashboard />
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.analytics.data,
});
export default connect(mapStateToProps)(Layouts);
