import React from "react";
import { LabelInfo } from "components/static";

function AgentData(props) {
  const agentInfo = props.agentInfo;

  const supplierInfo = props.supplierInfo;
  const moneyTransferOperator = agentInfo
    ? agentInfo.moneyTransferOperator
    : null;
  const payingAgent = agentInfo ? agentInfo.payingAgent : null;
  const receivePaymentsOperator = agentInfo
    ? agentInfo.receivePaymentsOperator
    : null;

  return (
    <div className={props.className}>
      <LabelInfo
        label="ИНН оп.перевода"
        info={moneyTransferOperator ? moneyTransferOperator.vatin : "-"}
        className=" p-col-6 p-md-4"
      />

      <LabelInfo
        label="Оператор перевода"
        info={moneyTransferOperator ? moneyTransferOperator.name : "-"}
        className="p-col-6 p-md-4"
      />

      <LabelInfo
        label="Тел. оп.перевода"
        info={
          moneyTransferOperator
            ? !!moneyTransferOperator.phones
              ? moneyTransferOperator.phones.join(", ")
              : "-"
            : "-"
        }
        className="p-col-6 p-md-4"
      />

      <LabelInfo
        label="Оп. агента"
        info={payingAgent ? payingAgent.operation : "-"}
        className="p-col-6 p-md-4"
      />

      <LabelInfo
        label="Тел. пл.агента"
        info={
          payingAgent
            ? !!payingAgent.phones
              ? payingAgent.phones.join(", ")
              : "-"
            : "-"
        }
        className="p-col-6 p-md-4"
      />

      <LabelInfo
        label="Тел. оп.пр.платежа"
        info={
          receivePaymentsOperator
            ? receivePaymentsOperator.phones.join(", ")
            : "-"
        }
        className="p-col-6 p-md-4"
      />

      <LabelInfo
        label="Поставщик"
        info={supplierInfo ? supplierInfo.name : "-"}
        className="p-col-6 p-md-4"
      />

      <LabelInfo
        label="Тел. поставщика"
        info={supplierInfo ? supplierInfo.phones.join(", ") : "-"}
        className="p-col-6 p-md-4"
      />

      <LabelInfo
        label="ИНН поставщика"
        info={supplierInfo ? supplierInfo.vatin : "-"}
        className="-col-6 p-md-4"
      />
    </div>
  );
}

export default AgentData;
