import React from "react";
import { connect } from "react-redux";
import { EmailFieldControl } from "components/forms/fields";

function EmailField(props) {
  const { errors, disabled } = props;

  return (
    <EmailFieldControl
      className={props.className}
      name="email"
      control={props.formHook.control}
      errors={props.formHook.errors}
      label="E-mail:"
      required={false}
      responseError={errors?.email?.messages[0]}
      disabled={disabled}
    />
  );
}

const mapStateToProps = (state) => ({
  errors: state.users.responseError,
});
export default connect(mapStateToProps)(EmailField);
