import React from "react";
import { BasicLegalEntity, Director, Contacts } from "../../index";
import { Comment } from "../../../../../../components/fields";

function Legal(props) {
  return (
    <div className={props.className}>
      <div className="p-col-12 p-md-6 p-pl-md-0">
        <div className="p-col-12 ">
          <BasicLegalEntity className="p-p-3 c-box-shadow p-d-flex p-flex-wrap" />
        </div>

        <div className="p-col-12 ">
          <Contacts className="p-p-3 c-box-shadow p-d-flex p-flex-wrap" />
        </div>
      </div>

      <div className="p-col-12 p-md-6 p-pr-md-0">
        <div className="p-col-12 p-pl-md-0">
          <Director className="p-p-3 c-box-shadow p-d-flex p-flex-wrap" />
        </div>

        <Comment className="p-col-12 p-p-3" />
      </div>
    </div>
  );
}

export default Legal;
