import React from "react";
import Head from "./headTable";
import Body from "./bodyTable";

function Table(props) {
  return (
    <div className={props.className}>
      <Head className="p-d-none p-d-xl-flex p-col-12 p-jc-between" />
      <Body className="p-col-12 " />
    </div>
  );
}

export default Table;
