export default function getFilters(getState, reduce) {
  let filters = {
    sort: getState()[reduce].sort,
    range: getState()[reduce].range,
    filter: getState()[reduce].filter,
    like: { search: getState()[reduce].like, caseSensitive: false },
  };

  for (let key in filters) {
    if (filters[key] === null) delete filters[key];
  }

  return filters;
}
