import React from "react";

import moment from "moment";
import "moment/locale/ru";
import "moment-timezone";
import PrimeReact from "primereact/api";
import { locale, addLocale } from "primereact/api";
import { dataPickerSetting } from "utils/settings";

import Routes from "./routes/Routes";

import "./styles/App.css";
import "./styles/media.css";
import "./styles/customer.css";
import "./styles/animationSuccess.css";

import "primereact/resources/themes/saga-green/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.min.css";

import "swiper/swiper-bundle.min.css";
import "./styles/customSwiper.css";

// eslint-disable-next-line
import "react-dadata/dist/react-dadata.css";

PrimeReact.autoZIndex = {
  modal: 1100,
  overlay: 1000,
  menu: 1000,
  tooltip: 1100,
  toast: 1200,
};

moment.locale("ru");
addLocale("ru", dataPickerSetting);
locale("ru");

function App() {
  return <Routes />;
}
export default App;
