import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import orderBy from "lodash/orderBy";
import { ActiveSphere } from "components/based";

import styles from "../style.module.css";

function Chart(props) {
  const [coming, setComing] = useState([]);
  const [expenditure, setExpenditure] = useState([]);
  const [data, setData] = useState([]);
  const typeSort = props.isComing ? "moneyIn" : "moneyOut";
  // const sortData = orderBy(data, [typeSort], ["desc"]).slice(0, 6);

  useEffect(() => {
    if (!props.data) return;
    const users = props.data.users;
    const result = [];

    if (users.length) {
      users.forEach((i) => {
        if (i.moneyIn === 0 && i.moneyOut === 0) return;
        else {
          result.push(i);
        }
      });
    }

    setData(result);
  }, [props.data]);

  useEffect(() => {
    data.forEach((i) => {
      if (i.moneyIn !== 0) setComing((prev) => [...prev, i]);
      if (i.moneyOut !== 0) setExpenditure((prev) => [...prev, i]);
    });
  }, [data]);

  const user = (item) => {
    return (
      <span className={styles.ratingFioOperator}>
        {item.lastName} {item.firstName} {item.middleName}
      </span>
    );
  };

  const renderComing = orderBy(coming, [typeSort], ["desc"])
    .slice(0, 6)
    .map((i, index) => {
      return (
        <div key={index} className="p-col-6 p-p-1 p-d-flex">
          <ActiveSphere color="#148B11" />
          <div
            className="p-pl-1"
            style={{ marginTop: "-5px", marginBottom: "5px" }}
          >
            {user(i)}
            <br />
            <span className={styles.ratingAmountOperator}>
              {i.moneyIn} руб.
            </span>
          </div>
        </div>
      );
    });
  const renderExpenditure = expenditure.map((i, index) => {
    return (
      <div key={index} className="p-col-6 p-p-1 p-d-flex">
        <ActiveSphere color="#AF0000" />
        <div
          className="p-pl-1"
          style={{ marginTop: "-5px", marginBottom: "5px" }}
        >
          {user(i)}
          <br />
          <span className={styles.ratingAmountOperator}>{i.moneyOut} руб.</span>
        </div>
      </div>
    );
  });

  return (
    <div className={props.className}>
      <div className="p-col-12 p-p-o p-d-flex p-flex-wrap">
        {props.isComing ? renderComing : renderExpenditure}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.analytics.data,
});
export default connect(mapStateToProps)(Chart);
