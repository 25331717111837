import React from "react";

function RegistrationView({ className }) {
  return (
    <div
      className={`${className} p-d-flex p-flex-column p-jc-center p-pl-2 p-pr-2 p-pl-md-5 p-pr-md-5`}
    >
      <span className="">
        <strong>Держите свой бизнес под контролем!</strong>
      </span>

      <h1>
        <strong>ОБЛАЧНЫЕ КАССЫ</strong>
      </h1>

      <p>
        Кроссплатформенное решение, реализующее функции личного кабинета для
        осуществления коммерческой деятельности в части продажи товаров и услуг
        как в рамках торговой площадки, так и в режиме доставки.
      </p>
    </div>
  );
}

export default RegistrationView;
