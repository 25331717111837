import { SHOW_SIDEBAR } from "../actionTypes";

const initialState = {
  show: false,
};

export default function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_SIDEBAR:
      return {
        show: action.payload,
      };
    default:
      return state;
  }
}
