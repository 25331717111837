import React from "react";
import { connect } from "react-redux";
import { setLegalEntity } from "domain/actions/organizations/created";
import Label from "components/based/label";
import { Select } from "components/forms/fields";

function LegalEntity(props) {
  return (
    <div className={props.className}>
      <Label
        value="Форма организации"
        className={`p-col-12 p-md-6 p-xl-4 p-pr-2 p-pl-0`}
      />

      <Select
        options={props.legalEntity}
        className={`p-col-12 p-md-6 p-xl-8 p-p-0 `}
        value={props.value}
        onChange={(e) => props.setLegalEntity(e)}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  legalEntity: state.requisites.legalEntity,
  value: state.orgCreate.item.legalEntity,
});
export default connect(mapStateToProps, { setLegalEntity })(LegalEntity);
