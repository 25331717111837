import React from "react";

import Mobile from "./card.mobile";
import Desktop from "./card.desktop";
import { connect } from "react-redux";
import {
  getReceiptByIdSelect,
  showSendLinkReceipt,
} from "domain/actions/receipt/index";
import { useMediaQuery } from "react-responsive";

function CardComponent(props) {
  const { getReceiptByIdSelect, showSendLinkReceipt } = props;

  const isMobile = useMediaQuery({
    query: "(max-width: 576px)",
  });

  return (
    <React.Fragment>
      {isMobile && (
        <Mobile
          item={props.item}
          onPreview={props.onPreview}
          onSetReceipt={getReceiptByIdSelect}
          showSendLinkReceipt={showSendLinkReceipt}
        />
      )}
      {!isMobile && (
        <Desktop
          item={props.item}
          onPreview={props.onPreview}
          onSetReceipt={getReceiptByIdSelect}
          showSendLinkReceipt={showSendLinkReceipt}
        />
      )}
    </React.Fragment>
  );
}

export default connect(null, { getReceiptByIdSelect, showSendLinkReceipt })(
  CardComponent
);
