import React, { useState } from "react";

import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";

function DeleteButton(props) {
  const [visible, setVisible] = useState(false);

  const accept = () => {
    props.deleteAcquiring();
  };
  const reject = () => {
    setVisible(false);
  };

  return (
    <div className={props.className}>
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Вы действительно хотите удалить эквайринг?"
        header="Подтверждение"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />

      <Button
        label="Удалить"
        className="с-button-danger"
        onClick={() => setVisible(true)}
      />
    </div>
  );
}

export default DeleteButton;
