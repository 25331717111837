import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getWorkers, switchWorker } from "domain/actions/organizations/index";
import CancelButton from "components/btns/cancelBtn";
import { Button } from "primereact/button";
import { Select } from "components/forms/fields";
import { selectedEquipment } from "domain/actions/equipment";

function Layouts(props) {
  const {
    item,
    workers,
    getWorkers,
    switchWorker,
    onClose,
    selectedEquipment,
  } = props;
  const [worker, setWorker] = useState(null);

  useEffect(() => getWorkers(), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const _filter = workers.filter((r) => r.id === item?.worker?.id);
    if (_filter.length > 0) setWorker(_filter[0]);
  }, [item, workers]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSwitchWorker = () => {
    if (worker)
      switchWorker([item.id], worker.id).then(() => {
        item.worker = worker;
        selectedEquipment(item);
        onClose();
      });
  };
  // console.log(workers)
  return (
    <div className="p-col-12">
      <Select
        label="Рабочее место"
        options={workers}
        className={`p-col-12`}
        value={worker ? worker : item.worker}
        onChange={(e) => {
          setWorker(e);
        }}
      />

      <div className="p-col-12 p-d-flex p-flex-wrap p-p-0">
        <div className="p-col-12 p-md-6 p-order-1 p-order-md-0">
          <CancelButton className="p-col-12 p-p-0" onShow={onClose} />
        </div>

        <div className="p-col-12 p-md-6 p-order-0 p-order-md-1">
          <Button
            label="Применить"
            onClick={handleSwitchWorker}
            className="c-btns--gradient c-btns"
            style={{ height: "50px" }}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  item: state.equipments.select,
  workers: state.organizations.workers,
});
export default connect(mapStateToProps, {
  getWorkers,
  switchWorker,
  selectedEquipment,
})(Layouts);
