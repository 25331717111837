import React from "react";
import { Controller } from "react-hook-form";
import { getDefaultValue } from "../../../../controllers/getDefaultValue";

import { InputText } from "primereact/inputtext";

import styles from "../styles.module.css";

function AgentOperatorComponent({ control, className, defaultValue = "" }) {
  return (
    <div className={`${styles.form} ${className}`}>
      <div className="p-p-0">
        <div className="p-col-12 p-d-flex p-flex-column">
          <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
            Телефоны
          </label>

          <Controller
            name="agentInfo.receivePaymentsOperator.phones"
            control={control}
            render={(props) => (
              <InputText
                onChange={(e) => props.onChange(e)}
                className={`${styles.borderBottom}`}
                keyfilter={/^[^a-zA-Zа-яА-Я<>*!]+$/}
                defaultValue={getDefaultValue(defaultValue, "phones")}
              />
            )}
            defaultValue={getDefaultValue(defaultValue, "phones")}
          />
        </div>
      </div>
    </div>
  );
}

export default AgentOperatorComponent;
