import React, { useState } from "react";
import { ListCashbox } from "../index";

import { Column } from "components/dataView";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";

import ColumnTypeToString from "controllers/ColumnTypeToString";
import { ExpansionIcon, ExpansionItem } from "components/static";

function Card(props) {
  const [show, setShow] = useState(false);

  const TypeToString = new ColumnTypeToString();
  const item = props.item;

  return (
    <div className="p-d-flex p-flex-wrap p-pt-4 p-pb-4 c-card-bb">
      <div className="p-col-7 p-md-2 p-order-0 p-p-1 p-as-center p-d-flex">
        <ExpansionIcon active={show} setActive={setShow} />
        <Column
          className="p-p-1 p-as-center p-ml-1"
          classTitle="p-d-none"
          classBody="c-color-green p-text-left"
          title="Номер заявки"
          value={`№${item.id}`}
        />
      </div>

      <Column
        className="p-col-6 p-sm-3 p-md-2 p-p-1 p-as-center p-order-2 p-order-md-1"
        classTitle="p-d-block p-d-md-none"
        title="Заявитель"
        value={item.responsible}
      />

      <Column
        className="p-col-6 p-sm-3 p-md-2 p-p-1 p-as-center p-order-3 p-order-md-2"
        classTitle="p-d-block p-d-md-none"
        title="Организация"
        value={
          item.cashboxes.length
            ? item.cashboxes[0].organization
              ? item.cashboxes[0].organization.name
              : "Не указана!"
            : "Не указана!"
        }
      />

      <Column
        className="p-col-6 p-sm-3 p-md-2 p-p-1 p-as-center p-order-4 p-order-md-3"
        classTitle="p-d-block p-d-md-none"
        title="Статус"
        value={TypeToString.toStatusBid(item.status)}
      />

      <Column
        className="p-col-6 p-sm-3 p-md-2 p-p-1 p-as-center p-order-5 p-order-md-4"
        classTitle="p-d-block p-d-md-none"
        title="Дата создания"
        value={TypeToString.toDate(item.createdAt)}
      />

      <div className="p-col-5 p-md-2 p-d-flex p-p-1 p-order-1 p-order-md-5 p-jc-center">
        <EditIcon
          style={{ height: "2rem", width: "2rem" }}
          className={`p-col-12 p-p-1 c-hover-cursor ${
            item.cashboxes[0].address === "virtual"
              ? "c-disabled"
              : item.status === 11
              ? "p-d-block"
              : "p-d-none"
          } `}
          onClick={props.onPreview}
        />
        <InfoIcon
          style={{ height: "2rem", width: "2rem" }}
          className={`p-col-12 p-p-1 c-hover-cursor ${
            item.cashboxes[0].address === "virtual"
              ? "c-disabled"
              : item.status === 11
              ? "p-d-none"
              : "p-d-block"
          } `}
          onClick={props.onPreview}
        />
      </div>

      <ExpansionItem
        className="p-col-12 p-order-6 p-p-0"
        active={show}
        withLine
      >
        <ListCashbox item={item} />
      </ExpansionItem>
    </div>
  );
}

export default Card;
