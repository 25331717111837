import {
  SORT_USERS,
  FILTER_USERS,
  RANGE_USERS,
  SET_LIKE_USERS,
  REFRESH_USERS,
} from "domain/types/users";

export function setSortsUsers(...params) {
  return async function thunk(dispatch, getState) {
    if (!Object.keys(params[0]).length) params = null;

    dispatch({
      type: SORT_USERS,
      payload: params ? params[0] : params,
    });

    refreshThunk(dispatch, getState);
  };
}
export function setFiltersUsers(params) {
  return async function thunk(dispatch, getState) {
    if (!Object.keys(params).length) params = null;

    dispatch({
      type: FILTER_USERS,
      payload: params,
    });

    refreshThunk(dispatch, getState);
  };
}
export function setRangeUsers(...data) {
  return async function thunk(dispatch, getState) {
    const filters = { ...getState().receiptsFilter.range };

    let params = {};

    if (data[1]) {
      params[data[0]] = {
        from: data[1][0],
        to: data[1][1],
      };
    }

    if (!Object.keys(params).length) params = null;

    for (let key in filters) {
      if (key === data[0] && params !== null) {
        filters[key] = params[key];
      }
    }

    Object.assign(params ? params : {}, filters);

    dispatch({
      type: RANGE_USERS,
      payload: params,
    });

    refreshThunk(dispatch, getState);
  };
}

export function setLike(value) {
  return async function thunk(dispatch, getState) {
    dispatch({
      type: SET_LIKE_USERS,
      payload: value,
    });

    refreshThunk(dispatch, getState);
  };
}

async function refreshThunk(dispatch, getState) {
  let refresh = getState().users.refresh;

  dispatch({
    type: REFRESH_USERS,
    payload: !refresh,
  });

  return true;
}
