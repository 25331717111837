import React from "react";

import { Button } from "primereact/button";

import styles from "../../../../../style.module.css";

function ConfirmButton(props) {
  const onConfirm = () => props.confirm();

  return (
    <div className="p-col-12 p-d-flex p-jc-end">
      <div className={`p-col-12 p-p-0 ${styles.buttons}`}>
        <Button
          label="Продолжить работу"
          className="p-col-12 p-p-0 с-button-success"
          type="confirm"
          onClick={onConfirm}
        />
      </div>
    </div>
  );
}

export default ConfirmButton;
