import React, { useEffect, useState } from "react";
import styles from "../index.module.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { InputText } from "primereact/inputtext";

function TextField({
  className = "",
  name = "",
  label = "",
  value = "",
  keyfilter = null,
  placeholder = "",
  disabled = false,
  tooltip = null,
  required = false,
  onValidate = () => null,
  onChange = () => console.log("onChange"),
  onBlur = () => console.log("onBlur"),
  validate = null,
  reqStar = false,
}) {
  const [valid, setValid] = useState(true);
  if (value === null) value = "";

  useEffect(() => {
    if (required && !value.length) return;
    onValidate(false);
  }, [required, value, onValidate]);

  useEffect(() => {
    if (validate && required) setValid(validate.onValid(value));
  }, [validate, value, required]);

  return (
    <div className={className}>
      <label className={`${styles.label} p-pb-1`}>
        {label} {reqStar && <span style={{ color: "red" }}>*</span>}
      </label>
      <InputText
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        className={`${styles.borderBottom}`}
        keyfilter={keyfilter}
        placeholder={placeholder}
        disabled={disabled}
        tooltip={tooltip}
      />
      {required && !value.length && (
        <small className="p-error p-d-block">Обязательно для заполнения!</small>
      )}
      {required && !valid && !!value.length && validate && (
        <small className="p-error p-d-block">{validate.message}</small>
      )}
    </div>
  );
}

function TextFieldControl({
  control,
  errors,
  className = "",
  name = "",
  label = "",
  keyfilter = null,
  defaultValue = "",
  required = false,
  validate = null,
  tooltip = null,
  responseError = null,
  disabled = false,
}) {
  let rules = {};

  if (required) {
    rules.required = "Обязательно для заполнения!";
  }
  if (validate) {
    rules.validate = validate;
  }

  return (
    <div className={className}>
      <label className={`${styles.label} p-pb-1`}>
        {label} {required && <span style={{ color: "red" }}>*</span>}{" "}
      </label>
      <Controller
        name={name}
        control={control}
        render={(props) => (
          <InputText
            value={props.value}
            onChange={(e) => props.onChange(e)}
            className={`${styles.borderBottom}`}
            keyfilter={keyfilter}
            tooltipOptions={{ position: "bottom" }}
            tooltip={tooltip}
            disabled={disabled}
          />
        )}
        rules={rules}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        as={<p className="errorText">message</p>}
      />
      {responseError && (
        <small className="p-error p-d-block">{`${responseError}`}</small>
      )}
    </div>
  );
}

export { TextField, TextFieldControl };
