import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Message } from 'primereact/message';

function CertId(props) {
  const [cert, setCert] = useState(false);

  useEffect(() => {
    if (props.select?.extParams)
    setCert(!!props.select.extParams.certId);
  }, [props.select]);

  return (
    <>
      {cert 
        ? <Message 
            className="p-col-12"
            severity="info" 
            text="Сертификат загружен"
            closable={false}
          />
        : <Message 
            className="p-col-12"
            severity="warn" 
            text="Отсутствует сертификат эквайринга"
            closable={false}
          />
      }
    </>
  );
}

const mapStateToProps = (state) => ({
  select: state.orgAcquiring.select,
});

export default connect(mapStateToProps, null)(CertId);
