import React from "react";

import styles from "../../../../index.module.css";

function TitleBlock({ id, name, className = "" }) {
  return (
    <h1 id={id} className={`${styles.titleBlock} ${className}`}>
      {name.toUpperCase()}
    </h1>
  );
}

export default TitleBlock;
