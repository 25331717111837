import { isINN } from "utils/rus.tax.codes.util";

const getData = (getState, isUser = false) => {
  const user = { ...getState().users.current };
  const bid = { ...getState().bidEquipments };
  const list = getState().bidEquipmentsCashbox.list.slice();

  const valid = isValidatedItem(list, bid.isLocal.id === 1, bid?.tariffId);

  if (!valid.isValid) return valid;

  const data = {
    externId: bid.externId,
    isLocal: bid.isLocal.id === 1 ? true : false,
    organizationId: +bid.organizationId,
    // userId: user ? user.id : null,
    tariffId: bid?.tariffId,
    cashboxes: formationList(list, bid.isLocal.id === 1),
  };

  if (isUser) data.userId = user ? user.id : null;

  if (!data.isLocal) data.amount = data.cashboxes[0].amount;

  return data;
};

function formationList(data, location) {
  let result = data.slice();
  result.forEach((item) => {
    const ofd = { ...item.ofd };

    if (!location) item = setJsonData(item, ofd);

    delete item.id;
  });

  return result;
}

function isValidatedItem(items, location, tariff) {
  if (!items.length)
    return {
      isValid: false,
      error: "Список касс пуст",
    };

  if (!tariff)
    return {
      isValid: false,
      error: "Не выбран тариф",
    };

  if (location) {
    let result = null;
    items.forEach((i) => {
      result = isValidClient(i);
    });
    return result;
  }
  if (!location) {
    let result = null;
    items.forEach((i) => {
      result = isValidProvider(i);
    });
    return result;
  }
}

function isValidClient(item) {
  console.log(item);
  let result = {
    isValid: false,
    error: "",
  };

  if (!item.cashboxModelId) {
    result.error = "Не выбрана модель кассы";
    return result;
  }

  if (item.factoryNumber === "") {
    result.error = "Не заполнен 'Серийный номер'";
    return result;
  }

  if (!item.fnTypeId) {
    result.error = "Не выбран срок действия ФН";
    return result;
  }

  // if (!item.connect.os) {
  //   result.error = "Не выбрана операционная система";
  //   return result;
  // }

  if (!item.connect.interface) {
    result.error = "Не выбран интерфейс подключения";
    return result;
  }

  if (item.connect.address === "") {
    result.error = "Не указан адрес подключения";
    return result;
  }

  result.isValid = true;
  return result;
}

function isValidProvider(item) {
  let result = {
    isValid: false,
    error: "",
  };

  if (Number(item.amount) < 1) {
    result.error = "Кол-во касс не должно быть меньше нуля";
    return result;
  }

  if (!item.ofdId) {
    result.error = "Не выбрано ОФД";
    return result;
  }

  if (item.ofdId && item.ofdId.id === 2) {
    if (item.ofd.name === "") {
      result.error = "Не указано имя для ОФД";
      return result;
    }
    if (item.ofd.inn === "") {
      result.error = "Не указан ИНН для ОФД";
      return result;
    }
    if (!isINN(item.ofd.inn)) {
      result.error = "Указанный ИНН для ОФД введен не верно";
      return result;
    }

    if (item.ofd.url === "") {
      result.error = "Не указан адрес(url) для ОФД";
      return result;
    }
  }

  if (!item.fnTypeId) {
    result.error = "Не выбран срок действия ФН";
    return result;
  }

  if (!item.ffdId) {
    result.error = "Не выбран формат фискальных данных";
    return result;
  }

  if (!item.snoListId || item.snoListId.length === 0) {
    result.error = "Не установлен список СНО";
    return result;
  }

  if (!item.defaultSnoId) {
    result.error = "Не выбрано СНО по умолчанию";
    return result;
  }

  result.isValid = true;
  return result;
}

function setJsonData(item, ofd) {
  if (item.ofdId === 2)
    item.jsonData = Object.assign(!!item.jsonData ? item.jsonData : {}, {
      ofd: ofd,
    });

  // if (item.jsonData) item.jsonData = JSON.stringify(item.jsonData);
  // if (item.jsonData) item.jsonData = item.jsonData;
  delete item.ofd;

  return item;
}
// function objInId(item) {
//   for (let key in item) {
//     if (key !== "connect") {
//       if (key === "agentTypesId" || key === "snoListId") {
//         item[key] = item[key].map((i) => i.id);
//       } else if (typeof item[key] === "object" && item[key] !== null)
//         item[key] = item[key].id;
//     } else {
//       item[key].interface = item[key].interface.name;
//     }
//   }

//   return item;
// }

export default getData;
