import React, { useState } from "react";
import { connect } from "react-redux";
import { setProp } from "domain/actions/equipment/bid";
import { CheckboxField } from "components/forms/fields";

import styles from "../../style.module.css";

function PrintOnPaper(props) {
  const [updateKey, forceUpdate] = useState(1);
  const isActive = props.selected ? props.selected.status !== 11 : false;
  return (
    <CheckboxField
      key={updateKey}
      label="Печать документов на бумаге"
      className={`${styles.printOnPaper} ${props.className}`}
      checked={props.item.needPrintOnPaper}
      onChange={(e) => {
        props.setProp("needPrintOnPaper", e.checked, props.item.id);
        forceUpdate(updateKey + 1);
      }}
      disabled={isActive}
    />
  );
}

const mapStateToProps = (state) => ({
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { setProp })(PrintOnPaper);
