import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PersonIcon from "@material-ui/icons/Person";
import { useAuth } from "components/auth/hooks";

const style = {
  color: "#000",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "bold",
  fontSize: "14px",
  lineHeight: "16px",
  textAlign: "right",
  cursor: "pointer",
};

function EntityAuth({ className, auth }) {
  const { onAuth } = useAuth();

  const handleAuth = async () => await onAuth();

  const icon = <PersonIcon className="p-pr-2" fontSize="large" />;

  const authorization = (
    <span
      onClick={handleAuth}
      style={style}
      className="p-d-flex p-ai-center p-jc-between"
    >
      {icon}
      <span>Авторизация</span>
    </span>
  );

  const entityInCabinet = (
    <Link
      to="/main/analytics"
      style={style}
      className="p-d-flex p-ai-center p-jc-between"
    >
      {icon}
      <span>Личный кабинет</span>
    </Link>
  );

  return (
    <div className={className}>{auth ? entityInCabinet : authorization}</div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, null)(EntityAuth);
