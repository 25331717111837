import React from "react";
import { Column } from "components/dataView";

import ColumnTypeToString from "controllers/ColumnTypeToString";
import { connect } from "react-redux";
import {
  getLinkConnect,
  deleteConnect,
} from "domain/actions/organizations/acquiring";

import { ActionsColumn } from "components/static";

function ConnectionsTable(props) {
  const {
    accepts = [],
    cashboxes = [],
    acquires = [],
    acceptLicenses = [],
    getLinkConnect,
    deleteConnect,
    setItem,
    refresh,
  } = props;
  const TypeToString = new ColumnTypeToString();

  const handleCopy = (token) => navigator.clipboard.writeText(token);
  const handleCopyLink = (item) => {
    getLinkConnect(item.id).then((r) => navigator.clipboard.writeText(r.link));
  };
  const handleDelete = (item) => {
    deleteConnect(item.id).then(() => refresh());
  };

  const renderedListItems = accepts.map((item) => {
    const cashbox = cashboxes.filter(
      (c) => c.id === item?.bindParams?.defaultCashboxId
    );

    const acquiring = acquires.filter(
      (a) => a.id === item?.bindParams?.defaultOrgAcquiringId
    );

    const license =
      item?.bindParams && item?.bindParams?.licenseId
        ? acceptLicenses[item.bindParams.licenseId]
        : null;

    const status = cashbox.length
      ? TypeToString.toStatusEquipment(cashbox[0].status)
      : null;

    const active = TypeToString.toActiveConnect(
      item.isActive,
      item?.bindParams
    );

    const cash = cashbox.length ? (
      <>
        {cashbox[0].name}
        <i
          className="pi pi-circle-on p-ml-1"
          style={{
            fontSize: ".75rem",
            color:
              status === 2 ? "#c1c1c1" : status === 1 ? "#fb8d05" : "#4CAF50",
          }}
        ></i>
      </>
    ) : null;

    const name = `${item.name ? item.name : "-"} `;

    let menu = (item) => [
      {
        label: "Скопировать ссылку",
        icon: "pi pi-fw pi-copy",
        command: () => handleCopyLink(item),
      },
      {
        label: "Скопировать токен",
        icon: "pi pi-fw pi-copy",
        command: () => handleCopy(item.deviceToken),
      },
      {
        label: "Редактирование",
        icon: "pi pi-fw pi-info-circle",
        command: () => setItem(item),
      },
      {
        label: "Удаление",
        icon: "pi pi-fw pi-trash",
        command: () => handleDelete(item),
      },
    ];

    return (
      <div
        className="p-d-flex p-flex-wrap p-pt-4 p-pb-4 c-card-bb"
        key={item.id}
      >
        <div className="p-col-12 p-md-8 p-lg-4 p-order-0 p-p-1 p-as-center p-d-flex">
          <Column
            className="p-as-start"
            classTitle="p-d-none"
            classBody="c-color-green p-text-left"
            value={name}
          />
        </div>

        <Column
          className="p-col-6 p-md-4 p-lg-2 p-p-1 p-ai-end p-ai-md-center p-as-center p-order-5 p-order-md-1 p-order-lg-3"
          title="Статус"
          value={active}
        />

        <Column
          className="p-col-6 p-md-4 p-lg-2 p-p-1 p-ai-start p-ai-md-center p-as-center p-order-2 p-order-md-4 p-order-lg-1"
          title="Касса"
          value={cashbox.length ? cash : "-"}
        />

        <Column
          className="p-col-6 p-md-4 p-lg-2 p-p-1 p-ai-start p-ai-md-center p-as-center p-order-5 p-order-md-5 p-order-lg-2"
          title="Эквайринг"
          value={acquiring.length ? acquiring[0].acquiring?.name : "-"}
        />

        <Column
          className="p-col-6 p-md-4 p-lg-1 p-p-1 p-ai-end p-ai-md-center p-as-center p-order-5 p-order-md-1 p-order-lg-3"
          title="Действие лицензии"
          value={TypeToString.toDateColor(license?.activeTo)}
        />

        <div className="p-col-6 p-md-4 p-lg-1 p-p-1 p-ai-start p-ai-md-center p-as-center p-order-6 p-d-flex">
          <ActionsColumn
            menuItems={menu(item)}
            element={item}
            setElement={() => {}}
          />
        </div>
      </div>
    );
  });

  return <div className={props.className}>{renderedListItems}</div>;
}

export default connect(null, { getLinkConnect, deleteConnect })(
  ConnectionsTable
);
