import {
  SET_SUPPLIER_EDIT_CREATERECEIPT,
  SET_AGENTS_EDIT_CREATERECEIPT,
  SET_CODES_EDIT_CREATERECEIPT,
  SET_DEFAULT_TAXATION_EDIT_CREATERECEIPTS,
  RETURN_DEFAULT_TAXATION_EDIT_CREATERECEIPTS,
} from "./editItem";

const SET_PAYSYSTEM_CREATERECEIPTS = "SET_PAYSYSTEM_CREATERECEIPTS";
const SET_ISONLINEPAY_CREATERECEIPTS = "SET_ISONLINEPAY_CREATERECEIPTS";
const ACQUIRING_PAYMENTS_CREATERECEIPTS = "ACQUIRING_PAYMENTS_CREATERECEIPTS";

const CREATE_RECEIPT_SHOW_BILLING = "CREATE_RECEIPT_SHOW_BILLING";
const CREATE_RECEIPT_SHOW_BILLING_ERROR = "CREATE_RECEIPT_SHOW_BILLING_ERROR";

export {
  SET_SUPPLIER_EDIT_CREATERECEIPT,
  SET_AGENTS_EDIT_CREATERECEIPT,
  SET_CODES_EDIT_CREATERECEIPT,
  SET_DEFAULT_TAXATION_EDIT_CREATERECEIPTS,
  RETURN_DEFAULT_TAXATION_EDIT_CREATERECEIPTS,
  SET_PAYSYSTEM_CREATERECEIPTS,
  SET_ISONLINEPAY_CREATERECEIPTS,
  ACQUIRING_PAYMENTS_CREATERECEIPTS,
  CREATE_RECEIPT_SHOW_BILLING,
  CREATE_RECEIPT_SHOW_BILLING_ERROR,
};
