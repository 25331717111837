import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getWorkers,
  setSelectedWorker,
} from "domain/actions/organizations/index";
import { Menu } from "components/static";
import { ActionsColumn } from "components/static";
// import AddIcon from "@material-ui/icons/Add";
import { Button } from "primereact/button";
import RefreshIcon from "@material-ui/icons/Refresh";
import ColumnTypeToString from "controllers/ColumnTypeToString";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function ListWorkers(props) {
  const { workers, getWorkers, onPreview, onAdding } = props;

  const TypeToString = new ColumnTypeToString();

  useEffect(() => {
    getWorkers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const refresh = () => getWorkers();

  let menu = (item) => [
    {
      label: "Просмотр",
      icon: "pi pi-fw pi-info-circle",
      command: () => onPreview(item),
    },
  ];

  const action = (rowData) => (
    <ActionsColumn
      menuItems={menu(rowData)}
      element={rowData}
      setElement={() => {}}
    />
  );

  return (
    <div className={props.className}>
      <div className="p-col-12">
        <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-ai-baseline">
          <div className="p-p-1">
            <Button
              label="Добавить"
              onClick={onAdding}
              className="button-primary-out"
            />
          </div>
          <Menu
            list={[
              {
                id: "2",
                name: "Обновить страницу",
                icon: RefreshIcon,
                callback: () => refresh(),
              },
            ]}
            className="p-pl-3 p-pt-0 p-pb-0 p-pr-0 p-d-flex p-as-center"
          />
        </div>

        <DataTable
          value={workers}
          paginator
          rows={15}
          dataKey="id"
          responsiveLayout="scroll"
        >
          <Column field="name" header="Рабочее место" sortable></Column>
          <Column
            field="isActive"
            header=""
            sortable
            sortField="isActive"
            style={{ width: "5rem" }}
            body={(rowData) => TypeToString.toActive(rowData.isActive)}
          ></Column>
          <Column body={action} style={{ width: "5rem" }}></Column>
        </DataTable>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  workers: state.organizations.workers,
});

export default connect(mapStateToProps, {
  getWorkers,
  setSelectedWorker,
})(ListWorkers);
