import React from "react";
import { HTable } from "components/based";

function HeadTable(props) {
  return (
    <div className={props.className}>
      <HTable className="p-col-3 p-pl-0 p-text-start" value="Тип ОФД" />

      <HTable className="p-col-1 p-text-start" value="Кол-во" />
      <HTable className="p-col-4 p-text-start" value="СНО(по умолчанию)" />

      <HTable className="p-col-4 p-text-start" value="Комментарий" />
    </div>
  );
}

export default HeadTable;
