import React, { useEffect } from "react";
import { connect } from "react-redux";
import { handleShowWindow } from "domain/actions/users";
import Window from "components/windows";
import Content from "./content";

function WindowUser(props) {
  const title = props.select
    ? "Редактирование пользователя"
    : "Добавление пользователя";
  const handleShowWindow = props.handleShowWindow;

  useEffect(() => !!props.select && handleShowWindow(true), [
    props.select,
    handleShowWindow,
  ]);

  return (
    <Window
      header={title}
      visible={props.show}
      onHide={() => handleShowWindow(false)}
      className={props.className}
      draggable={false}
    >
      <Content />
    </Window>
  );
}

const mapStateToProps = (state) => ({
  show: state.users.show,
  select: state.users.select,
});
export default connect(mapStateToProps, { handleShowWindow })(WindowUser);
