import React from "react";
import {
  TypePaymentSystem,
  TokenPayment,
  PaymentCallback,
  ShopIdOrLogin,
  SecretOrPassword,
  PaymentComment,
} from "../../../../components/fields";
import DeleteBtn from "./deleteBtn";

function Acquiring(props) {
  return (
    <div className={props.className}>
      <TypePaymentSystem
        className="p-col-12 p-md-4 p-xl-3"
        value={props.item.acquiringId}
        index={props.index}
      />
      <TokenPayment
        className="p-col-12 p-md-7 p-xl-8"
        value={props.item.paymentToken}
        index={props.index}
      />

      <DeleteBtn
        className="p-col-4 p-md-1 p-d-flex p-jc-center p-ai-end"
        index={props.index}
      />

      <PaymentCallback
        className="p-col-12 p-md-4"
        value={props.item.paymentCallback}
        index={props.index}
      />
      <ShopIdOrLogin
        className="p-col-12 p-md-4"
        value={props.item.paymentLogin}
        index={props.index}
      />
      <SecretOrPassword
        className="p-col-12 p-md-4"
        value={props.item.paymentPassword}
        index={props.index}
      />
      <PaymentComment
        className="p-col-12"
        value={props.item.comment}
        index={props.index}
      />
    </div>
  );
}

export default Acquiring;
