import React, { useState } from "react";
import ListWorkers from "./list";
import AddWorker from "./add.window";
import EditWorker from "./edit.window";

function Workers(props) {
  const { className } = props;
  const [current, setCurrent] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const handlePreview = (item) => {
    setCurrent(item);
    setShowEdit(true);
  };

  const handleAdding = () => setShowAdd(true);

  return (
    <>
      <div className={className}>
        <h4>Рабочие места "Workers"</h4>
        <ListWorkers onPreview={handlePreview} onAdding={handleAdding} />
      </div>

      <AddWorker show={showAdd} setShow={setShowAdd} />
      <EditWorker show={showEdit} setShow={setShowEdit} worker={current} />
    </>
  );
}

export default Workers;
