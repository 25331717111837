import { PATHNAME_ROUTE } from "../actionTypes";
import { AMSController } from "domain/controllers";

const AMS = new AMSController();

export function setPathname(pathname) {
  return async function registerThunk(dispatch, getState) {
    dispatch({
      type: PATHNAME_ROUTE,
      payload: pathname,
    });
  };
}

export function getServices() {
  return async function registerThunk(dispatch, getState) {
    const services = await AMS.get("/services").then((r) => r.data.data);
    return await AMS.get("/services/me").then((response) => {
      const meServices = response.data;
      const array = [];
      meServices.forEach((res) => {
        const service = services.filter((s) => s.id === res);
        if (service.length > 0 && service[0].id !== 1) array.push(service[0]);
      });
      return array;
    });
  };
}

export function getServicesAll() {
  return async function registerThunk(dispatch, getState) {
    return await AMS.get("/services").then((r) => r.data.data);
  };
}
