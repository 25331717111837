import React from "react";
import Line from "components/Line";

const style = {
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "16px",
  lineHeight: "19px",
};

function Slide_2({ className }) {
  return (
    <div className={`${className} p-col-12 p-p-0 p-d-flex`}>
      <div className="p-col-12 p-md-6 p-d-flex p-flex-column p-jc-center">
        <div className="p-col-12 p-pt-3 p-pb-3">
          <p className="p-m-0" style={style}>
            Мониторинг <strong>работы кассового оборудования</strong>,
            управление приоритетами задач
          </p>
          <Line height={3} />
        </div>

        <div className="p-col-12 p-pt-3 p-pb-3">
          <p className="p-m-0" style={style}>
            <strong>Незамедлительная</strong> и <strong>отложенная</strong>{" "}
            печать чека
          </p>
          <Line height={3} />
        </div>

        <div className="p-col-12 p-pt-3 p-pb-3">
          <p className="p-m-0" style={style}>
            Привязка кассы к{" "}
            <strong>автономному и внешнему защищенному сервису</strong>
          </p>
          <Line height={3} />
        </div>
      </div>
      <div
        className="p-d-none p-d-md-flex p-flex-column p-jc-center"
        style={{ margin: "2rem auto" }}
      >
        <img
          src={process.env.PUBLIC_URL + "/img/greeting/slide_2.jpg"}
          alt="Для кого мы стараемся?"
          className="p-col-12 p-pr-3"
        />
      </div>
    </div>
  );
}

export default Slide_2;
