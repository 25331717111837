import axios from "axios";

export class DadataController {
  constructor() {
    const env = process.env;

    this.token = env.REACT_APP_KLADR_KEY;
  }

  getUrlSuggestions() {
    return "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";
  }

  getHeaders(baseURL) {
    let headers = {
      timeout: 10000,
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Token " + this.token,
      },
      baseURL: baseURL,
    };

    return headers;
  }

  async getOrganizationByINN(inn) {
    const baseURL = this.getUrlSuggestions();
    return await axios
      .post("", { query: String(inn) }, this.getHeaders(baseURL))
      .then((response) => response.data);
  }
}
