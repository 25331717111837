import React from "react";
import { connect } from "react-redux";
import { handleShowWindow } from "domain/actions/equipment";
import Content from "./layouts";
import Window from "components/windows";

import Header from "./layouts/components/headerWin";

function CashboxWindow(props) {
  const close = props.handleShowWindow;

  return (
    <Window
      className={props.className}
      header={<Header />}
      visible={props.show}
      onHide={close}
      draggable={false}
    >
      <Content className="p-col-12 p-p-0" />
    </Window>
  );
}

const mapStateToProps = (state) => ({
  show: state.equipments.show,
  item: state.equipments.select,
});
export default connect(mapStateToProps, { handleShowWindow })(CashboxWindow);
