import React from "react";
import { connect } from "react-redux";
import {
  Comment,
  ConfirmButton,
  BindCashboxBtn,
  DeleteButton,
  Login,
  Password,
  Token,
  TypeAcquiring,
  UrlCallback,
  Tid,
  CertKey,
  Cert,
  FirstComponent,
  MerchantName,
  Merchant,
  SBPId,
  SbpAccountNumber,
  SBPMerchant,
  SecondComponent,
  Terminal,
  CertId,
  TimeLifePayment,
  RedirectSuccessfulUrl,
  RedirectFailFulUrl
} from "./components";

function ContentEdit(props) {
  return (
    <div className={props.className}>
      <TypeAcquiring className="p-col-12" />

      <>
        {props.select?.acquiring?.name === "Alfabank" && (
          <div className="text-center p-col-12 p-p-0">
            Данная форма для создания эквайринга, если ссылка <span style={{color: 'green'}}>payment.alfabank.ru</span>
          </div>
        )}
        {props.select?.acquiring?.name === "AlfabankAlt" && (
          <div className="text-center p-col-12 p-p-0">
            Данная форма для создания эквайринга, если ссылка <span style={{color: 'green'}}>pay.alfabank.ru</span>
          </div>
        )}
      </>

      {(props.select?.acquiring?.name !== "Alfabank" && 
        props.select?.acquiring?.name !== "AlfabankAlt" 
      ) && (
        <>
          <Token className="p-col-12" />
          <UrlCallback className="p-col-12" />
        </>
      )}

      <Login className="p-col-12 p-md-6" />
      <Password
        className="p-col-12 p-md-6"
        name={
          (props.select?.acquiring?.name === "Alfabank" || 
          props.select?.acquiring?.name === "AlfabankAlt") ? "Пароль" : undefined
        }
      />

      {(
        props.select?.acquiring?.name === "Alfabank" || 
        props.select?.acquiring?.name === "AlfabankAlt"
      )  && (
        <>
          <RedirectSuccessfulUrl className="p-col-12"/>
          <RedirectFailFulUrl className="p-col-12"/>
          <TimeLifePayment className="p-col-12" />
        </>
      )}

      {props.select?.acquiring?.name === "SberQR" && (
        <>
          <Tid className="p-col-12 p-md-4" />
          <CertKey className="p-col-12 p-md-8" />
          <CertId className="p-col-12" />
          <Cert className="p-col-12" />
        </>
      )}

      {props.select?.acquiring?.name === "PSB" && (
        <>
          <SBPId className="p-col-12 p-md-4" />
          <Terminal className="p-col-12 p-md-8" />
          <Merchant className="p-col-12 p-md-6 p-xl-4" />
          <MerchantName className="p-col-12 p-md-6 p-xl-4" />
          <SBPMerchant className="p-col-12 p-md-6 p-xl-4" />
          <SbpAccountNumber className="p-col-12 p-md-6 p-xl-4" />
          <FirstComponent className="p-col-12 p-md-6 p-xl-4" />
          <SecondComponent className="p-col-12 p-md-6 p-xl-4" />
        </>
      )}

      <BindCashboxBtn />

      <Comment className="p-col-12" />

      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-end p-p-0">
        <DeleteButton className="p-col-12 p-md-6 " />
        <ConfirmButton className="p-col-12 p-md-6" />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  select: state.orgAcquiring.select,
});
export default connect(mapStateToProps)(ContentEdit);
