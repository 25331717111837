import React from "react";
import { connect } from "react-redux";
import { setAcquiring } from "domain/actions/organizations/created";
import { Button } from "primereact/button";

function AddAcquiring(props) {
  const confirm = () => {
    if (props.select) return props.onShow(true);

    props.setAcquiring();
  };

  return (
    <div className={props.className}>
      <Button
        label="Добавить эквайринг"
        className="c-btns--gradient"
        onClick={confirm}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  select: state.orgCreate.select,
});
export default connect(mapStateToProps, { setAcquiring })(AddAcquiring);
