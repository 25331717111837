import React from "react";
import styles from "../style.module.css";

function Number(props) {
  return (
    <div className={`${props.className} ${styles.Numerics}`}>
      <span>{`№${props.value}`}</span>
    </div>
  );
}

export default Number;
