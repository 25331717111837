import React, { useState, useEffect } from "react";
import Table from "./chart";
import { setFilterCashbox } from "domain/actions/analytics";

import { SpinnerWrapper } from "components/wrappers";
import { connect } from "react-redux";

function CashMonitoring(props) {
  const { setFilterCashbox, filters, progress } = props;
  const [keyUpdate, setKeyUpdate] = useState(0);
  const [emptyText, setEmptyText] = useState("Нет активных касс");

  const handleFilter = (status = -1) => {
    const filter = status >= 0 ? { status: [status] } : null;
    setEmptyText((prev) => {
      if (status === 0) return "Нет активных касс";
      if (status === 1) return "Нет выключенных касс";
      if (status === 2) return "Нет заблокированных касс";
    });
    setFilterCashbox(filter);
  };

  useEffect(() => setKeyUpdate((prev) => prev + 1), [filters]);

  const renderContent = (
    <>
      <div className="p-col-12 p-pr-0 p-pl-0">
        <span className="p-col-12 p-pl-0 p-d-block block-label-title title-silver">
          Показатели состояния касс
        </span>

        <div className="p-col-12 p-p-0 p-d-flex">
          <div
            className="p-col-4 block-color-success c-hover-cursor"
            onClick={() => handleFilter(0)}
          >
            <span>Активна</span>
          </div>
          <div
            className="p-col-4 block-color-danger c-hover-cursor"
            onClick={() => handleFilter(1)}
          >
            <span>Выключена</span>
          </div>
          <div
            className="p-col-4 block-color-freeze c-hover-cursor"
            onClick={() => handleFilter(2)}
          >
            <span>Заблокирована</span>
          </div>
        </div>
      </div>

      <div key={keyUpdate} className="p-col-12 p-pr-0 p-pl-0">
        <Table className="p-col-12 p-pr-0 p-pl-0" emptyText={emptyText} />
      </div>
    </>
  );

  return (
    <div className={props.className}>
      <div className="p-col-12 p-p-3 c-box-shadow">
        <div className="analytics-title">Мониторинг касс</div>

        <SpinnerWrapper position="center" progress={progress}>
          <div
            className="p-col-12 p-p-0 p-d-flex p-flex-wrap"
            style={{ minHeight: "390px" }}
          >
            {!progress && renderContent}
          </div>
        </SpinnerWrapper>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  filters: state.analytics.filters.cashbox,
  progress: state.analytics.isProgress,
});

export default connect(mapStateToProps, { setFilterCashbox })(CashMonitoring);
