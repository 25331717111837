import React from "react";
import { HTable } from "components/based";

function HeadTable(props) {
  return (
    <div className={props.className}>
      <HTable className="p-col-2 p-p-1 p-text-start" value="Номер заявки" />

      <HTable className="p-col-2 p-p-1 p-text-center" value="Заявитель" />
      <HTable className="p-col-2 p-p-1 p-text-center" value="Организация" />
      <HTable className="p-col-2 p-p-1 p-text-center" value="Статус" />
      <HTable className="p-col-2 p-p-1 p-text-center" value="Дата создания" />

      <HTable
        className="p-col-2 p-pr-3 p-pl-1 p-pt-1 p-pb-1 p-text-center"
        value="Редактирование"
      />
    </div>
  );
}

export default HeadTable;
