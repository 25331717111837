import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setProp } from "domain/actions/equipment/bid";
import { Select } from "components/forms/fields";

function DefaultSno(props) {
  const [updateKey, forceUpdate] = useState(1);
  const [value, setValue] = useState(null);
  const {setProp} = props;

  useEffect(() => {
    const item = props.sno.filter((i) => +i.id === +props.item.defaultSnoId);
    setValue(item.length ? item[0] : null);
  }, [props.sno, props.item.defaultSnoId, setValue]);

  useEffect(() => {
    if (props.defaultTaxes) {
      setValue((prev) => {
        if (props.defaultTaxes[0]) {
          setProp(
            "defaultSnoId",
            +props.defaultTaxes[0].id,
            props.item.id
          );
        } else {
          setProp("defaultSnoId", null, props.item.id);
        }

        return props.defaultTaxes[0];
      });
    }
  }, [props.sno, props.defaultTaxes, setValue, setProp, props.item]);

  const isActive = props.selected ? props.selected.status !== 11 : false;

  return (
    <Select
      key={updateKey}
      label="СНО (по умолчанию)"
      options={props.defaultTaxes ? props.defaultTaxes : props.sno}
      className={props.className}
      value={value}
      onChange={(e) => {
        setProp("defaultSnoId", +e.id, props.item.id);
        forceUpdate(updateKey + 1);
      }}
      disabled={isActive}
      required
    />
  );
}

const mapStateToProps = (state) => ({
  sno: state.requisites.sno,
  selected: state.bidList.select,
});
export default connect(mapStateToProps, { setProp })(DefaultSno);
