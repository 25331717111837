import { Route } from "react-router-dom";

// Hook for processing " sub "routes,
// passing them to prop `routes' to the component it renders.
function RouteSwitch(route) {
  return (
    <Route
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}

export default RouteSwitch;
