export function autoLayoutKeyboardRUS(str) {
  const replacer = {
    й: "q",
    ц: "w",
    у: "e",
    к: "r",
    е: "t",
    н: "y",
    г: "u",
    Й: "Q",
    Ц: "W",
    У: "E",
    К: "R",
    Е: "T",
    Н: "Y",
    Г: "U",

    ш: "i",
    щ: "o",
    з: "p",
    х: "[",
    ъ: "]",
    ф: "a",
    ы: "s",
    Ш: "I",
    Щ: "O",
    З: "P",
    Х: "{",
    Ъ: "}",
    Ф: "A",
    Ы: "S",

    в: "d",
    а: "f",
    п: "g",
    р: "h",
    о: "j",
    л: "k",
    д: "l",
    В: "D",
    А: "F",
    П: "G",
    Р: "H",
    О: "J",
    Л: "K",
    Д: "L",

    ж: ";",
    э: "'",
    я: "z",
    ч: "x",
    с: "c",
    м: "v",
    и: "b",
    Ж: ":",
    Э: '"',
    Я: "Z",
    Ч: "X",
    С: "C",
    М: "V",
    И: "B",

    т: "n",
    ь: "m",
    б: ",",
    ю: ".",
    ".": "/",
    Т: "N",
    Ь: "M",
    Б: "<",
    Ю: ">",
    ",": "?",

    ё: "`",
    Ё: "~",
  };

  return str.replace(/[А-яЁё,.]/g, (x) => replacer[x]);
}

export function autoLayoutKeyboardENG(str) {
  const replacer = {
    q: "й",
    w: "ц",
    e: "у",
    r: "к",
    t: "е",
    y: "н",
    u: "г",
    i: "ш",
    o: "щ",
    p: "з",
    "[": "х",
    "]": "ъ",
    a: "ф",
    s: "ы",
    d: "в",
    f: "а",
    g: "п",
    h: "р",
    j: "о",
    k: "л",
    l: "д",
    ";": "ж",
    "'": "э",
    z: "я",
    x: "ч",
    c: "с",
    v: "м",
    b: "и",
    n: "т",
    m: "ь",
    ",": "б",
    ".": "ю",
    "/": ".",
  };

  return str.replace(/[A-z/,.;'\][]/g, function (x) {
    return x === x.toLowerCase()
      ? replacer[x]
      : replacer[x.toLowerCase()].toUpperCase();
  });
}
