import React, { useState } from "react";
import Window from "./components/window";
import PhoneIcon from "@material-ui/icons/Phone";
import styles from "../style.module.css";

function OrderACall(props) {
  const [show, setShow] = useState(false);
  return (
    <React.Fragment>
      <div className={props.className} onClick={() => setShow(true)}>
        {props.icon ? (
          <PhoneIcon />
        ) : (
          <span className={styles.orderACall}>
            <u>Заказать обратную связь</u>
          </span>
        )}
      </div>

      <Window show={show} setShow={setShow} />
    </React.Fragment>
  );
}

export default OrderACall;
