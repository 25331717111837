import React from "react";
import { connect } from "react-redux";
import { handleShowConnectEdit } from "domain/actions/organizations/acquiring";

import Window from "components/windows";
import ContentEdit from "./edit";

function WindowEditConnect(props) {
  const { item, handleShowConnectEdit } = props;

  return (
    <Window
      className="p-col-12 p-md-6 p-p-0"
      header="Редактирование подключения"
      visible={props.show}
      onHide={() => handleShowConnectEdit(false)}
      style={{ minWidth: "340px" }}
      draggable={false}
    >
      <ContentEdit
        className="p-col-12 p-d-flex p-flex-wrap"
        handleShowConnect={handleShowConnectEdit}
        item={item}
      />
    </Window>
  );
}

const mapStateToProps = (state) => ({
  show: state.orgCreate.connectShowEdit,
});
export default connect(mapStateToProps, { handleShowConnectEdit })(
  WindowEditConnect
);
