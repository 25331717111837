import { TOGGLE_PROGRESS } from "../../actionTypes";
import {
  SET_PAYMENTS,
  SET_PAGINATION_PAYMENTS_TOTAL,
  REFRESH_PAYMENTS,
  SELECT_PAYMENTS,
  SHOW_PAYMENT,
} from "../../types/payments";

import ClientController from "utils/Client";

const Client = new ClientController();

export const getPayments = (params) => getByCursor(params);
export const getPaymentById = (id) => getById(id);
export const refreshPayments = () => refresh();

function getById(id) {
  return async function thunk(dispatch, getState) {
    const response = await Client.get(`/acqPayment/${id}`);

    dispatch({ type: SELECT_PAYMENTS, payload: response.data });
    dispatch({ type: SHOW_PAYMENT, payload: true });
  };
}

export function closeWinPayment() {
  return async function thunk(dispatch, getState) {
    return dispatch({ type: SHOW_PAYMENT, payload: false });
  };
}

function getByCursor(cursor) {
  return async function thunk(dispatch, getState) {
    dispatch({ type: TOGGLE_PROGRESS, payload: true });

    let filters = getFilters(getState);
    let params = { limit: 10, lastId: cursor.id, cursor: {} };
    if (filters?.sort?.createdAt) params.cursor["createdAt"] = cursor.createdAt;
    if (filters?.sort?.sum) params.cursor["sum"] = cursor.sum;

    Object.assign(params, filters);

    return await Client.post(`/acqPayment/list`, params)
      .then((response) => {
        let data = response.data;

        dispatch({
          type: SET_PAGINATION_PAYMENTS_TOTAL,
          payload: { total: data.count },
        });

        dispatch({
          type: SET_PAYMENTS,
          payload: data.data,
        });
      })
      .finally((e) => dispatch({ type: TOGGLE_PROGRESS, payload: false }));
  };
}

function refresh() {
  return async function thunk(dispatch, getState) {
    let refresh = getState().receipts.refresh;
    dispatch({ type: REFRESH_PAYMENTS, payload: !refresh });
  };
}

function getFilters(getState) {
  let filters = {
    sort: getState().paymentsFilter.sort,
    range: getState().paymentsFilter.range,
    filter: getState().paymentsFilter.filter,
  };

  for (let key in filters) {
    if (filters[key] === null) delete filters[key];
  }

  return filters;
}
