import React from "react";
import { connect } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { CustomizedAxisTick } from "./tick";

function Chart(props) {
  const data = props.data ? props.data : [];

  data.forEach((i) => {
    const intervalArr = i.interval.split("-");
    const a =
      intervalArr[0].length === 1 ? `0${intervalArr[0]}` : intervalArr[0];
    const b =
      intervalArr[1].length === 1 ? `0${intervalArr[1]}` : intervalArr[1];
    i.interval = `${a} - ${b}`;
  });

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip p-p-2">
          <p className="dashboard-label">{`Дата: с 
            ${payload[0].payload.interval}`}</p>

          <p className="dashboard-label-other">{`Сумма: ${payload[0].payload.total} руб.`}</p>
          <p className="dashboard-label-other">{`Среднее значение за период: ${payload[0].payload.averageByDate} руб.`}</p>
          <p className="dashboard-label-other">{`Среднее значение чека: ${payload[0].payload.averageByCheck} руб.`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%">
      <BarChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: -5,
          bottom: 40,
        }}
      >
        <CartesianGrid
          strokeDasharray="0"
          vertical={false}
          stroke="#ccc"
          tickSize={3}
        />
        <XAxis
          dataKey="interval"
          interval={0}
          padding={{ left: 10, right: 10 }}
          tick={<CustomizedAxisTick />}
        />
        <YAxis axisLine={false} tickSize={0} />
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign="top" height={36} />
        <Bar
          dataKey="averageByDate"
          name="За период"
          fill="#5fc763"
          stroke="none"
        />
        <Bar
          dataKey="averageByCheck"
          name="Средний чек"
          fill="#023c05"
          stroke="none"
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

const mapStateToProps = (state) => ({
  data: state.analytics.data?.revenueHour,
});
export default connect(mapStateToProps)(Chart);
