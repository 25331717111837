import { useSelector } from "react-redux";
import { AuthController } from "domain/controllers/AuthController";

const AuthApi = new AuthController();

export function useAuth() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const checkAuthCode = () => {};

  const onAuth = async () => AuthApi.login();

  const onLogout = async () => AuthApi.logout();

  return {
    isAuthenticated,
    checkAuthCode,
    onAuth,
    onLogout,
  };
}
