import React from "react";
import { connect } from "react-redux";
import { ConfirmDialog } from "primereact/confirmdialog";
import { reauthorization } from "domain/actions/auth.actions";

function WarningAccessAccount(props) {
  const { visible, setVisible, reauthorization } = props;
  const accept = () => reauthorization();

  const reject = () => setVisible(false);

  return (
    <ConfirmDialog
      className="p-col-12 p-md-6 p-xl-4 p-p-0 c-confirm-dialog"
      visible={visible}
      onHide={reject}
      message="Для использования сервиса требуется завершение текущего сеанса и подтверждение учетной записи."
      header="Предупреждение"
      icon="pi pi-exclamation-triangle"
      accept={accept}
      reject={reject}
      acceptLabel="Продолжить"
      rejectLabel="Отмена"
    />
  );
}

export default connect(null, { reauthorization })(WarningAccessAccount);
