import React from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";

export function CardComponent(props) {
  const { service, onSelect } = props;

  const header = (
    <div
      style={{
        width: "100%",
        height: "10px",
        backgroundColor: "rgb(25 112 47)",
      }}
    ></div>
  );
  const footer = (
    <span>
      <Button label="Перейти" style={{width: "200px"}} onClick={onSelect} />
    </span>
  );

  return (
    <div className="p-col-12 ">
      <Card 
        title={service?.name} 
        subTitle={service?.description}
        footer={footer} 
        header={header}
      >
      </Card>
    </div>
  );
}

export default CardComponent;
