import axios from "axios";
import { AuthController } from "./AuthController";

export class AMSController {
  constructor() {
    this.Auth = new AuthController();
  }

  getBaseUrl() {
    const env = process.env;
    const prefix = "";

    if (env.REACT_APP_ENVIRONMENT === "production") {
      return env.REACT_APP_AMS_PROD + prefix;
    }

    if (env.REACT_APP_ENVIRONMENT === "development") {
      return env.REACT_APP_AMS_DEV + prefix;
    }

    return env.REACT_APP_AMS_LOCAL + prefix;
  }

  getHeaders(params = null) {
    const tokens = this.Auth.getTokenStorage();

    let headers = {
      timeout: 10000,
      headers: {
        "Content-Type": "application/json",
      },
      baseURL: this.getBaseUrl(),
    };

    if (tokens.accessToken) {
      headers.headers.Authorization = `Bearer ${tokens.accessToken}`;
    }

    if (params) {
      headers.params = params;
    }

    return headers;
  }

  async get(url, params = null) {
    return await axios.get(url, this.getHeaders(params));
  }

  async post(url, body) {
    return await axios.post(url, body, this.getHeaders());
  }

  async put(url, body) {
    return await axios.put(url, body, this.getHeaders());
  }

  async delete(url) {
    return await axios.delete(url, this.getHeaders());
  }
}
