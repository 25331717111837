import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { showError } from "domain/actions/error.actions";
import {
  putAcquiringLocal,
  deleteAcquiringLocal,
} from "domain/actions/organizations/acquiring";

import { TextField, MaskField } from "components/forms/fields";
import { Select } from "components/forms/fields";
import { Button } from "primereact/button";
import DeleteButton from "./deleteBtn";

function ContentEdit(props) {
  const { acquiringLocal, refresh } = props;
  const [title, setTitle] = useState("");
  const [acqType, setAcquiring] = useState([]);
  const [login, setLogin] = useState("");
  const [secretKey, setSecret] = useState("");
  const [accessCode, setAccessCode] = useState("");
  const {
    acquiring,
    showError,
    putAcquiringLocal,
    deleteAcquiringLocal,
  } = props;

  useEffect(() => {
    setTitle(acquiringLocal.title);
    setAcquiring();
    setLogin(acquiringLocal?.settingsData?.login);
    setSecret(acquiringLocal?.settingsData?.secretKey);
    setAccessCode(acquiringLocal?.settingsData?.accessCode);
  }, [acquiringLocal]);

  useEffect(() => {
    const _acquiring = acquiring.filter(
      (i) =>
        i.name.toUpperCase() ===
        acquiringLocal?.settingsData?.acqType?.toUpperCase()
    );
    if (_acquiring.length) setAcquiring(_acquiring[0]);
  }, [acquiring, acquiringLocal]);

  const confirm = () => {
    if (!title) return showError('Поле "Наименование" не заполнено');
    if (!acqType) return showError('Поле "Тип платежной системы" не заполнено');
    if (!login) return showError('Поле "Логин" не заполнено');
    if (!secretKey) return showError('Поле "Secret key" не заполнено');
    if (!accessCode) return showError('Поле "Access code" не заполнено');
    if (accessCode.length !== 6) return showError('Поле "Access code" ');

    putAcquiringLocal({
      id: acquiringLocal.id,
      title,
      acqType,
      login,
      secretKey,
      accessCode,
    }).then(() => refresh());
  };

  const deleteAcquiring = () => {
    deleteAcquiringLocal(acquiringLocal.id).then(() => refresh());
  };

  return (
    <div className={props.className}>
      <TextField
        className="p-col-12"
        label="Наименование"
        value={title}
        onChange={setTitle}
        reqStar
      />

      <div className="p-col-12">
        <Select
          className="p-col-12 p-p-0"
          options={acquiring.filter((i) => i.name.toUpperCase() === "TAP2GO")}
          label="Тип платежной системы"
          value={acqType}
          onChange={setAcquiring}
          required
        />
      </div>

      <TextField
        className="p-col-12"
        label="Логин"
        value={login}
        onChange={setLogin}
        reqStar
      />

      <TextField
        className="p-col-12"
        label="Secret key"
        value={secretKey}
        onChange={setSecret}
        reqStar
      />

      <MaskField
        className="p-col-12"
        label="Access code"
        value={accessCode}
        onChange={setAccessCode}
        mask="999999"
        reqStar
      />

      <div className="p-col-12 p-d-flex p-flex-wrap p-jc-end p-p-0">
        <DeleteButton
          className="p-col-12 p-md-6 "
          deleteAcquiring={deleteAcquiring}
        />
        <div className="p-col-12 p-md-6">
          <Button
            label="Применить"
            className="с-button-success"
            onClick={confirm}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  acquiring: state.requisites.acquiring,
});
export default connect(mapStateToProps, {
  showError,
  putAcquiringLocal,
  deleteAcquiringLocal,
})(ContentEdit);
