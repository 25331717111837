import React from "react";
import styles from "./contacts.module.css";
import PhoneIcon from "@material-ui/icons/Phone";

function Phone({ phone, fontWeight = "300", className = "" }) {
  return (
    <div className={`${styles.block} ${className}`}>
      <PhoneIcon style={{ marginRight: "1rem" }} />{" "}
      <span
        className={`${styles.text} contacts-phone`}
        style={{ fontWeight: fontWeight }}
      >
        {phone}
      </span>
    </div>
  );
}

export default Phone;
