import React from "react";
import {
  PositionName,
  ManagerLastName,
  ManagerFirstName,
  ManagerMiddleName,
} from "../../../../components/fields";

function Director(props) {
  return (
    <div className={props.className}>
      <span className="block-label-title p-pb-4 title-silver p-col-12">
        Руководитель
      </span>

      <PositionName className="p-col-12" disabled={props.disabled} />
      <ManagerLastName className="p-col-12 p-md-6" disabled={props.disabled} />
      <ManagerFirstName className="p-col-12 p-md-6" disabled={props.disabled} />
      <ManagerMiddleName
        className="p-col-12 p-md-6"
        disabled={props.disabled}
      />
    </div>
  );
}

export default Director;
