import React from "react";
import { HashRouter as Router, Route } from "react-router-dom";

import RouteSwitch from "../components/route/Routes.switch";
import { ProvideAuth } from "../components/route/ProvideAuth";
import { PublicRoute, PrivateRoute } from "../components/route/TypeRoute";

import PublicPager from "./urlParameters/public.routes";
import PrivatePager from "./urlParameters/private.routes";

import ToastError from "../components/ToastError";
import ToastSuccess from "../components/ToastSuccess";
import { ScrollTop } from "primereact/scrolltop";

import {
  Greeting,
  // NotFound
} from "./pages";

function Routes() {
  return (
    <ProvideAuth>
      <Router>
        <div>
          <ToastError />
          <ToastSuccess />
          <ScrollTop />

          <Route exact path="/" component={Greeting} />

          <PrivateRoute path="/main">
            {PrivatePager.map((route, i) => (
              <RouteSwitch key={i} {...route} />
            ))}
          </PrivateRoute>

          <PublicRoute path="/greeting">
            {PublicPager.map((route, i) => (
              <RouteSwitch key={i} {...route} />
            ))}
          </PublicRoute>

          {/* <Route exact path="/*" component={NotFound} /> */}
        </div>
      </Router>
    </ProvideAuth>
  );
}

export default Routes;
