import React from "react";
import RightsPage from "components/rightsControl/RightsPage";
import Content from "./layouts";

function Equipments(props) {
  return (
    <RightsPage right="EQUIPMENTS">
      <Content />
    </RightsPage>
  );
}

export default Equipments;
