import React from "react";
import Line from "components/Line";

const style = {
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "24px",
  lineHeight: "28px",
};

function Information({ className }) {
  return (
    <div className={className}>
      <div className="p-col-12 p-pl-0 p-pr-0">
        <p className="p-m-0" style={style}>
          <strong>Предприятия</strong> крупного, среднего и малого бизнеса
        </p>
        <Line height={3} />
      </div>

      <div className="p-col-12 p-pl-0 p-pr-0">
        <p className="p-m-0" style={style}>
          Индивидуальные <strong>предприниматели</strong> розничной торговли и
          доставки
        </p>
        <Line height={3} />
      </div>
    </div>
  );
}

export default Information;
