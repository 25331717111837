import React, { useState, useEffect } from "react";

import { AuthComponent } from "components/auth";
import Registration from "routes/pages/greeting/main/layouts/components/projectProposal/components/RegistrationView";
import AppPayboxView from "./components/appPaybox";

import {
  ForWhat,
  WhyUs,
  HowCashRegistersWork,
  HowWeSolveProblems,
  WhoAreWeTryingFor,
  IntegrationCMS,
  IntegrationAPI,
} from "./components";
import { Header, Footer } from "../../components";

import { NavigationList } from "../../index";
import Navigation from "components/NavigationPage/NavigationPage";

import styles from "../../index.module.css";

function Layout(params) {
  const [scroll, setScroll] = useState(0);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <div className={`${styles.layout} p-pl-md-4 p-pr-0 p-pl-xl-6 `}>
      <Header
        className={`${styles.header} p-col-12 p-pr-xl-6`}
        auth={params.auth}
      />

      <div
        className="p-d-block p-d-md-none"
        style={{ minHeight: "136px" }}
      ></div>

      {/*
       ************************************************************************************************
       ********************************
       */}

      {/* <ProjectProposal className={`${styles.register} p-d-flex p-mb-3`} /> */}
      <div className={`${styles.register} p-d-flex p-mb-3`}>
        <div className="p-d-none p-d-md-block p-col-2 p-p-0"></div>
        <div className="p-d-flex p-flex-wrap p-col-12 p-md-10 p-p-0">
          <div className="p-col-12 p-md-6 ">
            <Registration
              className={`${styles.RegistrationView} p-col-12 p-mt-md-6`}
            />

            <AppPayboxView className={`${styles.RegistrationView} p-col-12`} />
          </div>
          <div
            className={`p-col-6 p-d-none p-d-md-flex p-jc-center p-as-center ${styles.auth_block}`}
          >
            <div className={styles.auth_block_bg}>
              <AuthComponent />
            </div>
          </div>
        </div>
      </div>

      {/*
       ************************************************************************************************
       ********************************
       */}

      <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
        <div
          className={`p-md-2 p-d-none p-d-md-block p-p-0 ${
            scroll < 377 ? "block-hide" : "block-show"
          }`}
        >
          <Navigation
            list={NavigationList}
            className={styles.sidebar}
            attr="sidebar-greeting-page"
          />
        </div>

        <div className="p-col-12 p-md-10 p-p-0 p-d-flex p-flex-wrap">
          <ForWhat
            params={NavigationList[0]}
            className={`${styles.ForWhat} p-col-12 p-pl-2 p-pr-2 p-pr-xl-6`}
          />

          <WhyUs
            params={NavigationList[1]}
            className={`${styles.WhyUs} p-col-12 p-pl-2 p-pr-2 p-pr-xl-6`}
          />
          <HowWeSolveProblems
            params={NavigationList[2]}
            className={`${styles.HowWeSolveProblems} p-col-12 p-pl-1 p-pr-4 p-pr-xl-4`}
          />

          <WhoAreWeTryingFor
            params={NavigationList[3]}
            className={`${styles.WhoAreWeTryingFor} p-col-12 p-pl-2 p-pr-2 p-pr-xl-6`}
          />

          <HowCashRegistersWork
            params={NavigationList[4]}
            className={`${styles.HowCashRegistersWork} p-col-12 p-pl-2 p-pr-0 `}
          />

          <IntegrationCMS
            params={NavigationList[5]}
            className={`${styles.IntegrationCMS} p-col-12 p-pl-2 p-pr-3`}
          />

          <IntegrationAPI
            params={NavigationList[6]}
            className={`${styles.IntegrationCMS} p-col-12 p-pl-2 p-pr-3`}
          />

          <div
            className="p-d-none p-d-md-block"
            style={{ minHeight: "115px", opacity: "0" }}
          >
            _
          </div>
        </div>
      </div>

      <Footer className="p-col-12 p-pr-xl-6" />
    </div>
  );
}

export default Layout;
