import { SET_PAGINATION_BID_EQUIPMENT } from "domain/types/equipment";

const initialState = {
  limit: 1,
  page: 1,
  pages: 1,
  total: 120,
};

export default function pagination(state = initialState, action) {
  switch (action.type) {
    case SET_PAGINATION_BID_EQUIPMENT:
      const { limit, page, pages, total } = action.payload;
      return {
        ...state,
        limit: limit,
        page: page,
        pages: pages,
        total: total,
      };
    default:
      return state;
  }
}
