import React, { useState, useEffect } from "react";

import { TabView, TabPanel } from "primereact/tabview";

function TabViewComponent(props) {
  const [activeIndex, setActiveIndex] = useState(0);
  const getTabIndex = props.getTabIndex;

  useEffect(() => !!getTabIndex && getTabIndex(activeIndex), [
    activeIndex,
    getTabIndex,
  ]);

  const renderItems = props.children.map((i, index) => {
    return (
      <TabPanel key={index} header={i.props.label}>
        {i}
      </TabPanel>
    );
  });

  return (
    <TabView
      activeIndex={activeIndex}
      onTabChange={(e) => setActiveIndex(e.index)}
      className={props.className}
    >
      {renderItems}
    </TabView>
  );
}

export default TabViewComponent;
