import React from "react";
import { Controller } from "react-hook-form";
import { getDefaultValue } from "../../../../controllers/getDefaultValue";

import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import styles from "../styles.module.css";

function CorrectionComponent({
  control,
  className,
  requisites,
  defaultValue = null,
}) {
  return (
    <div className={`${styles.form} ${className}`}>
      <div className="p-p-0">
        <div className="p-col-12 p-d-flex p-flex-column">
          <div className="p-col-12 p-d-flex p-flex-column">
            <label className={`${styles.font} p-pb-1`}>Тип коррекции</label>

            <Controller
              name="correctionType"
              control={control}
              render={(props) => (
                <Dropdown
                  value={props.value}
                  options={requisites ? requisites.correctionType : []}
                  onChange={(e) => props.onChange(e.value)}
                  optionLabel="name"
                  className={`${styles.borderBottom}`}
                />
              )}
              defaultValue={getDefaultValue(defaultValue, "correctionType")}
            />
          </div>

          <div className="p-col-12 p-d-flex p-flex-column">
            <label className={`${styles.font} p-pb-1`}>
              Дата совершения корректируемого расчёта
            </label>

            <Controller
              name="correctionBaseDate"
              control={control}
              render={(props) => (
                <Calendar
                  value={props.value}
                  onChange={(e) => props.onChange(e.value)}
                  className={`${styles.borderBottom}`}
                  dateFormat="dd MM yy"
                  panelClassName="c-calendar"
                  defaultValue={
                    new Date(
                      getDefaultValue(defaultValue, "correctionBaseDate")
                    )
                  }
                />
              )}
              defaultValue={
                new Date(getDefaultValue(defaultValue, "correctionBaseDate"))
              }
            />
          </div>

          <div className="p-col-12 p-d-flex p-flex-column">
            <label className={`${styles.font} p-pb-1`}>
              Номер предписания налогового органа
            </label>

            <Controller
              name="correctionBaseNumber"
              control={control}
              render={(props) => (
                <InputText
                  onChange={(e) => props.onChange(e)}
                  className={`${styles.borderBottom}`}
                  defaultValue={getDefaultValue(
                    defaultValue,
                    "correctionBaseNumber"
                  )}
                />
              )}
              defaultValue={
                defaultValue
                  ? defaultValue.correctionBaseNumber
                    ? defaultValue.correctionBaseNumber
                    : " "
                  : " "
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CorrectionComponent;
