import React from "react";
import styles from "../style.module.css";

function Sorts(props) {
  return (
    <div className={props.className + " " + styles.sorts}>
      <span>Сортировка по:</span>
      {props.children}
    </div>
  );
}

export default Sorts;
