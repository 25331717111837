import {
  ADD_CASHBOX_BID_EQUIPMENTS,
  UPDATE_CASHBOX_BID_EQUIPMENTS,
  DELETE_CASHBOX_BID_EQUIPMENTS,
  CLEAR_CASHBOX_BID_EQUIPMENTS,
  LOADING_CASHBOX_BID_EQUIPMENTS,
} from "../../../types/equipment";

const initialState = {
  list: [],
  selected: null,
  editEvent: false,
  isProgress: false,
  id: 1,
  schemaItemClient: {
    workerId: null,
    needPrintOnPaper: false,
    cashboxModelId: null,
    factoryNumber: "",
    fnTypeId: null,
    tariffId: null,
    tradePlace: "",
    regNumber: "",
    connect: {
      os: "Windows",
      interface: null,
      address: "",
    },
    comment: "",
    location: "",
  },
  schemaItemProvider: {
    tradePlace: "",
    needPrintOnPaper: false,
    ffdId: null,
    defaultSnoId: null,
    snoListId: null,
    agentTypesId: null,
    fnTypeId: null,
    ofdId: null,
    tariffId: null,
    amount: 2,
    jsonData: null,
    comment: "",
    ofd: {
      name: "",
      inn: "",
      url: "",
    },
  },
};

export default function bidListCashboxReduce(state = initialState, action) {
  switch (action.type) {
    case ADD_CASHBOX_BID_EQUIPMENTS:
      return {
        ...state,
        list: [...state.list, action.payload],
        id: state.id + 1,
      };
    case UPDATE_CASHBOX_BID_EQUIPMENTS:
      return {
        ...state,
        list: action.payload,
        editEvent: true,
      };
    case DELETE_CASHBOX_BID_EQUIPMENTS:
      return {
        ...state,
        list: action.payload,
        editEvent: !!action.payload.length,
      };
    case CLEAR_CASHBOX_BID_EQUIPMENTS:
      return {
        ...state,
        list: [],
        editEvent: false,
      };
    case LOADING_CASHBOX_BID_EQUIPMENTS:
      return {
        ...state,
        isProgress: action.payload,
      };
    default:
      return state;
  }
}
