import React, { useState, useCallback, useEffect } from "react";
import styles from "../index.module.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { isEmail } from "utils/email.util";

import { InputText } from "primereact/inputtext";

function EmailField({
  className = "",
  name = "",
  label = "",
  value = "",
  onChange = () => console.log("onChange"),
  onBlur = () => console.log("onBlur"),
  isValid = false,
  disabled = false,
}) {
  const [valid, setValid] = useState(false);
  if (value === null) value = "";

  const inValid = useCallback(() => {
    if (!isValid) return setValid(true);

    setValid(isEmail(value));
  }, [isValid, value, setValid]);

  useEffect(() => inValid(), [inValid]);

  return (
    <div className={className}>
      <label className={`${styles.label} p-pb-1`}>{label}</label>
      <InputText
        name={name}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
          inValid(e.target.value);
        }}
        onBlur={onBlur}
        className={`${styles.borderBottom}`}
        disabled={disabled}
      />
      {!valid && !!value.length && (
        <small className="p-error p-d-block">
          Введенный email не прошел проверку.
        </small>
      )}
      {isValid && !value.length && (
        <small className="p-error p-d-block">Обязательно для заполнения!</small>
      )}
    </div>
  );
}

function EmailFieldControl({
  control,
  errors,
  className = "",
  name = "",
  label = "",
  defaultValue = "",
  required = false,
  responseError = null,
  disabled = false,
}) {
  let rules = {
    validate: (value) => {
      if (!value.length && !required) return true;

      if (!isEmail(value)) {
        return "Введенный email не прошел проверку";
      }

      return isEmail(value);
    },
  };

  if (required) {
    rules.required = "Обязательно для заполнения!";
  }

  return (
    <div className={className}>
      <label className={`${styles.label} p-pb-1`}>{label}</label>
      <Controller
        name={name}
        control={control}
        render={(props) => (
          <InputText
            value={props.value}
            onChange={(e) => props.onChange(e)}
            className={`${styles.borderBottom}`}
            disabled={disabled}
          />
        )}
        rules={rules}
        defaultValue={defaultValue}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        as={<p className="errorText">message</p>}
      />
      {responseError && (
        <small className="p-error p-d-block">{`${responseError}`}</small>
      )}
    </div>
  );
}

export { EmailField, EmailFieldControl };
