import React from "react";

import styles from "../style.module.css";

function Label({ className = "", value = "", style = {}, required = false }) {
  return (
    <div className={className + " " + styles.label} style={style}>
      {value} {required && <span style={{ color: "red" }}>*</span>}
    </div>
  );
}

export default Label;
