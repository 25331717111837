import React from "react";
import { ConfirmDialog } from "primereact/confirmdialog";

function WarningConfirm(props) {
  const reject = () => {
    props.accept(false);
    props.onHide(true);
  };
  const accept = () => {
    props.accept(true);
    props.onHide(true);
  };

  return (
    <ConfirmDialog
      visible={props.show}
      onHide={() => props.onHide(false)}
      message="Если сменить место установки, все ранее заполненные данные будут удалены! Продолжить?"
      header="Предупреждение!"
      icon="pi pi-exclamation-triangle"
      accept={accept}
      reject={reject}
      baseZIndex={2000}
    />
  );
}

export default WarningConfirm;
