import React from "react";
import { InputNumber } from "primereact/inputnumber";
import { Label } from "components/based";

function NumberFieldComponent(props) {
  let minFractionDigits = props.minFractionDigits ? props.minFractionDigits : 2;
  let maxFractionDigits = props.maxFractionDigits ? props.maxFractionDigits : 2;
  let max = props.max ? props.max : null;

  return (
    <div className={props.className}>
      <Label className="p-d-block p-d-xl-none" value={props.label} />
      <InputNumber
        value={props.value}
        className="c-border-bottom"
        min={0}
        max={max}
        minFractionDigits={minFractionDigits}
        maxFractionDigits={maxFractionDigits}
        onChange={(e) => props.onChange(props.name, e.value)}
        onValueChange={(e) => props.onChange(props.name, e.value)}
        disabled={props.disabled}
        tooltip={props.value ? String(props.value) : "0"}
        tooltipOptions={{ position: "bottom" }}
      />
    </div>
  );
}

export default NumberFieldComponent;
