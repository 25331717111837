import React from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { TextField } from "components/forms/fields";
import { showSuccess } from "domain/actions/error.actions";

import Window from "components/windows";

function UrlPaymentsWindow(props) {
  const { show, url, setShow, showSuccess } = props;
  const handleCopyLink = () => {
    navigator.clipboard.writeText(url);

    showSuccess("Ссылка на онлайн-оплату скопирована");
  };

  return (
    <Window
      header="Предупреждение!"
      visible={show}
      modal
      style={{ width: "350px" }}
      onHide={setShow}
    >
      <div className="p-col-12 p-d-flex p-p-0">
        <TextField
          label="Ссылка на оплату"
          className="p-col-11"
          value={url ? url.slice(0, 19) + "..." : "ссылка отсутствует"}
        />

        <div className="p-col-1 p-p-0 p-d-flex p-ai-center">
          <Button
            className="p-button-rounded p-button-text icon-style-1"
            icon="pi pi-copy"
            onClick={handleCopyLink}
          />
        </div>
      </div>
    </Window>
  );
}

export default connect(null, { showSuccess })(UrlPaymentsWindow);
