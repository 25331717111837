import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  setPropAcquiring,
  setPropAcquiringEdit,
} from "domain/actions/organizations/acquiring";
import { Select } from "components/forms/fields";

function TypeAcquiring(props) {
  const [value, setValue] = useState("");
  const [updateKey, forceUpdate] = useState(1);

  const setProp = !props.select
    ? props.setPropAcquiring
    : props.setPropAcquiringEdit;

  useEffect(() => {
    const item = !!props.select
      ? props.listTypeSystem.filter((i) => +i.id === +props.select.acquiringId)
      : props.listTypeSystem.filter((i) => +i.id === +props.value);

    setValue(item.length ? item[0] : null);
  }, [props.listTypeSystem, props.value, props.select, updateKey]);

  return (
    <div className={props.className}>
      <Select
        className="p-col-12 p-p-0"
        key={updateKey}
        options={props.listTypeSystem && props.listTypeSystem}
        label="Тип платежной системы"
        value={value}
        disabled={!!props.select}
        onChange={(e) => {
          setProp("acquiringId", +e.id);
          if(e.name === "SberQR" || e.name === "PSB") setProp("extParams", e.name, 'acqName');
          forceUpdate(updateKey + 1);
        }}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  listTypeSystem: state.requisites.acquiring,
  value: state.orgAcquiring.acquiring.acquiringId,
  select: state.orgAcquiring.select,
});
export default connect(mapStateToProps, {
  setPropAcquiring,
  setPropAcquiringEdit,
})(TypeAcquiring);
