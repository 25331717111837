import React from "react";
import styles from "./contacts.module.css";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

function Email({ email, className = "" }) {
  return (
    <div className={`${styles.block} ${className}`}>
      <MailOutlineIcon style={{ marginRight: "1rem" }} />{" "}
      <span className={`${styles.text} contacts-email`}>{email}</span>
    </div>
  );
}

export default Email;
