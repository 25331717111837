import {
  LOGOUT_AUTH,
  LOGIN_AUTH,
  FORGETPASS_AUTH,
  LOGIN_ISAUTH,
  LOADING_AUTH,
  COUNT_ERROR_TOKEN_AUTH,
  NULL_ERROR_TOKEN_AUTH,
  SET_VISIBLE_UNAUTHORIZED,
} from "../actionTypes";

const initialState = {
  isAuthenticated: true,
  accessToken: "",
  refreshToken: "",
  checkHash: null,
  loading: false,
  countError: 0,
  window: {
    unauthorized: false,
  },
};

export default function authReduce(state = initialState, action) {
  switch (action.type) {
    case LOGIN_AUTH:
      return {
        ...state,
        isAuthenticated: true,
      };
    case LOGOUT_AUTH:
      return {
        ...state,
        isAuthenticated: false,
      };
    case LOADING_AUTH:
      return {
        ...state,
        loading: action.payload,
      };
    case FORGETPASS_AUTH:
      return {
        ...state,
        checkHash: action.payload,
      };
    case LOGIN_ISAUTH:
      let { isAuthenticated } = action.payload;
      return {
        ...state,
        isAuthenticated: isAuthenticated,
      };
    case COUNT_ERROR_TOKEN_AUTH:
      return {
        ...state,
        countError: state.countError + 1,
      };
    case NULL_ERROR_TOKEN_AUTH:
      return {
        ...state,
        countError: 0,
      };
    case SET_VISIBLE_UNAUTHORIZED:
      return {
        ...state,
        window: {
          unauthorized: action.payload,
        },
      };
    default:
      return state;
  }
}
