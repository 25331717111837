import React from "react";
import ColumnTypeToString from "controllers/ColumnTypeToString";
import { Column } from "components/dataView";
import EditIcon from "@material-ui/icons/Edit";

function Card(props) {
  const TypeToString = new ColumnTypeToString();
  const item = props.item;

  const name = `${item.lastName} ${item.firstName} ${
    item.middleName ? item.middleName : ""
  }`;

  return (
    <div className="p-d-flex p-flex-wrap p-pt-4 p-pb-4 c-card-bb">
      <div className="p-col-10 p-md-4 p-order-0 p-p-1 p-as-center">
        <Column
          className="p-as-start"
          classTitle="p-d-none"
          classBody="c-color-green p-text-left"
          value={name}
        />
      </div>

      <Column
        className="p-col-6 p-md-2 p-p-1 p-ai-start p-ai-md-center p-as-center p-order-3 p-order-md-1"
        title="Телефон"
        value={TypeToString.toPhone(item.mobile)}
      />

      <Column
        className="p-col-6 p-md-2 p-p-1 p-ai-start p-ai-md-center p-as-center p-order-5 p-order-md-3"
        title="Дата создания"
        value={TypeToString.toDate(item.createdAt)}
      />

      <Column
        className="p-col-6 p-md-3 p-p-1 p-ai-end p-ai-md-center p-as-center p-order-3 p-order-lg-3"
        classBody="p-text-right p-text-md-center"
        title="Уровень доступа"
        value={TypeToString.toLvlRoot(item.rights)}
      />

      <div className="p-col-2 p-md-1 p-p-1 p-d-flex p-jc-end p-order-1 p-order-md-4">
        {props?.rights.includes("USERS_READ") && (
          <EditIcon
            style={{ height: "2rem", width: "2rem" }}
            className="p-col-4 p-p-1 c-hover-cursor "
            onClick={props.onPreview}
          />
        )}
      </div>
    </div>
  );
}

export default Card;
