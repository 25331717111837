import React from "react";

function IntegrationWithServices({ className }) {
  return (
    <div className={className}>
      <div>
        <strong>Интеграция с дополнительными сервисами</strong>
      </div>
      <p></p>
    </div>
  );
}

export default IntegrationWithServices;
