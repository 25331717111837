import store from "domain/store";

class Requisites {
  getAgentByEng(eng) {
    const requisites = store.getState().requisites;
    const agents = requisites.agentType;
    const agent = agents.filter((i) => i.eng === eng);

    return agent.length ? agent[0] : null;
  }
  getNomenclatureCodeByEng(eng) {
    const requisites = store.getState().requisites;
    const nomenclatureCode = requisites.itemCodeType;
    const item = nomenclatureCode.filter((i) => i.eng === eng);

    return item.length ? item[0] : null;
  }

  getMarkingCodeByEng(eng) {
    const requisites = store.getState().requisites;
    const markingCodeType = requisites.markingCodeType;
    const item = markingCodeType.filter((i) => i.eng === eng);

    return item.length ? item[0] : null;
  }
  getMeasurementUnit(name) {
    const requisites = store.getState().requisites;
    const unit = requisites.unit;
    const item = unit.filter((i) => i.name === name);

    return item.length ? item[0] : null;
  }
  getType(eng) {
    const requisites = store.getState().requisites;
    const types = requisites?.itemType;

    if (!types) return null;

    const item = types.filter((i) => i.eng === eng);

    return item.length ? item[0] : null;
  }
  getTax(val) {
    const requisites = store.getState().requisites;
    const taxes = requisites?.tax;

    if (!taxes) return null;

    const item = taxes.filter((i) => i.eng === val.type);

    return item.length ? item[0] : null;
  }
  getPayObject(eng) {
    const requisites = store.getState().requisites;
    const paymentObject = requisites?.paymentObject;

    if (!paymentObject) return null;

    const item = paymentObject.filter((i) => i.eng === eng);

    return item.length ? item[0] : null;
  }
  getPaymentMethod(eng) {
    const requisites = store.getState().requisites;
    const method = requisites?.paymentMethod;

    if (!method) return null;

    const item = method.filter((i) => i.eng === eng);

    return item.length ? item[0] : null;
  }
}

export default Requisites;
